import React, { useState } from "react";
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const CardHeader = styled.div`
  h2 {
    color: ${(props) => (props.isActive ? "#fff" : "#000")};
    font-family: Soleil;
    font-size: ${(props) => (props.isActive ? "30.24px" : "28px")};
    font-style: normal;
    font-weight: 400;
    line-height: ${(props) => (props.isActive ? "35.64px" : "33px")};
    letter-spacing: ${(props) => (props.isActive ? "-0.605px" : "-0.56px")};
    transition: color 0.3s, font-size 0.3s, line-height 0.3s,
      letter-spacing 0.3s;
  }
`;

const CardContent = styled.div`
  p {
    color: ${(props) => (props.isActive ? "#000" : "#000")};
    font-family: Soleil;
    font-size: ${(props) => (props.isActive ? "12px" : "12.96px")};
    font-style: normal;
    font-weight: 400;
    line-height: ${(props) => (props.isActive ? "18px" : "19.44px")};
    letter-spacing: ${(props) => (props.isActive ? "-0.24px" : "-0.259px")};
    transition: color 0.3s, font-size 0.3s, line-height 0.3s,
      letter-spacing 0.3s;
  }
`;

const CardFooter = styled.div`
  h3 {
    color: ${(props) => (props.isActive ? "#000" : "#000")};
    font-family: Soleil;
    font-size: ${(props) => (props.isActive ? "16px" : "17.28px")};
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: ${(props) => (props.isActive ? "-0.32px" : "-0.346px")};
    transition: color 0.3s, font-size 0.3s, letter-spacing 0.3s;
  }
`;

const CardOptions = styled.div`
  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: #626981; /* Default color for option text */
    font-family: Soleil;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: -0.28px;
    transition: color 0.3s, font-size 0.3s, line-height 0.3s,
      letter-spacing 0.3s;

    &:hover {
      color: #bbb; /* Change text color on hover */
      font-size: 15.12px;
      line-height: 31.32px;
      letter-spacing: -0.302px;
    }
  }

  img {
    width: 16px; /* Set the width of the image */
    height: 16px; /* Set the height of the image */
    margin-right: 5px;
  }
`;

const CardPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  p {
    font-family: Soleil;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p.price {
    color: #000;
    font-size: 79.904px;
    letter-spacing: -1.598px;
    transition: color 0.3s, font-size 0.3s, letter-spacing 0.3s;

    @media (max-width: 768px) {
      font-size: 39.511px;
      line-height: 93.133px;
      letter-spacing: -0.79px;
    }

    ${(props) =>
      props.isActive &&
      css`
        color: #fff;
        font-size: 86.297px;
        letter-spacing: -1.726px;
      `}
  }

  p.cpl {
    color: #000;
    font-size: 18.955px;
    letter-spacing: -0.379px;

    @media (max-width: 768px) {
      font-size: 18.955px;
      line-height: normal;
      letter-spacing: -0.379px;
    }
  }
`;

const PricingCardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 1224px;
  padding: 30px;
  margin: 10px;
  background: ${(props) =>
    props.isActive
      ? 'url("assets/images/analytics/middle-card.webp") center no-repeat'
      : 'url("assets/images/analytics/card.webp") center no-repeat'};
  background-size: cover;
  transition: transform 0.3s ease-in-out, background 0.3s ease-in-out, color 0.3s ease-in-out;
  overflow: hidden; /* Hide overflow content */
  border-radius: 12px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: auto; /* Set height to auto for mobile devices */
    &:hover {
      transform: scale(1.1);
      ${CardHeader} h2 {
        color: #fff;
      }
    }
  }

  &:hover {
    @media (min-width: 769px) {
      transform: scale(1.1);
      background: url("assets/images/analytics/middle-card.webp") center no-repeat;
      background-size: cover;
    }
  }

  &:hover ${CardHeader} h2 {
    color: #fff;
    font-size: 30.24px;
    line-height: 35.64px;
    letter-spacing: -0.605px;
  }

  &:hover ${CardContent} p {
    color: #bbb;
    font-size: 12.96px;
    line-height: 19.44px;
    letter-spacing: -0.259px;
  }

  &:hover ${CardFooter} h3 {
    color: #fff;
    font-size: 17.28px;
    letter-spacing: -0.346px;
  }

  &:hover ${CardOptions} li {
    color: #bbb;
    font-size: 15.12px;
    line-height: 31.32px;
    letter-spacing: -0.302px;
  }

  &:hover ${CardPrice} p.price {
    color: #fff;
    font-size: 86.297px;
    letter-spacing: -1.726px;
  }

  &:hover ${CardPrice} p.cpl {
    color: #fff;
    font-size: 18.955px;
    letter-spacing: -0.379px;
  }

  &:not(:hover):not(.active) {
    transform: scale(1);
    background: url("assets/images/analytics/card.webp") center no-repeat;
    background-size: cover;

    ${CardHeader} h2 {
      color: #000;
    }
  }

  &:not(:hover):not(.active) {
    transform: scale(1);
    background: url("assets/images/analytics/card.webp") center no-repeat;
    background-size: cover;

    ${CardHeader} h2 {
      color: #000;
    }

    ${CardContent} p {
      color: ${(props) => (props.isActive ? "#000" : "#000")};
      font-size: ${(props) => (props.isActive ? "12px" : "12.96px")};
      line-height: ${(props) => (props.isActive ? "18px" : "19.44px")};
      letter-spacing: ${(props) => (props.isActive ? "-0.24px" : "-0.259px")};
    }

    ${CardFooter} h3 {
      color: ${(props) => (props.isActive ? "#000" : "#000")};
      font-size: ${(props) => (props.isActive ? "16px" : "17.28px")};
      letter-spacing: ${(props) => (props.isActive ? "-0.32px" : "-0.346px")};
    }

    ${CardOptions} li {
      color: #626981;
      font-size: 14px;
      line-height: 29px;
      letter-spacing: -0.28px;
    }

    ${CardPrice} p.price {
      color: #000;
      font-size: 79.904px;
      letter-spacing: -1.598px;
    }

    ${CardPrice} p.cpl {
      color: #000;
      font-size: 18.955px;
      letter-spacing: -0.379px;
    }
  }

`;


const CardButton = styled.div`
  position: relative;
  padding-bottom: 20px; /* Added padding */
  padding-top: 10px;
  button {
    color: #fff;
    text-align: center;
    font-family: Soleil;
    font-size: 17.943px;
    font-style: normal;
    font-weight: 700;
    line-height: 15.943px;
    border: 4px solid rgba(255, 255, 255, 0.5);
    border-radius: 46.477px;
    background: linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%);
    outline: solid linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%) 1.162px;
    padding: 14.267px 49.048px;
    cursor: pointer;
    transition: background 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;

    &:after {
      content: "›";
      width: 5.81px;
      height: 9.295px;
      flex-shrink: 0;
      color: #ffffff;
      margin-left: 5px;
      z-index: 2;
    }
  }
`;

const HorizontalLine = styled.svg`
  position: absolute;
  top: 44%;
  left: 90%;
  transform: translateX(-50%);
  width: 215.235px;
  height: 1px;

  &:hover {
    line {
      stroke: #fff;
    }
  }
`;

const PricingCardNew = ({
  heading,
  content,
  price,
  buttonText,
  footerHeading,
  options,
  index,
  linkTo,
}) => {
  const [isActive, setIsActive] = useState(1);
console.log(isActive,"isact");
  return (
    <div className="col-md-4">

     {isActive==true?
     <PricingCardWrapper
        isActive={true}
      
      >   
      

        <CardHeader  >
          <h2>{heading}</h2>
        </CardHeader>
        <CardContent>
          <p>{content}</p>
        </CardContent>
        <CardPrice>
          <p className="price">{price}</p>
          <p className="cpl">(Cost Per Lead) CPL</p>
        </CardPrice>
        <CardButton>
        <Link to={linkTo}>
          <button>{buttonText}</button>
          </Link>
          <HorizontalLine>
            <line
              x1="0"
              y1="0"
              x2="180.235"
              y2="0"
              style={{
                strokeWidth: "1.08px",
                stroke: "#565656",
              }}
            />
          </HorizontalLine>
        </CardButton>
        <CardFooter>
          <h3>{footerHeading}</h3>
        </CardFooter>
        <CardOptions>
          <ul>
            {options.map((option, idx) => (
              <li key={idx}>
                <img src="assets/images/Pricing-Check.webp" alt="WebP" />
                {option}
              </li>
            ))}
          </ul>
        </CardOptions>
      </PricingCardWrapper>:<PricingCardWrapper
     
      >   
      

        <CardHeader  >
          <h2>{heading}</h2>
        </CardHeader>
        <CardContent>
          <p>{content}</p>
        </CardContent>
        <CardPrice>
          <p className="price">{price}</p>
          <p className="cpl">(Cost Per Lead) CPL</p>
        </CardPrice>
        <CardButton>
          <button>{buttonText}</button>
          <HorizontalLine>
            <line
              x1="0"
              y1="0"
              x2="180.235"
              y2="0"
              style={{
                strokeWidth: "1.08px",
                stroke: "#565656",
              }}
            />
          </HorizontalLine>
        </CardButton>
        <CardFooter>
          <h3>{footerHeading}</h3>
        </CardFooter>
        <CardOptions>
          <ul>
            {options.map((option, idx) => (
              <li key={idx}>
                <img src="assets/images/Pricing-Check.webp" alt="WebP" />
                {option}
              </li>
            ))}
          </ul>
        </CardOptions>
      </PricingCardWrapper>}
    </div>
  );
};

export default PricingCardNew;