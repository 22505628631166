import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import "bootstrap/dist/css/bootstrap.min.css";

// Define the button component separately
const ButtonComponent = styled.button`
  position: relative;
  color: #fff;
  text-align: center;
  font-family: Soleil;
  font-size: 13.943px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.943px;
  border: 4px solid #0e1522;
  border-radius: 46.477px;
  background: var(
    --Gradient,
    linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%)
  );
  outline: solid var(--Gradient, #1f86e3) 1.162px;
  padding: 14.267px 59.048px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  &::after {
    content: "›";
    width: 10px; /* Increase the width as needed */
    height: 16px; /* Increase the height as needed */
    flex-shrink: 0;
    color: #ffffff;
    margin-left: 15px; /* Adjust this margin as needed */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  margin-top: 20px; // Add margin-top to create space above the button
`;

const MiniThirdSectionContainer = styled.div`
  position: relative;
  display: none; /* Hide by default */
  overscroll-behavior: none;

  @media (min-width: 768px) {
    display: flex;
    height: 600px;
    overflow: hidden;
    background: url("assets/images/home-page/third-mini.webp") center/cover;
  }
`;


const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* Add this line to make LeftColumn scrollable */
      /* Set a fixed height for the LeftColumn */
`;

const ContentContainer = styled.div`
  text-align: left;
  padding-left: 120px;
  padding-top: 130px;
`;

const Heading = styled.h2`
  color: ${(props) => props.color || "#fff"};
  font-size: ${(props) => props.fontSize || "38.171px"};
  font-weight: ${(props) => props.fontWeight || 400};
  line-height: ${(props) => props.lineHeight || "52.546px"};
  letter-spacing: ${(props) => props.letterSpacing || "-1.145px"};
  margin-bottom: 10px;
  font-family: Soleil;
`;

const Text = styled.p`
  color: ${(props) => props.color || "#666"};
  font-size: ${(props) => props.fontSize || "16px"};
  font-family: Soleil;
  line-height: ${(props) => props.lineHeight || "35.626px"};
`;

const RightColumn = styled.div`
  flex: 1;
  overflow-y: scroll;
  height: 100%;
`;

const MaskedImage = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const images = [
  "/assets/images/home-page/scroll3.1.webp",
  "/assets/images/home-page/scroll3.2.webp",
  "/assets/images/home-page/scroll3.3.webp",
  "/assets/images/home-page/scroll3.4.webp",
];

const contentData = {
  type1: {
    heading: (
      <>
        Real-Time Visitor <br />
        Identification
      </>
    ),
    content: (
      <>
        Know who's on your site at <br /> any moment.
      </>
    ),
    buttonLink: "/real-time",
  },
  type2: {
    heading: (
      <>
        Lead Scoring & <br />
        Prioritization
      </>
    ),
    content: "Focus on leads that matter",
    buttonLink: "/lead-scoring",
  },
  type3: {
    heading: (
      <>
        Google Analytics <br />
        Enrichment
      </>
    ),
    content: (
      <>
        Enhance your existing analytics <br /> for deeper insights
      </>
    ),
    buttonLink: "/analytics",
  },
  type4: {
    heading: "Seamless Integration",
    content: (
      <>
        Works effortlessly with your <br /> favorite tools
      </>
    ),
    buttonLink: "/Seamless-Integration",
  },
  type5: {
    heading: "Seamless Integration",
    content: (
      <>
        Works effortlessly with your <br /> favorite tools
      </>
    ),
    buttonLink: "/Seamless-Integration",
  },
};

// Shared style object for common typography
const commonTypography = {
  fontFamily: "Soleil",
  fontStyle: "normal",
  fontWeight: 400,
  letterSpacing: "-1.145px",
};

// Apply common typography to all content types
Object.values(contentData).forEach((content) => {
  content.typography = {
    heading: {
      ...commonTypography,
      color: "#FFF",
      fontSize: "38.171px",
      lineHeight: "52.546px",
    },
    content: {
      ...commonTypography,
      color: "#BBB",
      fontSize: "18px",
      lineHeight: "28.626px",
    },
  };
});
/* Example CSS to disable overscroll behavior for the entire body */

// ... (your imports)
// ... (your imports)

// ... (your imports)

const MiniThirdSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const rightColumnRef = useRef(null);

  const handleScroll = () => {
    // Calculate the index of the currently visible image based on scroll position
    const scrollTop = rightColumnRef.current.scrollTop;
    const index = Math.floor(scrollTop / rightColumnRef.current.clientHeight);
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    rightColumnRef.current.addEventListener('scroll', handleScroll);
    return () => {
      rightColumnRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []); // Add dependencies if needed

  return (
    <MiniThirdSectionContainer style={{ display: "flex", flexDirection: "row" }}>
      <RightColumn ref={rightColumnRef} style={{ display: "flex", flexDirection: "row", position: "relative" }}>
        <div style={{ width: "700px", position: "sticky", top: "50px" }}>
          {Object.values(contentData).map((content, index) => (
            <ContentContainer key={index} style={{ display: currentImageIndex === index ? 'block' : 'none' }}>
              <Heading {...content.typography.heading}>
                {content.heading}
              </Heading>
              <Text {...content.typography.content}>
                {content.content}
              </Text>
              <ButtonComponent>Know More</ButtonComponent>
            </ContentContainer>
          ))}
        </div>
        <div style={{ width: "800px", height: "800px" }}>
          {images.map((image, index) => (
            <MaskedImage key={index}>
              <Image src={image} alt={`Image ${index + 1}`} />
            </MaskedImage>
          ))}
        </div>
      </RightColumn>
    </MiniThirdSectionContainer>
  );
};

export default MiniThirdSection;