import React, { useState } from "react";
import styled from "styled-components";
const MobileSection = () => {
  const [activeButton, setActiveButton] = useState(1);
  const contentData = [
    {
      heading:
        "Define your ideal customer profile (ICP) & segment the visitors",
      content:
        `Automatically create groups of good-fit leads matching your ideal customer profile Or combine them with high-intent behavior.\n    \nThis will help you segment and score the visitors and focus only on the ones that qualify.Our industry filters match LinkedIn so if you are unsure what industries to select you can check out the industries your customers are listed under on LinkedIn It's important to cover include related industries too just like the screenshot above.`,
      videoPath: "https://intentbuy.io/Lead-Scoring/Segment-your-Visitors.mp4",
    },
    {
      heading: "Score Your Leads",
      content:
        "Combine company attributes such as industry, size and location with behaviour like time on site or pricing page visits. We will then automatically capture, save and send you ideal leads",
      videoPath: "https://intentbuy.io/Lead-Scoring/Score-Your-Leads.mp4",
    },
    {
      heading: "Connect your Google Analytics",
      content:
        "Elevate your Google Analytics to a B2B powerhouse in three clicks. Enrich Anonymous GA traffic with detailed company data for optimised marketing: convert quality leads, craft ideal retargeting, and create lookalike audiences. Exclude unfit visitors, reducing acquisition costs without upping your ad budget.",
      videoPath:
        "https://intentbuy.io/Lead-Scoring/Connect-Google-Analytics.mp4",
    },
    {
      heading: "Integrate your tools",
      content:
        "Integrate our intent-driven sales intelligence platform with your tools to effortlessly connect with your target audience. Share information with your sales team, leveraging high-intent accounts and our extensive dataset of over 540 million contacts. Instantly locate individuals at any company with our accurate, fast, double-verified AI-driven process.",
      videoPath: "https://intentbuy.io/Lead-Scoring/Integrate-your-tools.mp4",
    },
  ];
  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };
  return (
    <Container>
      <Heading>{contentData[activeButton - 1].heading}</Heading>
      <Content >{contentData[activeButton - 1].content}</Content>
      {/* Switched the order for mobile */}
      <ButtonContainer>
      {["01", "02", "03", "04"].map((buttonNumber, index) => (
        <React.Fragment key={buttonNumber}>
          <Button
            onClick={() => handleButtonClick(buttonNumber)}
            active={buttonNumber === activeButton}
          >
            {buttonNumber}
          </Button>
          {index < 3 && <ConnectingLine />}
        </React.Fragment>
      ))}
    </ButtonContainer>
         
      <VideoContainer   className="internal-video">
        <StyledVideo
         className="internal-video"
          src={contentData[activeButton - 1].videoPath}
          controls
          width="351.98px"
          height="198.23px"
          style={{ border: "2px solid #000", borderRadius: "20px" }}
        />
      </VideoContainer>
    </Container>
  );
};
const Container = styled.div`
  text-align: left;
  padding: 20px;
  min-height: 500px;
  margin-top: 20px;
  @media (min-width: 768px) {
    display: none; /* Hide for screens wider than 767px (non-mobile) */
  }
`;
const Heading = styled.div`
  color: var(--Gray-900, #2A3342);
  font-family: Soleil;
  font-size: 26px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.52px;
  margin-bottom: 25px;
`;
const Content = styled.div`
  color: var(--Gray-500, #556987);
  font-family: Soleil;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  white-space: pre-line;
  margin-bottom: 65px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  /* Additional styles for your content container */
  @media (max-width: 768px) {
    /* Styles for smaller screens */
  }
`;

const VideoContainer = styled.div`
  width: 100%;
   
  @media (min-width: 768px) {
    /* Styles for smaller screens */
    width: 100%;
    max-width: none;
    height: auto;
    .internal-video {
      /* Adjust height for mobile view */
      height: 300px; /* or your preferred height value */
    width:100%;
    paddingTop:"40px"
    max-width:"none";
    }
  }
`;
const StyledVideo = styled.iframe`
  width: 100%;
 height:221px;
  box-shadow: 5.253px 7.588px 22.123px 0px rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
  margin: 0 auto; /* Center the video section */
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom:60px;
 
`;

const Button = styled.button`
  position: relative;
  width: 34.991px;
  height: 34.991px;
  text-align: center;
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  flex-shrink: 0;
  stroke-width: 0.666px;
  stroke: ${(props) =>
    props.active ? "var(--Gradient, #1F86E3)" : "transparent"};
  background-color: ${(props) =>
    props.active
      ? "var(--Gradient, #1F86E3)"
      : "#D9D9D9"}; /* Adjust background color */
  color: ${(props) =>
    props.active ? "#FFF" : "#000"}; /* Set text color based on active state */
  font-family: Soleil;
  font-size: 14.654px;
  font-weight: 400;
  line-height: 19.982px;
  border: 2px solid transparent; /* Set the initial border width and color */
  border-radius: 50%;
  cursor: pointer;
  /* Adjust space between buttons and border */
  &:before {
    content: '';
    position: absolute;
    top: -10px; /* Adjust the gap between the button and the border */
    left: -10px; /* Adjust the gap between the button and the border */
    width: calc(100% + 20px); /* Adjust the total width including the gap */
    height: calc(100% + 20px); /* Adjust the total height including the gap */
    border: 1px solid var(--Gradient, #1F86E3);; /* Set the border width and color */
    border-radius: 50%;
    z-index: -1; /* Place the border below the button */
  }
  &:hover {
    background-color: ${(props) =>
      props.active
        ? "var(--Gradient, #1F86E3)"
        : "#D9D9D9"}; /* Adjust hover color */
    color: ${(props) =>
      props.active ? "#FFF" : "#FFF"}; /* Adjust hover color */
  }
`;


const ConnectingLine = styled.div`
  flex: 1;
  height: 1.5px;
  background-color: #000000; /* Line color */
  margin: 0 8px; /* Adjust the space between the line and buttons */
`;
export default MobileSection;