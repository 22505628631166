// Import necessary libraries and components
import React from "react";
import styled from "styled-components";

// Define styled component for the input field
const InputField = styled.input`
  width: 100%;
  border: none;
  outline: none;
  color: #7d7b7b;
  font-family: Soleil;
  font-size: 17.76px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.24px;
  padding-bottom: 15px;
  border-bottom: 1px solid #828282; /* Add bottom border style */
  margin-bottom: 15px;

  &::placeholder {
    color: #7d7b7b;
    font-family: Soleil;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.24px;
  }
`;

const TermsText = styled.span`
  color: #7d7b7b;
  font-family: Soleil;
  font-size: 12.787px;
  font-style: normal;
  font-weight: 300;
  line-height: 15.377px;
  /* Adjust other styles as needed */
`;

// Define the MobileContactUs component
const MobileContactUs = () => {
  return (
    <div
      className="container mt-5"
      style={{
        backgroundImage: 'url("assets/images/Contact-us/mobile-contact-us.webp")', // Replace with the actual path to your background image
        backgroundSize: "cover", // or "contain" depending on your preference
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    >

      {/* Heading and Content Section */}
      <div className="row justify-content-center mb-4 mobile-contact-us-row">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="text-center">
            {/* Heading */}
            <h1
              style={{
                fontFamily: "Soleil",
                fontSize: "32.905px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "46.615px",
                letterSpacing: "-0.987px",
              }}
            >
              Contact Us
            </h1>
          </div>
          <div className="text-center">
            {/* Content */}
            <p
              style={{
                color: "#343434",
                fontFamily: "Soleil",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Get in Touch to Experience the Future of Business Analytics with IntentBuy
            </p>
          </div>
        </div>
      </div>

      {/* Form Section */}
      <div className="row justify-content-center mobile-contact-us-row" style={{ margin:0, }}>
        <div
          className="col-12 col-md-8 col-lg-6"
          style={{
            width: "341.615px",
            height: "520.861px",
            flexShrink: 0,
            marginBottom: "-130px",
          }}
        >
          <form style={{ borderRadius: "10px", background: "#FFF", padding: "20px" }}>
            {/* Replace standard input with styled InputField */}
            <div className="mb-3">
              <InputField type="text" placeholder="First Name" />
            </div>
            <div className="mb-3">
              <InputField type="text" placeholder="Last Name" />
            </div>
            <div className="mb-3">
              <InputField type="email" placeholder="Email ID" />
            </div>
            <div className="mb-3">
              <InputField type="text" placeholder="Organization Name" />
            </div>
            <div className="mb-3">
              <InputField type="text" placeholder="Company Size" />
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="termsCheckbox"
              />
              <label className="form-check-label" htmlFor="termsCheckbox">
                <TermsText>Terms & Conditions Apply*</TermsText>
              </label>
            </div>
            <div className="mb-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MobileContactUs;
