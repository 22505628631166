import React from "react";
import styled from "styled-components";

const StyledFourthSection = styled.div`
  background-image: url('assets/images/real-time/RealTimeFourth.webp');
  background-size: cover; /* Adjust the background-size property as needed */
  background-position: center; /* Adjust the background-position property as needed */
  border-radius: 11.746px;
  height: 610px;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  margin-top: 30px;
  z-index: 1;

  .content-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Left-align the content */
    text-align: left;
    padding: 20px; /* Padding for top and bottom of the second row */
  }
  h2 {
    color: #fff;
    font-family: Soleil;
    font-size: 30px;
    font-weight: 700;
    line-height: 33.52px; /* 111.735% */
    letter-spacing: -0.6px;
    margin-bottom: 10px;
  }

  p {
    color: #bcc1ca;
    font-family: Soleil;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    letter-spacing: -0.28px;
    margin-bottom: 10px;
  }

  img {
    width: 100%;
    max-width: 315.852px;
    height: auto;
    margin-top: 45px; /* Adjusted margin-top for the image */
    margin-bottom: 20px 
  }

  video {
    border-radius: 24px;
    margin-top: 20px;
  }
`;

const MobileSectionRealTimeFourth = () => {
  return (
    <StyledFourthSection>
      {/* First Row - Image */}
      <div className="col-12 d-flex align-items-center justify-content-center">
        <img
          src="/assets/images/real-time/real-time-4.webp"
          alt="Your Alt Text"
          className="img-fluid"
        />
      </div>
      {/* Second Row - Heading and Paragraphs */}
      <div className="col-12 content-column">
        <h2>
          Catch the one
          <br /> that Got Away!!
        </h2>
        <p>
          We have all been there, after spending precious
          <br /> time on a potential buyer, they disappear.
        </p>
        <p>
          With our advanced form and email
          integration,<br /> you will know when they
          secretly return to your<br /> site,
          giving your sales team the right
          opportunity<br /> to close the sale.
        </p>
      </div>
    </StyledFourthSection>
  );
};

export default MobileSectionRealTimeFourth;
