// Banner.js
import React from "react";
import NavBar from "./Navbar";
import styled, { keyframes } from "styled-components";
import ImageMarqueeExample from "./Seamless-Marquee";
import ReverseImageMarqueeExample from "./Seamless-Marquee-Reverse"

const BannerContainer = styled.div`
  position: relative;
  height: 626.354px;
`;

const BannerContent = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const orbitAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) translateX(150px);
  }
  100% {
    transform: translate(-50%, -50%) translateX(-150px);
  }
`;

// eslint-disable-next-line
const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg) translateX(150px) rotate(0deg);
  animation: ${orbitAnimation} 10s linear infinite;
`;
const Overlay = styled.div`
  position: absolute;
  height: 100%;
  background: rgba(16, 28, 45, 0.8); /* Adjust opacity as needed */
`;

const handleRequestDemo = () => {

  window.location.href = "/#before-footer";
};

const BannerSeamlessIntegration = ({ transparent }) => {
  return (
    <>
      <BannerContainer>
        <div
          style={{
            backgroundImage: "url(/assets/images/Seamless-Integration1.webp)",
            backgroundSize: "contain",
            position: "absolute",
            width: "100%",
            height: "1000px",
            backgroundRepeat: "no-repeat",
          }}
        />
        <Overlay /> {/* Add the overlay */}
        <NavBar transparent={true} whiteText={true} customTextColor="#000" />
        <BannerContent>
          <h1
            style={{
              color: "#FFF",
              fontFamily: "Soleil",
              fontSize: "26px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "15px",
              opacity: "0.9913",
            }}
          >
            Enhance Your Workflow with our
          </h1>

          <h1
            style={{
              color: "#FFF",
              fontFamily: "Soleil",
              fontSize: "83.501px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "114.814px",
              letterSpacing: "-3.34px",
            }}
          >
            Seamless Integration
          </h1>

          <p
            style={{
              color: "#BBB",
              fontFamily: "Soleil",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "31px",
              marginBottom: "32px",
            }}
          >
            Seamlessly integrate Intentbuy into your existing tech stack to
            enrich <br />your marketing campaigns and boost your sales pipeline.
          </p>

          <button
            style={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "Soleil",
              fontSize: "16.943px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "13.943px",
              border: "4px solid #192F48",
              borderRadius: "49.055px",
              background:
                "var(--Gradient, linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%))",
              outline: "solid var(--Gradient, #1f86e3) 1.162px",
              padding: "14.267px 49.048px",
              cursor: "pointer",
              transition: "background 0.3s ease, box-shadow 0.3s ease",
              position: "relative",
            }}
            onClick={handleRequestDemo}>
            Request a Demo
            <span
              style={{
                content: '"›"',
                width: "5.81px",
                height: "9.295px",
                flexShrink: 0,
                color: "#ffffff",
                marginLeft: "5px",
                zIndex: 2,
                position: "absolute",
                top: "44%",
                transform: "translateY(-50%)",
              }}
            >
              ›
            </span>
          </button>
          <ImageMarqueeExample />
          <ReverseImageMarqueeExample />
        </BannerContent>
      </BannerContainer>
    </>
  );
};

export default BannerSeamlessIntegration;
