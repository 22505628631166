// ContinuousRotationCarousel.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const Container = styled.div`
  overflow: hidden;
  padding-top: 30px; 
  padding-bottom: 10px; 
`;

const CarouselWrapper = styled.div`
  display: flex;
  animation: ${rotate} 10s linear infinite;
`;

const CarouselItem = styled.img`
  width: 172.68px; // Set the width as needed
  height: 61.36px; // Set the height as needed
  margin-right: 4px; // Add margin between images
`;

const ContinuousRotationCarousel = ({ images }) => {
  const duplicatedImages = [...images, ...images]; // Duplicate images for seamless looping

  return (
    <Container>
      <CarouselWrapper>
        {duplicatedImages.map((image, index) => (
          <CarouselItem key={index} src={image} alt={`Image ${index + 1}`} />
        ))}
      </CarouselWrapper>
    </Container>
  );
};

export default ContinuousRotationCarousel;
