// ContinuousRotationCarouselReverse.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotateReverse = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const ContainerReverse = styled.div`
  overflow: hidden;
  position: relative;
`;

const CarouselWrapperReverse = styled.div`
  display: flex;
  animation: ${rotateReverse} 10s linear infinite;
`;

const CarouselItemReverse = styled.img`
width: 172.68px; // Set the width as needed
height: 61.36px; 
  margin-right: 2px; // Add margin between images
`;

const ContinuousRotationCarouselReverse = ({ images }) => {
  const duplicatedImages = [...images, ...images]; // Use the same images for seamless looping

  return (
    <ContainerReverse>
      <CarouselWrapperReverse>
        {duplicatedImages.map((image, index) => (
          <CarouselItemReverse key={index} src={image} alt={`Image ${index + 1}`} />
        ))}
      </CarouselWrapperReverse>
    </ContainerReverse>
  );
};

export default ContinuousRotationCarouselReverse;
