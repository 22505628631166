import React, { useState } from "react";
import styled from "styled-components";

// Styled components
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding-top: 80px;
`;

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 306px;
  margin: -50px auto;
  position: relative;
  z-index: 2;
  border-radius: 16px;
  background-image: url('assets/images/home-page/before-footer.webp'); /* Replace with the actual path to your image */
  background-size: cover;
  background-position: center;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h1`
  color: var(--White-900, #fff);
  font-family: Soleil;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.96px;
`;

const EmailField = styled.div`
  display: flex;
  width: 447.078px;
  height: 63.868px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-family: Soleil;
  font-size: 15.967px;
  font-style: normal;
  font-weight: 400; /* Change the font weight to 400 */
  line-height: normal;
  padding: 22.354px 31.934px;
  border-radius: 63.868px;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 0px 0px 0px 1.597px rgba(255, 255, 255, 0.20) inset;

  input {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    color: #fff;
    font-family: Soleil; /* Apply the same font family to the input */
    font-size: 15.967px; /* Apply the same font size to the input */
    font-style: normal;
    font-weight: 400; /* Change the font weight to 400 */
    &::placeholder {
      color: #fff;
    }
  }
`;


const Button = styled.button`
  width: 175.638px;
  height: 54.288px;
  flex-shrink: 0;
  border-radius: 63.868px;
  background: #fff;
  box-shadow: none;
  margin-left: auto;
  margin-right: -26px;
  border-color: #fff;

  color: #000;
  text-align: center;
  font-family: Soleil;
  font-size: 19.16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.354px; /* 116.667% */
`;

const SuccessMessage = styled.div`
  color: #000; /* Set the color to black */
  margin-top: 10px;
`;

// Component
const YourComponent = ({ customBackgroundColor }) => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setIsValidEmail(true); // Reset the validity when the email changes
  };

  const handleJoinNowClick = () => {
    // Validate the email
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    setIsValidEmail(isValid);

    // Proceed with further actions if the email is valid
    if (isValid) {
      console.log("Email is valid:", email);
      setShowSuccessMessage(true);
    }
  };

  return (
    <Container style={{ backgroundColor: customBackgroundColor || "#000" }}>
      <StyledComponent>
        <div style={{ textAlign: "center" }}>
          <Heading>Have any additional questions?</Heading>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
          <EmailField>
            <input type="email" value={email} onChange={handleEmailChange} placeholder="Email Address" />
            <Button onClick={handleJoinNowClick}>Get In Touch</Button>
          </EmailField>
        </div>
        {!isValidEmail && <div style={{ color: "red", marginTop: "10px" }}>Please enter a valid email address.</div>}
        {showSuccessMessage && <SuccessMessage>Form Successfully Submitted, We Will get in touch with you shortly.</SuccessMessage>}
      </StyledComponent>
    </Container>
  );
};

export default YourComponent;
