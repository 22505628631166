// FrequentlyAskedQuestion.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./FrequentlyAskedQuestion.css"; // You may need to adjust the CSS file name
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

// Replace the placeholder image paths with your actual image paths
const OpenArrowImage = "/assets/images/Lead-Scoring/Up-Arrow.webp";
const ClosedArrowImage = "/assets/images/Lead-Scoring/Down-Arrow.webp";

/* Default styles for all devices */
const additionalContentStyle = {
 
};



const FrequentlyAskedQuestion = ({
  heading,
  additionalContent,
  faqData,
  headingStyle,
  questionStyle,
}) => {
  const [openIndex, setOpenIndex] = useState(-1); // State to track the open FAQ index

  const handleToggleFAQ = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };



  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-sm-12 mx-auto" style={{ paddingTop: "40px" }}>
          <div className="faq-heading" style={headingStyle}>
            {heading}
          </div>
          <div className="faq-content" style={additionalContentStyle}>
            {additionalContent}
          </div>
        </div>
        <div className="col-md-6">
          {faqData.map((faq, index) => (
            <div key={index} className="faq-item">
              <div
                className={`faq-question ${openIndex === index ? "open" : ""}`}
                onClick={() => handleToggleFAQ(index)}
                style={questionStyle}
              >
                {faq.question}
                <img
                  src={openIndex === index ? OpenArrowImage : ClosedArrowImage}
                  alt="Arrow"
                  style={{
                    width: "24px",
                    height: "24px",
                    flexShrink: 0,
                    marginTop: openIndex === index ? "-2px" : "0",
                    transition:
                      "margin 0.5s cubic-bezier(0.4, 0, 0.2, 1), max-height 0.6s cubic-bezier(0.4, 0, 0.2, 1)",
                  }}
                />
              </div>
              <div
                className="faq-answer"
                style={{
                  maxHeight: openIndex === index ? "1000px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
                }}
              >
                {faq.answer}
              </div>
              {index === faqData.length - 1 ? null : <hr className="faq-divider" />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

FrequentlyAskedQuestion.propTypes = {
  heading: PropTypes.string.isRequired,
  additionalContent: PropTypes.node, // Accept any valid React node for additional content
  faqData: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FrequentlyAskedQuestion;
