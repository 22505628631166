import React, { useState } from 'react';
import { Carousel, Card, Container } from 'react-bootstrap';
import styled from 'styled-components';

const SecondaryDarkGrey900 = '#1B2559';
const SecondaryDarkGrey600 = '#68769F';

// Styled components
const StyledCarousel = styled(Carousel)`
  height: 400px;
`;

const StyledIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 150px;
`;

const StyledIndicator = styled.div`
  height: 5px;
  width: 20px;
  background-color: ${({ isActive }) => (isActive ? '#269AF5' : '#D9D9D9')}; // Updated color for active and non-active
  border-radius: 5px; // Added border-radius
  margin: 0 5px;
  cursor: pointer;
`;


const StyledCardBody = styled(Card.Body)`
  border-radius: 0px 0px 31.804px 31.804px;
  background: #fff;
  box-shadow: 0px 3.797px 3.797px 0px rgba(0, 0, 0, 0.15);
  
`;

const StyledCard = styled(Card)`
  border: none;
  height: 100%;
  margin-bottom: 15px;
`;

const StyledCardTitle = styled(Card.Title)`
  color: ${SecondaryDarkGrey900};
  text-align: center;
  font-family: Soleil;
  font-size: 22.65px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.783px;
  letter-spacing: -0.906px;
`;

const StyledCardText = styled(Card.Text)`
  color: ${SecondaryDarkGrey600};
  text-align: center;
  font-family: Soleil;
  font-size: 12.08px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.63px;
`;

const CardSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <Container className="my-4">
      <StyledCarousel activeIndex={activeIndex} onSelect={handleSelect} controls={false} indicators={false}>
        <Carousel.Item>
          <StyledCard>
            <Card.Img variant="top" src="assets/images/analytics/analytics-card-1.webp" />
            <StyledCardBody>
              <StyledCardTitle>Optimizing Leads and Budget</StyledCardTitle>
              <StyledCardText>
                Leverage these enhanced insights from Google Analytics to better
                understand your marketing performance. This enables you to
                attract more qualified leads while optimizing your budget.
              </StyledCardText>
            </StyledCardBody>
          </StyledCard>
        </Carousel.Item>

        <Carousel.Item>
          <StyledCard>
            <Card.Img variant="top" src="assets/images/analytics/analytics-card-2.webp" />
            <StyledCardBody>
              <StyledCardTitle>Advanced Audience Segmentation</StyledCardTitle>
              <StyledCardText>
                Additionally, create sophisticated audience segments, like
                retargeting and lookalike groups, based on detailed company
                data.
              </StyledCardText>
            </StyledCardBody>
          </StyledCard>
        </Carousel.Item>

        <Carousel.Item>
          <StyledCard>
            <Card.Img variant="top" src="assets/images/analytics/analytics-card-3.webp" />
            <StyledCardBody>
              <StyledCardTitle>Boosting Quality Conversions</StyledCardTitle>
              <StyledCardText>
                You can reduce your cost per acquisition and increase
                high-quality conversions, all within your current budget, using
                the enhanced capabilities of Google Analytics through this
                feature.
              </StyledCardText>
            </StyledCardBody>
          </StyledCard>
        </Carousel.Item>
      </StyledCarousel>

      <StyledIndicators>
        {[0, 1, 2].map((index) => (
          <StyledIndicator
            key={index}
            onClick={() => setActiveIndex(index)}
            isActive={activeIndex === index}
          />
        ))}
      </StyledIndicators>
    </Container>
  );
};

export default CardSlider;
