// Home.js
import React, { useRef } from "react";
import NavBar from "./component/Navbar";
import FrequentlyAskedQuestion from "./component/FrequentlyAskedQuestion";
import "./css/analytics.css";
import YouTube from "react-youtube";
import PageTitle from "./PageTitle";
import ResponsiveFooter from "./component/footer";
import ScrollComponent from "./component/AnalyticsSecond";
import styled from "styled-components";
import MobileAnalyticsSection from "./component/Mobile-Component/AnalyticsThirdSection";
import CardSlider from "./component/Mobile-Component/AnalyticsMobileSecond";
import MobileNavbar from "./component/Mobile-Component/MobileNavbar";
import AnalyticsFirstMobileComponent from "./component/Mobile-Component/AnalyticsFirst";
import BeforeFooterMobile from "./component/Mobile-Component/AnalyticsFooter";
import MobileFooter from "./component/Mobile-Component/MobileFooter";

const DesktopOnly = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;



const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

function GAAnalytics() {
  const playerRef = useRef(null);

  // Video options, you can customize these as needed
  const opts = {
   
   
  
    height: "800",
    width: "1200",
    borderRadius:"20px !important",
    playerVars: {
      autoplay: 0, // Autoplay off
    },
  };

  // YouTube video onReady callback
  const onReady = (event) => {
    // Do something when the video is ready
    console.log("Video is ready:", event);
  };

  // Handle row click event
  const onRowClick = (startTime, heading, paragraph) => {
    // Seek to the specified timestamp using the stored player instance
    if (playerRef.current) {
      playerRef.current.seekTo(startTime, true);
    }

    // Do something with the heading and paragraph (e.g., log or navigate)
    console.log("Clicked on:", heading, paragraph);
  };

  // YouTube video onPlay callback
  const onPlay = (event) => {
    // Store the YouTube player instance in the ref
    playerRef.current = event.target;
  };

  const homePageHeading = "Frequently Asked Questions";
  const homePageAdditionalContent = (
    <>
      <p>
        Our platform is engineered to transform your
        <br /> website's anonymous traffic into a goldmine
        <br /> of qualified leads
      </p>
      {/* Add more lines as needed */}
    </>
  );
  const homePageFaqData = [
    {
      question: "How do I access data in GA?",
      answer:
        "Company attributes are synced into Google Analytics as custom dimension with which you can enrich or filter reports, create audiences and build dashboards from.",
    },
    {
      question: "Do I need technical know-how to configure the integration?",
      answer:
        "Nope, no technical skills are required to set up the integrations. It's just a one-click install. We are also available to support you if required.",
    },
    {
      question: "Do you support GA4?",
      answer: "Yes we do!",
    },
    {
      question: "What if I need help building reports and dashboards?",
      answer:
        "We have pre-built Looker Studio (GDS) dashboards for you that include website overviews, paid and organic marketing reports along with account based reporting dashboards.",
    },
  ];

  

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexShrink: 0,
    paddingBottom: "30px",
    borderRadius: "20px", // Set the border radius for top edges
    overflow: "hidden", // Ensure overflow is hidden to mask the YouTube video's corners
  };
  
  const youtubeStyle = {
    width: "100%",
    height: "100%",
    borderTopLeftRadius: "20px", // Set the border radius for top edges
    borderTopRightRadius: "20px ",
    borderBottomRightRadius:"20px!important",
    borderBottomLeftRadius:"20px!important" // Set the border radius for top edges
  };
  const handleRequestDemo = () => {

    window.location.href = "/#before-footer";
  };

  return (
    <div>
      <PageTitle title={"GA Analytics"} />
      <DesktopOnly>
        <NavBar />
      </DesktopOnly>
      <MobileOnly>
        <MobileNavbar whiteText={false} />
      </MobileOnly>
      <DesktopOnly>
        <div className="container mt-5">
          {/* Uncover Insights Into Visitor Behavior */}
          <div className="CustomSection row">
            <div className="col-md-7">
              {/* B2B Analytics Heading */}
              <h2 className="mb-0">Google Analytics Reimagined for</h2>
              <div className="b2b-analytics-heading">
                <span className="analytics-text">B2B </span>
                <span> Analytics</span>
              </div>
              <p className="text-muted mb-5">
                Tailor Google Analytics for B2B. Go beyond tracking individual
                site
                <br /> visitors. With Intentbuy, turn Google Analytics into a
                tool that
                <br /> gives a complete view of your business customer
                interactions,
                <br />
                enhancing your B2B marketing strategy.
              </p>
              <div className="ButtonsContainer">
                <button className="DemoButton" onClick={handleRequestDemo}>Request a Demo</button>
                <button className="SignUpButton" onClick={handleRequestDemo}>Sign Up</button>
              </div>
            </div>

            <div className="col-md-5">
              <img
                src="/assets/images/analytics/analysis-first.webp"
                alt="Illustration of Visitor Behavior Insights"
                className="img-fluid"
                style={{
                  height: "auto", // Set the height here
                  fill: "linear-gradient(109deg, #EEF0F3 0.33%, #D5DAE1 96.11%)",
                }}
              />
            </div>
          </div>
        </div>
      </DesktopOnly>
      <MobileOnly>
        <AnalyticsFirstMobileComponent />
      </MobileOnly>
      <DesktopOnly>
        <div className="container-fluid" style={{ paddingTop: "125px" }}>
          <ScrollComponent />
        </div>
      </DesktopOnly>
      <MobileOnly>
        <CardSlider />
      </MobileOnly>
      {/* Third section */}
      <DesktopOnly>
        <div
          className="analytics-section"
          style={{ paddingTop: "60px", paddingBottom: "20px" }}
        >
          <div className="column" id="column1">
            <div
              className="row"
              onClick={() =>
                onRowClick(1, "Analyze", "Filter paid and organic...")
              }
            >
              <img
                src="assets/images/analytics/analytics-3.1.webp"
                alt="Icon 1"
              />
              <h3>Analyze</h3>
            </div>
            <div
              className="row"
              onClick={() =>
                onRowClick(
                  1,
                  "Analyze",
                  "Track the changes you make without needing to wait for conversions, so you can test more with less risk and make sure you hit your number within budget."
                )
              }
            >
              <p>
                Filter paid and organic marketing actions by
                <br /> the amount of target company traffic they
                <br /> drive, so you can cut costs with confidence
                <br />
                and double down on winners.
              </p>
            </div>
          </div>

          {/* Second section */}
          <div className="column" id="column2">
            <div
              className="row"
              onClick={() =>
                onRowClick(27, "Enrich", "Know your traffic by name...")
              }
            >
              <img
                src="assets/images/analytics/analytics-3.1.webp"
                alt="Icon 2"
              />
              <h3>Enrich</h3>
            </div>
            <div
              className="row"
              onClick={() =>
                onRowClick(
                  27,
                  "Enrich",
                  "Track the changes you make without needing to wait for conversions, so you can test more with less risk and make sure you hit your number within budget."
                )
              }
            >
              <p>
                Know your traffic by name. <br />
                Numbers without context don’t tell much
                <br /> of a story, Intentbuy adds vital context to
                <br /> Google Analytics by enriching the numbers
                <br /> with company identifications. So you can
                <br /> spend less and convert more.
              </p>
            </div>
          </div>

          {/* Third section */}
          <div className="column" id="column3">
            <div
              className="row"
              onClick={() =>
                onRowClick(
                  40,
                  "Optimize",
                  "Track the changes you make without..."
                )
              }
            >
              <img
                src="assets/images/analytics/analytics-3.1.webp"
                alt="Icon 3"
                style={{ maxWidth: "200px" }} // Set the maximum width as needed
              />
              <h3>Optimize</h3>
            </div>
            <div
              className="row"
              onClick={() =>
                onRowClick(
                  40,
                  "Optimize",
                  "Track the changes you make without needing to wait for conversions, so you can test more with less risk and make sure you hit your number within budget."
                )
              }
            >
              <p>
                Track the changes you make without
                <br /> needing to wait for conversions, so you can
                <br /> test more with less risk and make sure you
                <br />
                hit your number within budget.
              </p>
            </div>
          </div>
        </div>
        {/* Video container */}
        <div
  id="video-container"
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // Use a percentage to make it responsive
    flexShrink: 0,
    paddingBottom: "30px",
    borderRadius: "20px!important" // Set the border radius to 20px
  }}
>
 <DesktopOnly>

<div className="video-container">
                  {/* Replace the video URL with your actual video URL */}
                  <iframe
                    className="video"
                    title="Video"
                    width="1097px"
                    height="630px"
                    src="https://www.youtube.com/embed/9WkT4yE-qh0"
                    allowFullScreen
                    style={{ border:"none !important",borderRadius: "14px" }}
                  ></iframe>
                </div>

        </DesktopOnly>

  
</div>
      </DesktopOnly>
      <MobileOnly>
        <MobileAnalyticsSection />
      </MobileOnly>
      
      <div
        style={{ ...containerStyle, marginLeft: "auto", marginRight: "auto" }}
      >
        <FrequentlyAskedQuestion
          heading={homePageHeading}
          additionalContent={homePageAdditionalContent}
          faqData={homePageFaqData}
        />
     
      </div>
      <MobileOnly>
        <BeforeFooterMobile />
      </MobileOnly>
      
      <DesktopOnly>
      <ResponsiveFooter customBackgroundColor={true} />
      </DesktopOnly>
      <MobileOnly>
        <MobileFooter />
      </MobileOnly>
    </div>
  );
}

export default GAAnalytics;
