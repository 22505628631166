// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import RealTime from './real-time';
import LeadScoring from './lead-scoring';
import GAAnalytics from './analytics';
import SeamlessIntegration from './Seamless-Integration';
import Pricing from './Pricing';
import PrivacyPolicy from './Privacy-Policy';
import "./fonts.css";
import "./global.css";
import SeamlessIntegrationPage from './Test';
import ContactUsPage from './Contact-Us';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/real-time" element={<RealTime />} />
          <Route path="/lead-scoring" element={<LeadScoring />} />
          <Route path="/analytics" element={<GAAnalytics />} />
          <Route path="/Seamless-Integration" element={<SeamlessIntegration />} />
          <Route path='/Pricing' element={<Pricing />} />
          <Route path='/Privacy-Policy' element={ <PrivacyPolicy/>} />
          <Route path='/Seamless-integration-page' element={ <SeamlessIntegrationPage/>} />
          <Route path='/Contact-Us' element={<ContactUsPage />} />
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
