import React from "react";

const RealTimeSecondMobileSection = () => {
  return (
    <div className="MobileSection row mt-5 d-md-none align-items-center mx-auto">
      <div className="col-12">
        {/* Heading and Content for Mobile */}
        <h2
          className="mb-4 text-center"
          style={{
            color: "#0E1522",
            textAlign: "center",
            fontFamily: "Soleil",
            fontSize: "21px",
            fontStyle: "normal",
            fontWeight: 800,
            lineHeight: "30px",
            letterSpacing: "-0.46px",
            margin: "auto", /* Center the heading */
          }}
        >
          Discover the untapped potential of your website traffic!
        </h2>
        <div className="col-12 d-flex align-items-center justify-content-center">
          {/* Image for Mobile */}
          <img
            src="/assets/images/real-time-second.webp"
            alt="real-time-second"
            className="img-fluid"
            style={{
              width: "auto",
              height: "auto",
              flexShrink: 0,
              borderRadius: "12.702px",
              marginBottom: "20px", /* Add padding/margin only to the bottom */
            }}
          />
        </div>
        <p
          className="text-left"
          style={{
            color: "#626981",
            fontFamily: "Soleil",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "25px",
            width: "349.498px",
            margin: "auto", /* Center the paragraphs */
          }}
        >
          In a world where only about 2 percent of
          <br /> website visitors fill out a website form,
          you’re<br /> missing out on the other 98 percent!
          That’s the<br /> case many businesses are facing
          when it<br /> comes to website traffic.
        </p>
      </div>
    </div>
  );
};

export default RealTimeSecondMobileSection;
