import React from "react";
import PageTitle from "./PageTitle";
import NavBar from "./component/Navbar";
import ResponsiveFooter from "./component/footer";
import styled from "styled-components";
import MobileNavbar from "./component/Mobile-Component/MobileNavbar";
import MobileFooter from "./component/Mobile-Component/MobileFooter";

const DesktopOnly = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const PrivacyPolicy = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh", // Set minimum height to full viewport height
    backgroundColor: "#0e1522", // Set the background color for the entire page
  };

  const PageContent = styled.div`
    flex: 1;
    padding: 80px 100px;
    background-color: #0e1522;
    color: #ffffff;

    @media (max-width: 767px) {
      padding: 60px 20px; /* Adjust as needed for smaller screens */

      h1 {
        color: #fff;
        font-family: Soleil;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 30.315px;
        letter-spacing: -0.84px;
      }

      p {
        color: #bcc1ca;
        font-family: Soleil;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }

    @media (min-width: 768px) {
      /* Desktop styles */
      h1 {
        color: #fff;
        font-family: Soleil;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 61px;
        letter-spacing: -1.5px;
      }

      p {
        color: #bcc1ca;
        font-family: Soleil;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
  `;

  return (
    <div style={containerStyle}>
      <PageTitle title={"Privacy Policy"} />
      <DesktopOnly>
        <NavBar transparent={true} whiteText={true} backgroundColor="#0e1522" />
      </DesktopOnly>
      <MobileOnly>
        <MobileNavbar whiteText={true} />
      </MobileOnly>

      <PageContent>
        <h1>Privacy Policy</h1>
        <p>Updated at </p>
        <p>
          {" "}
          (“we,” “our,” or “us”) is committed to protecting your privacy. This
          Privacy Policy explains how your personal information is collected,
          used, and disclosed by .
        </p>
        <p>
          This Privacy Policy applies to our website, and its associated
          subdomains (collectively, our “Service”) alongside our application, .
          By accessing or using our Service, you signify that you have read,
          understood, and agree to our collection, storage, use, and disclosure
          of your personal information as described in this Privacy Policy and
          our Terms of Service. This Privacy Policy was created with{" "}
          <a href="https://termify.io" rel="noopener noreferrer">
            Termify
          </a>
          .
        </p>
        <br />
        <h1>Definitions and key terms</h1>
        <p>
          To help explain things as clearly as possible in this Privacy Policy,
          every time any of these terms are referenced, are strictly defined as:
          <ul>
            <li>
              Cookie: small amount of data generated by a website and saved by
              your web browser. It is used to identify your browser, provide
              analytics, remember information about you such as your language
              preference or login information.
            </li>
            <li>
              Company: when this policy mentions “Company,” “we,” “us,” or
              “our,” it refers to , that is responsible for your information
              under this Privacy Policy.
            </li>
            <li>
              Country: where or the owners/founders of are based, in this case
              is{" "}
            </li>
            <li>
              Customer: refers to the company, organization or person that signs
              up to use the Service to manage the relationships with your
              consumers or service users.
            </li>
            <li>
              Device: any internet connected device such as a phone, tablet,
              computer or any other device that can be used to visit and use the
              services.
            </li>
            <li>
              IP address: Every device connected to the Internet is assigned a
              number known as an Internet protocol (IP) address. These numbers
              are usually assigned in geographic blocks. An IP address can often
              be used to identify the location from which a device is connecting
              to the Internet.
            </li>
            <li>
              Personnel: refers to those individuals who are employed by or are
              under contract to perform a service on behalf of one of the
              parties.
            </li>
            <li>
              Personal Data: any information that directly, indirectly, or in
              connection with other information — including a personal
              identification number — allows for the identification or
              identifiability of a natural person.
            </li>
            <li>
              Service: refers to the service provided by as described in the
              relative terms (if available) and on this platform.
            </li>
            <li>
              Third-party service: refers to advertisers, contest sponsors,
              promotional and marketing partners, and others who provide our
              content or whose products or services we think may interest you.
            </li>
            <li>Website: 's site, which can be accessed via this URL: </li>
            <li>
              You: a person or entity that is registered with to use the
              Services.
            </li>
          </ul>
        </p>
        <br /> <h1>Information automatically collected</h1>
        <p>
          There is some information like your Internet Protocol (IP) address
          and/or browser and device characteristics — is collected automatically
          when you visit our platform. This information may be used to connect
          your computer to the Internet. Other information collected
          automatically could be a login, e-mail address, password, computer and
          connection information such as browser plug-in types and versions and
          time zone setting, operating systems and platforms, purchase history,
          (we sometimes aggregate with similar information from other Users),
          the full Uniform Resource Locator (URL) clickstream to, through and
          from our Website that may include date and time; cookie number; parts
          of the site you viewed or searched for; and the phone number you used
          to call our Customer Services. We may also use browser data such as
          cookies, Flash cookies (also known as Flash Local Shared Objects) or
          similar data on certain parts of our Website for fraud prevention and
          other purposes. During your visits, we may use software tools such as
          JavaScript to measure and collect session information including page
          response times, download errors, length of visits to certain pages,
          page interaction information (such as scrolling, clicks, and
          mouse-overs), and methods used to browse away from the page. We may
          also collect technical information to help us identify your device for
          fraud prevention and diagnostic purposes.
        </p>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the platform. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about who
          and when you use our and other technical information. This information
          is primarily needed to maintain the security and operation of
          our platform, and for our internal analytics and reporting purposes.
        </p>
        <br /> <h1>Sale of Business</h1>
        <p>
          We reserve the right to transfer information to a third party in the
          event of a sale, merger or other transfer of all or substantially all
          of the assets of or any of its Corporate Affiliates (as defined
          herein), or that portion of or any of its Corporate Affiliates to
          which the Service relates, or in the event that we discontinue our
          business or file a petition or have filed against us a petition in
          bankruptcy, reorganization or similar proceeding, provided that the
          third party agrees to adhere to the terms of this Privacy Policy.
        </p>
        <br />
        <h1>Affiliates</h1>
        <p>
          We may disclose information (including personal information) about you
          to our Corporate Affiliates. For purposes of this Privacy Policy,
          "Corporate Affiliate" means any person or entity which directly or
          indirectly controls, is controlled by or is under common control with
          , whether by ownership or otherwise. Any information relating to you
          that we provide to our Corporate Affiliates will be treated by those
          Corporate Affiliates in accordance with the terms of this Privacy
          Policy.
        </p>
        <br />
        <h1>Governing Law</h1>
        <p>
          This Privacy Policy is governed by the laws of without regard to its
          conflict of laws provision. You consent to the exclusive jurisdiction
          of the courts in connection with any action or dispute arising between
          the parties under or in connection with this Privacy Policy except for
          those individuals who may have rights to make claims under Privacy
          Shield, or the Swiss-US framework.
        </p>
        <p>
          The laws of , excluding its conflicts of law rules, shall govern this
          Agreement and your use of the . Your use of the may also be subject to
          other local, state, national, or international laws.
        </p>
        <p>
          By using or contacting us directly, you signify your acceptance of
          this Privacy Policy. If you do not agree to this Privacy Policy, you
          should not engage with our website, or use our services. Continued use
          of the website, direct engagement with us, or following the posting of
          changes to this Privacy Policy that do not significantly affect the
          use or disclosure of your personal information will mean that you
          accept those changes.
        </p>
        <br />
        <h1>Your Consent</h1>
        <p>
          We've updated our Privacy Policy to provide you with complete
          transparency into what is being set when you visit our site and how
          it's being used. By using our , registering an account, or making a
          purchase, you hereby consent to our Privacy Policy and agree to its
          terms.
        </p>
        <br />
        <h1>Links to Other Websites</h1>
        <p>
          This Privacy Policy applies only to the Services. The Services may
          contain links to other websites not operated or controlled by . We are
          not responsible for the content, accuracy or opinions expressed in
          such websites, and such websites are not investigated, monitored or
          checked for accuracy or completeness by us. Please remember that when
          you use a link to go from the Services to another website, our Privacy
          Policy is no longer in effect. Your browsing and interaction on any
          other website, including those that have a link on our platform, is
          subject to that website’s own rules and policies. Such third parties
          may use their own cookies or other methods to collect information
          about you.
        </p>
        <br /> <h1>Cookies</h1>
        <p>
          {" "}
          uses "Cookies" to identify the areas of our website that you have
          visited. A Cookie is a small piece of data stored on your computer or
          mobile device by your web browser. We use Cookies to enhance the
          performance and functionality of our but are non-essential to their
          use. However, without these cookies, certain functionality like videos
          may become unavailable or you would be required to enter your login
          details every time you visit the as we would not be able to remember
          that you had logged in previously. Most web browsers can be set to
          disable the use of Cookies. However, if you disable Cookies, you may
          not be able to access functionality on our website correctly or at
          all. We never place Personally Identifiable Information in Cookies.
        </p>
        <br />
        <h1>Blocking and disabling cookies and similar technologies</h1>
        <p>
          Wherever you're located you may also set your browser to block cookies
          and similar technologies, but this action may block our essential
          cookies and prevent our website from functioning properly, and you may
          not be able to fully utilize all of its features and services. You
          should also be aware that you may also lose some saved information
          (e.g. saved login details, site preferences) if you block cookies on
          your browser. Different browsers make different controls available to
          you. Disabling a cookie or category of cookie does not delete the
          cookie from your browser, you will need to do this yourself from
          within your browser, you should visit your browser's help menu for
          more information.
        </p>
        <br /> <h1>Changes To Our Privacy Policy</h1>
        <p>
          We may change our Service and policies, and we may need to make
          changes to this Privacy Policy so that they accurately reflect our
          Service and policies. Unless otherwise required by law, we will notify
          you (for example, through our Service) before we make changes to this
          Privacy Policy and give you an opportunity to review them before they
          go into effect. Then, if you continue to use the Service, you will be
          bound by the updated Privacy Policy. If you do not want to agree to
          this or any updated Privacy Policy, you can delete your account.
        </p>
        <br />
        <h1>Third-Party Services</h1>
        <p>
          We may display, include or make available third-party content
          (including data, information, applications and other products
          services) or provide links to third-party websites or services
          ("Third- Party Services").
        </p>
        <p>
          You acknowledge and agree that shall not be responsible for any
          Third-Party Services, including their accuracy, completeness,
          timeliness, validity, copyright compliance, legality, decency, quality
          or any other aspect thereof. does not assume and shall not have any
          liability or responsibility to you or any other person or entity for
          any Third-Party Services.
        </p>
        <p>
          Third-Party Services and links thereto are provided solely as a
          convenience to you and you access and use them entirely at your own
          risk and subject to such third parties' terms and conditions.
        </p>
        <ul></ul>
        <br />
        <h1>Contact Us</h1>
        <p>Don't hesitate to contact us if you have any questions.</p>
        <ul>
          <li>
            Via Email: <a href="mailto:info@intentbuy.io">info@intentbuy.io</a>
          </li>
        </ul>
      </PageContent>
      <DesktopOnly>
        <ResponsiveFooter customBackgroundColor={true} />
      </DesktopOnly>
      <MobileOnly>
        <MobileFooter />
      </MobileOnly>
    </div>
  );
};

export default PrivacyPolicy;
