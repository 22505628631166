import React from "react";

const RealTimeFirstMobileSection = () => {
  return (
    <div className="PreFirstSectionMobile row mt-3 d-md-none mx-auto">
      {/* Mobile Column */}
      <div
        className="MobileColumn col-12 d-flex flex-column align-items-center justify-content-center"
        style={{ marginBottom: "10px" }}
      >
        <h2
          className="mb-4"
          style={{
            color: "var(--Gray-900, #2A3342)",
            fontFamily: "Soleil",
            fontSize: "28.599px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "39.013px",
            letterSpacing: "-0.612px",
            textAlign: "center",
            width: "100vw",
          }}
        >
          <span
            style={{
              color: "var(--Gray-900, #2A3342)",
              fontFamily: "Soleil",
              fontSize: "23px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "34px",
              letterSpacing: "-0.46px",
            }}
          >
            Uncover Insights Into
          </span>
          <br /> Visitor Behavior Instantly
        </h2>

        <p
          className="text-muted mb-4"
          style={{
            color: "#626981",
            fontFamily: "Soleil",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "center",
            width: "360.255px", // Set the width for the paragraph
          }}
        >
          Identify your website visitors, what pages
          they<br /> visit, and what actions they take on
          your website<br /> in real time!
        </p>
      </div>

      {/* Right Column for Mobile */}
      <div
        className="RightColumnMobile col-12 d-flex align-items-center justify-content-center"
        style={{ padding: "0", overflow: "hidden" }}
      >
        <img
          src="/assets/images/real-time/real-time-12.webp"
          alt="Illustration for PreFirst Section"
          className="img-fluid"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default RealTimeFirstMobileSection;
