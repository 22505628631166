import React from "react";

const AnalyticsFirstMobileComponent = () => {
  const handleRequestDemo = () => {

    window.location.href = "/#before-footer";
  };
  return (
    <div className="container mt-5 mb-5 d-md-none text-center">
      {/* Uncover Insights Into Visitor Behavior (Mobile Version) */}
      <div className="CustomSectionMobile">
        {/* Headings */}
        <div className="col-12 text-center">
          <h3
            style={{
              color: "#2A3342",
              fontFamily: "Soleil",
              fontSize: "20.166px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "42.659px",
              opacity: 0.9913,
              marginBottom: "-10px",
            }}
          >
            Google Analytics Reimagined for
          </h3>
          <h2
            style={{
              color: "#2A3342",
              fontFamily: "Soleil",
              fontSize: "20.166px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "42.659px",
              opacity: 0.9913,
            }}
          >
            <span
              style={{
                fontFamily: "Soleil",
                fontSize: "51.401px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "70.677px",
                letterSpacing: "-2.056px",
                opacity: 0.9913,
                background:
                  "var(--Gradient, linear-gradient(91deg, #1F86E3 14.24%, #1B3B97 100%))",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              B2B
            </span>{" "}
            <span
              style={{
                color: "#101C2D",
                fontFamily: "Soleil",
                fontSize: "51.401px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "70.677px",
                letterSpacing: "-2.056px",
                opacity: 0.9913,
              }}
            >
              Analytics
            </span>
          </h2>
        </div>
        {/* Image */}
        <div className="col-12 d-flex justify-content-center">
          <img
            src="/assets/images/analytics/analysis-first.webp"
            alt="Illustration of Visitor Behavior Insights (Mobile Version)"
            className="img-fluid"
            style={{
              width: "100%", // Set the width to 100% to make it responsive
              padding: "15px 0",
            }}
          />
        </div>
        {/* Content */}
        <div className="col-12 text-center">
          <p
            style={{
              color: "#626981",
              fontFamily: "Soleil",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "31px",
              opacity: 0.9913,
              marginTop: "30px",
            }}
          >
            Tailor Google Analytics for B2B. Go beyond tracking individual site
            visitors. With Intentbuy, turn Google Analytics into a tool that
            gives a complete view of your business customer interactions,
            enhancing your B2B marketing strategy.
          </p>
          {/* Buttons */}
          {/* Buttons */}
          <div className="ButtonsContainerMobile">
            <button
              className="DemoButtonMobilenew"
              style={{
                color: "#fff",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "13.943px",
                border: "4px solid white",
                borderRadius: "46.477px",
                background:
                  "var(--Gradient, linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%))",
                outline: "solid var(--Gradient, #1f86e3) 1.162px",
                padding: "14.267px 49.048px",
                cursor: "pointer",
                transition: "background 0.3s ease, box-shadow 0.3s ease",
                marginBottom: "10px", // Add this line for spacing
              }}
              onClick={handleRequestDemo}>
              Request a Demo
              <span
                style={{
                  content: '"›"',
                  width: "5.81px",
                  height: "9.295px",
                  flexShrink: 0,
                  color: "#ffffff",
                  marginLeft: "5px",
                  zIndex: 2,
                  position: "absolute",
                }}
              >
                ›
              </span>
            </button>
          </div>

          <div className="ButtonsContainerMobile">
            <button
              className="SignUpButtonnew"
              style={{
                display: "inline-flex",
                height: "56px",
                padding: "16px 28px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                flexShrink: 0,
                borderRadius: "6px",
                background: "var(--White-900, #fff)",
                color: "var(--Gray-800, #333f51)",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "28px",
                borderColor: "transparent",
              }}
              onClick={handleRequestDemo}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsFirstMobileComponent;
