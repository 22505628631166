import React from "react";
import styled from "styled-components";

const MobileContainer = styled.div`
  /* Add any additional styling for the container */
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8); 
`;

const Heading = styled.h2`
  /* Typography styles for the heading */
  font-family: Soleil;
  font-size: 36.554px;
  font-style: normal;
  font-weight: 700;
  line-height: 46.209px;
  letter-spacing: -1.097px;

  /* Background gradient styles */
  background: var(--Gradient, linear-gradient(91deg, #1F86E3 14.24%, #1B3B97 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Opacity */
  opacity: 0.9913;
`;

const ContentText = styled.p`
  /* Typography styles for the content text */
  color: #626981;
  font-family: Soleil;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding-bottom: 10px;

  /* Opacity */
  opacity: 0.9913;
`;

const Button = styled.button`
  color: #fff;
  text-align: center;
  font-family: Soleil;
  font-size: 13.943px;
  font-style: normal;
  font-weight: 700;
  line-height: 13.943px;
  border: 4px solid white;
  border-radius: 46.477px;
  background: var(--Gradient, linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%));
  outline: solid var(--Gradient, #1f86e3) 1.162px;
  padding: 16.267px 29.048px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  /* Add any additional styles or hover effects here */
  &::after {
    content: "›";
    width: 5.81px;
    height: 9.295px;
    flex-shrink: 0;
    color: #ffffff;
    margin-left: 5px;
    z-index: 2;
  }
`;


const Image = styled.img`
width: 100vw; /* Full width of the viewport */
height: auto; /* Maintain the aspect ratio */
margin-top: 20px;
`;

const Subheading = styled.h3`
  color: #2A3342;
  text-align: center;
  font-family: Soleil;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  opacity: 0.9913;
  margin-bottom: -3px;
  margin-top: 40px;
`;

function MobileView() {
  return (
    <MobileContainer>
      <Subheading>Boost Your Sales with</Subheading>
      <Heading>Smarter Insights</Heading>
      <ContentText>Turn Anonymous Site Visits into Real<br /> Business Opportunities</ContentText>
      <Button>Get Started Today</Button>
      <Image src="assets/images/home-page/mob-first.webp" alt="Mobile Image" />
    </MobileContainer>
  );
}

export default MobileView;
