// Home.js
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./css/Home.css";
import TestimonialCarousel from "./component/TestimonialCarousel";
import BeforeFooter from "./component/BeforeFooter";
import styled, { css } from "styled-components";
import DesktopThirdSection from "./component/ThirdSection";
import NavBar from "./component/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import PageTitle from "./PageTitle";
import ResponsiveFooter from "./component/footer";
import MobileView from "./component/Mobile-Component/home-page-first";
import MobileViewSecond from "./component/Mobile-Component/home-page-second";
import TestimonialSlider from "./component/Mobile-Component/mobile-testimonial";
import MobileThirdSection from "./component/Mobile-Component/MobileThirdSection";
import MobileNavbar from "./component/Mobile-Component/MobileNavbar";
import MobileFooter from "./component/Mobile-Component/MobileFooter";

const DesktopOnly = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const CustomContainer = styled.div`
  /* Add any styles you need for the container */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeftSection = styled.div`
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-right: 50px;
    margin-bottom: 0;
  }
`;
const RightSection = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
  }
`;
const HeadingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentRow = styled.div`
  /* Add margin between rows */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BadgeText = styled.span`
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 36px;
  background: #f3faff;
  box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
`;

const FifthSectionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FifthSectionImage = styled.img`
  width: 100%; /* Ensure the image takes the full width of its container */
  height: auto; /* Maintain the aspect ratio */
  margin-bottom: 10px; /* Adjust the margin as needed */

  /* Add individual height and width styles as needed */
  ${(props) =>
    props.imageType === "first" &&
    css`
      height: 50px;
      width: 40px;
    `}

  ${(props) =>
    props.imageType === "second" &&
    css`
      height: 50px;
      width: 52px;
    `}

  ${(props) =>
    props.imageType === "third" &&
    css`
      height: 50px;
      width: 44px;
    `}

${(props) =>
    props.imageType === "fourth" &&
    css`
      height: 67.102px;
      width: 64.969px;
    `}

${(props) =>
    props.imageType === "fifth" &&
    css`
      height: 60.395px;
      width: 57.027px;
    `} /* Add styles for other image types as needed */
`;

const FifthSectionText = styled.p`
  color: var(--Gray-900, #2a3342);
  font-family: Soleil;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.6px;
`;

function Home() {
  const beforeFooterRef = useRef();

  useEffect(() => {
    // Check if there's a hash in the URL and scroll to the corresponding element
    const hash = window.location.hash;
    if (hash === "#before-footer" && beforeFooterRef.current) {
      beforeFooterRef.current.scrollIntoView({
        block: "start",
        inline: "nearest",
      });
    }
  }, []);

  return (
    <div>
      <PageTitle title={"Intentbuy"} />
      <MobileOnly>
        <MobileNavbar />
      </MobileOnly>
      <DesktopOnly>
        <NavBar
          transparent={false}
          whiteText={false}
          customBackgroundColor="#1E76D2"
          customTextColor="#000"
        />
      </DesktopOnly>
      {/* <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="banner-text">
              <h1>Boost Your Sales with</h1>
              <h2>Smarter Insights</h2>
              <p>Turn Anonymous Site Visits into Real Business Opportunities</p>
              <button className="btn btn-primary">Get Started Today</button>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner-image">
              <img
                src="/assets/images/home-page/home-first.webp"
                alt="Description of the"
                className="img-fluid" // Make the image responsive
              />
            </div>
          </div>
        </div>
      </div> */}
      <div>
        {/* Desktop view */}
        <DesktopOnly>
          <iframe
            title="Juxtapose Comparison"
            frameBorder="0"
            className="juxtapose"
            width="100%"
            height="700px"
            src="https://cdn.knightlab.com/libs/juxtapose/latest/embed/index.html?uid=a13bcee8-b6f6-11ee-9ddd-3f41531135b6"
          ></iframe>
        </DesktopOnly>

        {/* Mobile view */}
        <MobileOnly>
          <MobileView />
        </MobileOnly>
      </div>

      {/* Second section with Bootstrap grid layout */}
      <CustomContainer className="container">
        {/* 1st row */}
        <DesktopOnly>
          <HeadingRow className="row">
            <LeftSection className="col-12 text-center">
              <div className="left-section">
                <h3 className="mb-1 heading-container">
                  <span className="intentbuy-text">Introducing</span>
                  <span className="analytics-text">Intentbuy</span>
                </h3>
              </div>
            </LeftSection>
          </HeadingRow>

          {/* 2nd row */}
          <ContentRow className="row">
            <LeftSection className="col-12 text-center text-md-left">
              <div className="left-section">
                <p className="text-sm">Making B2B Selling Easier</p>
              </div>
            </LeftSection>
          </ContentRow>

          {/* 3rd row */}
          <ContentRow className="row">
            <RightSection className="col-12 text-md-left">
              <div className="right-section">
                <p className="text-md">
                  Our platform is engineered to empower B2B teams with
                  actionable insights, <br />
                  transforming your website's anonymous traffic into a goldmine
                  of qualified leads.
                </p>
              </div>
            </RightSection>
          </ContentRow>
        </DesktopOnly>
        <MobileOnly>
          <MobileViewSecond />
        </MobileOnly>
      </CustomContainer>

      {/* Third section with three columns */}
      <DesktopOnly>
        <DesktopThirdSection />
      </DesktopOnly>
      <MobileOnly>
        <MobileThirdSection />
      </MobileOnly>

      {/* Fourth section with two columns */}
      <DesktopOnly>
        <div
          className="container fourth-section"
          style={{ marginTop: "180px" }}
        >
          <div className="row">
            {/* Left column for content */}
            <div className="col-lg-6">
              <div className="fourth-section-content">
                {/* Badge */}
                <div className="badge-container">
                  <BadgeText>
                    <span className="badge-text">HOW IT WORKS</span>
                  </BadgeText>
                </div>

                {/* Text */}
                <p className="text-content">
                  Gain more insight in just five simple steps
                </p>

                {/* Button */}
                <Link to="/Pricing" className="button-container">
                  <span className="button-text">Start your free trial</span>
                </Link>
              </div>
            </div>

            {/* Right column for video and background image */}
            <div className="col-lg-6">
              <div className="fourth-section-image position-relative">
                {/* Background Image */}
                <div
                  className="background-image"
                  style={{
                    backgroundImage:
                      "url('/assets/images/how-it-works-background.webp')", // Adjust the path as needed
                    zIndex: -24, // Set a lower z-index for the background image
                    marginTop:"-128px"
                  }}
                ></div>

                {/* Video Container */}
                <div className="video-container1">
                  {/* Replace the video URL with your actual video URL */}
                  <iframe
                    className="video"
                    title="Video"
                    width="352px"
                    height="200px"
                    src="https://www.youtube.com/embed/9WkT4yE-qh0?si=Xg3OdHFpKwGLVzKH&rel=0"
                    allowFullScreen
                    style={{ border: "none !important", borderRadius: "14px" }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DesktopOnly>

      {/* Fifth section */}
      <DesktopOnly>
        <div className="container mt-5 fifth-section">
          {/* First row with three columns */}
          <div className="row">
            {/* First column */}
            <div className="col-md-4 col-sm-12">
              <FifthSectionItem>
                <FifthSectionImage
                  src="/assets/images/home-page/first.webp"
                  alt="first.webp"
                  className="fifth-section-image"
                  imageType="first"
                />
                <FifthSectionText>
                  Generate your unique tracking pixel <br />
                  per domain you want to track
                </FifthSectionText>
              </FifthSectionItem>
            </div>

            {/* Second column */}
            <div className="col-md-4 col-sm-12">
              <FifthSectionItem>
                <FifthSectionImage
                  src="/assets/images/home-page/second.webp"
                  alt="second.webp"
                  className="fifth-section-image"
                  imageType="second"
                />
                <FifthSectionText>
                  Define your ideal customer profile <br /> (ICP) and Segment
                  the visitors.
                </FifthSectionText>
              </FifthSectionItem>
            </div>

            {/* Third column */}
            <div className="col-md-4 col-sm-12">
              <FifthSectionItem>
                <FifthSectionImage
                  src="/assets/images/home-page/third.webp"
                  alt="third.webp"
                  className="fifth-section-image"
                  imageType="third"
                />
                <FifthSectionText>Setup Lead Scoring</FifthSectionText>
              </FifthSectionItem>
            </div>
          </div>

          {/* Second row with two columns */}
          <div className="row">
            {/* Fourth column */}
            <div className="col-md-4 col-sm-12">
              <FifthSectionItem>
                <FifthSectionImage
                  src="/assets/images/home-page/fourth.webp"
                  alt="fourth.webp"
                  className="fifth-section-image"
                  imageType="fourth"
                />
                <FifthSectionText>Connect Google Analytics</FifthSectionText>
              </FifthSectionItem>
            </div>

            {/* Fifth column */}
            <div className="col-md-4 col-sm-12">
              <FifthSectionItem>
                <FifthSectionImage
                  src="/assets/images/home-page/fifth.webp"
                  alt="fifth.webp"
                  className="fifth-section-image"
                  imageType="fifth"
                />
                <FifthSectionText>
                  Integrate with your Sales Stack
                </FifthSectionText>
              </FifthSectionItem>
            </div>
          </div>
        </div>
      </DesktopOnly>

      {/* Fourth section with two columns for mobile view */}
      <MobileOnly>
        <div className="mobile">
          {/* 1st Section */}
          <div className="first-section">
            <div className="badge">
              <BadgeText>
                <span className="badge-text">HOW IT WORKS</span>
              </BadgeText>
            </div>
            <h2 className="section-heading">
              Gain more insight in just <br /> five simple steps
            </h2>
            <Link to="/Pricing" className="start-trial-btn">
              Start your free trial
            </Link>
          </div>

          {/* 2nd Section */}
          <div className="second-section">
            {/* Video */}
            <div className="video-container-mobile">
              <iframe
                className="video"
                title="Video"
                width="80%"
                height="100%"
                src="https://www.youtube.com/embed/9WkT4yE-qh0?si=Xg3OdHFpKwGLVzKH&rel=0"
                allowFullScreen
                style={{ borderRadius: "14px" }}
              ></iframe>
            </div>

            {/* Rows with Image and Content */}
            {/* first Rows with Image and Content */}
            <div className="row">
              <img
                src="assets/images/home-page/first.webp"
                alt="fifth-1"
                className="row-image-1"
              />
              <div className="row-content-1">
                <p>
                  Generate your unique tracking pixel per domain you want to
                  track
                </p>
              </div>
            </div>

            {/* second Rows with Image and Content */}
            <div className="row-2">
              <img
                src="assets/images/home-page/second.webp"
                alt="fifth-1"
                className="row-image-2"
              />
              <div className="row-content">
                <p>
                  Define your ideal customer profile (ICP) and Segment the
                  visitors.
                </p>
              </div>
            </div>

            {/* third Rows with Image and Content */}
            <div className="row-3">
              <img
                src="assets/images/home-page/third.webp"
                alt="fifth-1"
                className="row-image-3"
              />
              <div className="row-content-3">
                <p>Setup Lead Scoring</p>
              </div>
            </div>

            {/* forth Rows with Image and Content */}
            <div className="row-4">
              <img
                src="assets/images/home-page/fourth.webp"
                alt="fifth-1"
                className="row-image-4"
              />
              <div className="row-content-4">
                <p>Connect Google Analytics</p>
              </div>
            </div>

            {/* fifth Rows with Image and Content */}
            <div className="row-5">
              <img
                src="assets/images/home-page/fifth.webp"
                alt="fifth-1"
                className="row-image-5"
              />
              <div className="row-content-5">
                <p>Integrate with your Sales Stack</p>
              </div>
            </div>

            {/* Repeat the above row structure for the remaining 4 rows */}
          </div>
        </div>
      </MobileOnly>

      {/* Sixth section */}
      <TestimonialCarousel />
      <TestimonialSlider />

      {/* Seventh section */}
      <BeforeFooter ref={beforeFooterRef} />
      <DesktopOnly>
        <ResponsiveFooter customBackgroundColor={true} />
      </DesktopOnly>
      <MobileOnly>
        <MobileFooter />
      </MobileOnly>
    </div>
  );
}

export default Home;
