import React, { useState, useRef, useEffect } from "react";

const YourComponent = () => {
  const [selectedButton, setSelectedButton] = useState(1);
  const videoContainerRef = useRef(null);

  const handleButtonClick = (buttonNumber) => {
    setSelectedButton(buttonNumber);

    // Disable scrolling only for the 3rd and 4th buttons
    if (buttonNumber === 3 || buttonNumber === 4) {
      videoContainerRef.current.style.overflowX = "hidden";
    } else {
      videoContainerRef.current.style.overflowX = "auto";
    }
  };

  const getVideoPath = (buttonNumber) => {
    switch (buttonNumber) {
      case 1:
        return "https://intentbuy.io/Lead-Scoring/Segment-your-Visitors.mp4";
      case 2:
        return "https://intentbuy.io/Lead-Scoring/Score-Your-Leads.mp4";
      case 3:
        return "https://intentbuy.io/Lead-Scoring/Connect-Google-Analytics.mp4";
      case 4:
        return "https://intentbuy.io/Lead-Scoring/Integrate-your-tools.mp4";
      default:
        return null;
    }
  };

  const getContent = (buttonNumber) => {
    switch (buttonNumber) {
      case 1:
        return {
          heading: (
            <>
              Define your ideal customer
              <br />
              profile (ICP) & segment the
              <br /> visitors
            </>
          ),
          additionalContent: (
            <>
              Automatically create groups of good-fit leads matching your ideal
              <br />
              customer profile. Or combine them with high-intent behavior.
              <br />
              <br />
              This will help you segment and score the visitors and focus only
              on
              <br />
              the ones that qualify. Our industry filters match LinkedIn so if
              you are unsure what industries to select you can check out the
              industries your customers are listed under on LinkedIn. It's
              important to cover include related industries too just like the
              screenshot above.
            </>
          ),
        };

      case 2:
        return {
          heading: "Score Your Leads",
          additionalContent:
            "Combine company attributes such as industry, size and location with behaviour like time on site or pricing page visits. We will then automatically capture, save and send you ideal leads",
        };
      case 3:
        return {
          heading: "Connect your Google Analytics",
          additionalContent:
            "Elevate your Google Analytics to a B2B powerhouse in three clicks. Enrich Anonymous GA traffic with detailed company data for optimised marketing: convert quality leads, craft ideal retargeting, and create lookalike audiences. Exclude unfit visitors, reducing acquisition costs without upping your ad budget.",
        };
      case 4:
        return {
          heading: "Integrate your tools",
          additionalContent:
            "Integrate our intent-driven sales intelligence platform with your tools to effortlessly connect with your target audience. Share information with your sales team, leveraging high-intent accounts and our extensive dataset of over 540 million contacts. Instantly locate individuals at any company with our accurate, fast, double-verified AI-driven process.",
        };
      default:
        return null;
    }
  };

  useEffect(() => {
    if (videoContainerRef.current && selectedButton) {
      const buttonWidth = 62.296;
      const containerWidth = videoContainerRef.current.clientWidth;
      const scrollOffset =
        (selectedButton - 1) * buttonWidth -
        containerWidth / 2 +
        buttonWidth / 2;
      videoContainerRef.current.scrollTo({
        left: scrollOffset,
        behavior: "smooth",
      });
    }
  }, [selectedButton]);

  useEffect(() => {
    // Additional logic to perform when the component mounts or when selectedButton changes
    // You can fetch additional data or perform any other actions here
  }, [selectedButton]);

  const videoContainerStyle = {
    width: "100%",
    overflowX: "auto",
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: "24px",
    transition: "margin-left 0.5s ease-in-out",
  };

  return (
    <div className="d-none d-md-block">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "780px",
          marginTop: "80px",
        }}
      >
        {/* First Row */}
        <div
          style={{
            display: "flex",
            background: "#FFF",
            alignItems: "center",
            marginTop: "50px",
            height: "100px",
          }}
        >
          {/* Left Side */}
          <div style={{ width: "664px", flexShrink: 0, paddingRight: "20px" }}>
            {selectedButton && (
              <>
                <h1
                  style={{
                    marginBottom: "10px",
                    color: "var(--Gray-900, #2A3342)",
                    fontFamily: "Soleil",
                    fontSize: "40px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "51px",
                    letterSpacing: "-0.8px",
                  }}
                >
                  {getContent(selectedButton).heading}
                </h1>
              </>
            )}
          </div>
          {/* Right Side */}
          <div
            style={{
              width: "556.974px",
              color: "var(--Gray-500, #556987)",
              fontFamily: "Soleil",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "30px",
            }}
          >
            {selectedButton && (
              <>
                <h1
                  style={{
                    marginBottom: "10px",
                    color: "var(--Gray-500, #556987)",
                    fontFamily: "Soleil",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "30px",
                  }}
                >
                  {getContent(selectedButton).additionalContent}
                </h1>
                {/* Your additional content and styles for the right side */}
              </>
            )}
          </div>
        </div>

        {/* Second Row */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "auto",
            marginTop: "110px",
          }}
        >
          {[1, 2, 3, 4].map((buttonNumber, index) => (
            <React.Fragment key={buttonNumber}>
              {index > 0 && (
                <div
                  key={`line-${index}`}
                  style={{
                    width: "150px",
                    height: "2px",
                    backgroundColor: "#E6E6E6",
                    marginRight: "10px",
                  }}
                />
              )}
              <button
                style={{
                  width: "62.296px",
                  height: "62.296px",
                  flexShrink: 0,
                  background:
                    selectedButton === buttonNumber
                      ? "linear-gradient(91deg, #1F86E3 14.24%, #1B3B97 100%)"
                      : "#D9D9D9", // Set the desired initial state color here
                  color: selectedButton === buttonNumber ? "#fff" : "#000",
                  marginRight: "10px",
                  borderRadius: "50%",
                  border:
                    selectedButton === buttonNumber
                      ? "5px solid #FFF"
                      : "none",
                      outline:
                      selectedButton === buttonNumber
                      ? "solid var(--Gradient, #1f86e3) 1.162px"
                      : "none",
                }}
                onClick={() => handleButtonClick(buttonNumber)}
              >
                {buttonNumber.toString().padStart(2, "0")}
              </button>
            </React.Fragment>
          ))}
        </div>

        {/* Horizontal Line */}
        <div
          style={{
            width: "1033.003px",
            height: "0px",
            flexShrink: 0,
            strokeWidth: "2px",
            stroke: "#E6E6E6",
            margin: "20px 0",
          }}
        />

        {/* Third Row (Video) */}
        <div ref={videoContainerRef} style={videoContainerStyle}>
          {[1, 2, 3, 4].map((buttonNumber) => (
            <div
              key={buttonNumber}
              style={{
                width: "454px",
                height: "250px",
                marginRight: "20px",
                marginLeft: "20px",
                borderRadius: "24px",
                overflow: "hidden",

                border:
                  selectedButton === buttonNumber
                    ? "5px solid var(--Gradient, #1F86E3)"
                    : "none",
                boxShadow:
                  selectedButton === buttonNumber
                    ? "9px 13px 37.9px 0px rgba(0, 0, 0, 0.12)"
                    : "none",
              }}
            >
              <iframe
                title={`Video ${buttonNumber}`}
                width="100%"
                height="100%"
                src={getVideoPath(buttonNumber)}
                frameBorder="0"
                allowFullScreen
                autoplay="0" // Add this line to disable autoplay
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YourComponent;
