// MobileBannerSeamlessIntegration.js
import React from "react";
import styled from "styled-components";
import ContinuousRotationCarousel from "./MobileImageMarqueeExample";
import ContinuousRotationCarouselReverse from "./ContinuousRotationCarouselReverse";
import MobileNavbar from "./MobileNavbar";

const BannerContainer = styled.div`
  position: relative;
  height: 610px; /* Adjust the height for mobile */
  overflow: hidden; /* Add this line */
`;

const BannerContent = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  background: rgba(16, 28, 45, 0.8); /* Adjust opacity as needed */
`;

const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-family: Soleil;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 0px 5px; /* Add padding left and right */
  padding-top: 60px;
`;

const SeamlessIntegrationTitle = styled.h1`
  color: #fff;
  text-align: center;
  font-family: Soleil;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 55.586px;
  letter-spacing: -2.08px;
  padding: 5px 5px; /* Add padding left and right */
`;

const Description = styled.p`
  color: #bbb;
  text-align: center;
  font-family: Soleil;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  padding: 5px 10px; /* Add padding left and right */
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

const handleRequestDemo = () => {

  window.location.href = "/#before-footer";
};

const MobileBannerSeamlessIntegration = ({ transparent, isLightBackground }) => {
  // Images for the continuous scrolling marquee
  const images = [
    "/assets/images/Seamless Integration/logo1.webp",
    "/assets/images/Seamless Integration/logo2.webp",
    "/assets/images/Seamless Integration/logo3.webp",
    "/assets/images/Seamless Integration/logo4.webp",
  ];

  return (
    <>
      <BannerContainer>
        <div
          style={{
            backgroundImage: "url(/assets/images/seamless-integration-bg.webp)",
            backgroundSize: "cover",
            backgroundPosition: "center", // Center the background image
            position: "absolute",
            width: "100%",
            height: "650px",
            backgroundRepeat: "no-repeat",
          }}
        />
        <Overlay /> {/* Add the overlay */}
        <MobileNavbar whiteText={true} />
        <BannerContent>
          <Title>Enhance Your Workflow with our</Title>
          <SeamlessIntegrationTitle>
            Seamless Integration
          </SeamlessIntegrationTitle>
          <Description>
            Effortlessly incorporate Intentbuy into your current tech stack to
            elevate your marketing campaigns and supercharge your sales
            pipeline.
          </Description>

          <ButtonContainer>
          <button
            style={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "Soleil",
              fontSize: "13px", // Adjust the font size for mobile
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "13px", // Adjust the line height for mobile
              border: "4px solid #192F48",
              borderRadius: "49px", // Adjust the border radius for mobile
              background:
                "var(--Gradient, linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%))",
              outline: "solid var(--Gradient, #1f86e3) 1.162px",
              padding: "16.267px 49.048px",
              cursor: "pointer",
              transition: "background 0.3s ease, box-shadow 0.3s ease",
              position: "relative",
              paddingBottom: "18px",
            }}
            onClick={handleRequestDemo}>
            Request a Demo
            <span
              style={{
                content: '"›"',
                width: "5.81px",
                height: "9.295px",
                flexShrink: 0,
                color: "#ffffff",
                marginLeft: "5px",
                zIndex: 2,
                position: "absolute",
                top: "44%",
                transform: "translateY(-50%)",
              }}
            >
              ›
            </span>
          </button>
          </ButtonContainer>
          <ContinuousRotationCarousel images={images} />
          <ContinuousRotationCarouselReverse images={images} />
        </BannerContent>
      </BannerContainer>
    </>
  );
};

export default MobileBannerSeamlessIntegration;
