// MobileSectionComponent.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components"; // Import styled-components if needed

const MobileSectionComponent = () => {
  return (
    <MobileSectionContainer className="text-center">
      <Container style={{ paddingRight: 0 }}> 
        {/* First Row */}
        <Row>
          <Col xs={12}>
            <Heading>Unified Integration Hub</Heading>
            <Description>
              With compatibility with over 5,000+ apps, integrating your team's
              favorite tools is just a click away, ensuring a smooth and efficient
              workflow.
            </Description>
          </Col>
        </Row>

        {/* Second Row */}
        <Row className="justify-content-center">
          <Col xs={12} sm={4}>
            <ListBox>
              <Image src="/assets/images/seamless-integration-2.1.webp" alt="Image 1" />
              <Content>Enhanced Marketing Campaigns</Content>
            </ListBox>
          </Col>
          <Col xs={12} sm={4}>
            <ListBox>
              <Image src="/assets/images/seamless-integration-2.1.webp" alt="Image 2" />
              <Content>Boosted Sales Pipeline</Content>
            </ListBox>
          </Col>
          <Col xs={12} sm={4}>
            <ListBox>
              <Image src="/assets/images/seamless-integration-2.1.webp" alt="Image 3" />
              <Content>Time-Saving Workflow</Content>
            </ListBox>
          </Col>
        </Row>
      </Container>
    </MobileSectionContainer>
  );
};

const MobileSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  mx-auto; /* Center horizontally */
`;

const Heading = styled.h1`
  color: #fff;
  text-align: left;
  font-family: Soleil;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
`;

const Description = styled.p`
  color: #bbb;
  text-align: left;
  font-family: Soleil;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  margin-right: 0; /* Override margin-right */
  margin-top: 20px;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px; /* Adjust margin as needed */
`;

const Image = styled.img`
width: 25.73px;
height: 25.73px;
  margin-right: 10px; /* Adjust margin as needed */
`;

const Content = styled.p`
color: #FFF;
  font-family: Soleil;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24.84px;
  margin: 0;
`;

export default MobileSectionComponent;
