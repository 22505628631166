import React from "react";

const RealTimeFifthMobileSection = () => {
  return (
    <div className="FifthSection row mt-5 mx-1">
      {/* First Row */}
      <div
        className="col-12 text-center"
        style={{
          backgroundImage: "url('/assets/images/real-time/real-time-5.webp')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="FeatureBadgenew"
        >
          {/* Replace "FEATURES" text with an image */}
          <img
            src="assets/images/real-time/Badge.webp"
            alt="Features"
            style={{
              width: "81.86px", // Adjust the width as needed
              height: "24.56px", // Adjust the height as needed
              borderRadius: "40.183px",
              objectFit: "cover",
            }}
          />
        </div>
      </div>

      {/* Second Row */}
      <div className="col-12 text-center mt-3">
      <h2
          className="VisitorTrackingHeading pb-3"
          style={{
            color: "var(--Gray-900, #2A3342)",
            textAlign: "center",
            fontFamily: "Soleil",
            fontSize: "23px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "33px",
            letterSpacing: "-0.46px",
          }}
        >
          {window.innerWidth > 767 ? (
            <>
              Visitor Tracking (Ip Append)
              <br /> Packed With Features To Make
              <br /> Lead Generation Possible
            </>
          ) : (
            <span>
              Visitor Tracking (Ip Append)
              <br /> Packed With Features To Make
              <br /> Lead Generation Possible
            </span>
          )}
        </h2>
      </div>

      {/* Third and Fourth Rows */}
      <div className="row mb-4 mt-3">
        {/* Feature Columns for Mobile */}
        <div className="col-12">
          <div className="FeatureColumn">
            <img
              src="/assets/images/real-time/real-time-section-51.webp"
              alt="Contact Insights"
              className="img-fluid"
              style={{
                width: "64px",
                height: "64px",
                flexShrink: 0,
                borderRadius: "6px",
                background: "conic-gradient(from 100deg at 50% 50%, #1F86E3 51.249960064888deg, #1B3B97 360deg)",
              }}
            />
            <h3
              className="FeatureHeadingNew"
              style={{
                color: "var(--Gray-900, #2A3342)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                padding: "25px 0",
              }}
            >
              Contact Insights
            </h3>
            <p
              className="FeatureContentNew"
              style={{
                color: "var(--Gray-500, #556987)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                marginTop: "-10px",
                marginBottom: "60px",
              }}
            >
              We offer access to a robust database, including email addresses, phone numbers, allowing your sales team to immediately engage the prospect.
            </p>
          </div>
        </div>

        <div className="col-12">
          <div className="FeatureColumn">
            <img
              src="/assets/images/real-time/real-time-section-52.webp"
              alt="Company Data"
              className="img-fluid mb-2"
              style={{
                width: "64px",
                height: "64px",
                flexShrink: 0,
                borderRadius: "6px",
                background: "conic-gradient(from 100deg at 50% 50%, #1F86E3 51.249960064888deg, #1B3B97 360deg)",
              }}
            />
            <h3
              className="FeatureHeadingNew"
              style={{
                color: "var(--Gray-900, #2A3342)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                padding: "15px 0",
              }}
            >Company Data</h3>
            <p
              className="FeatureContentNew"
              style={{
                color: "var(--Gray-500, #556987)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                marginTop: "-10px",
                marginBottom: "60px",
              }}
            
            >
              We offer access to a robust database of company information, as
              well as quick links for social and news research.
            </p>
          </div>
        </div>

        <div className="col-12">
          <div className="FeatureColumn">
            <img
              src="/assets/images/real-time/real-time-section-53.webp"
              alt="Real-Time Alerts"
              className="img-fluid mb-2"
              style={{
                width: "64px",
                height: "64px",
                flexShrink: 0,
                borderRadius: "6px",
                background: "conic-gradient(from 100deg at 50% 50%, #1F86E3 51.249960064888deg, #1B3B97 360deg)",
              }}
            />
            <h3
              className="FeatureHeadingNew"
              style={{
                color: "var(--Gray-900, #2A3342)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                padding: "15px 0",
              }}
            >Real-Time Alerts</h3>
            <p
              className="FeatureContentNew"
              style={{
                color: "var(--Gray-500, #556987)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                marginTop: "-10px",
                marginBottom: "60px",
              }}
            >
              Never miss a prospect with our real-
              time notification system. Collecting and emailing you critical
              information about your visitors.
            </p>
          </div>
        </div>

        <div className="col-12">
          <div className="FeatureColumn">
            <img
              src="/assets/images/real-time/real-time-section-54.webp"
              alt="Lead Handling"
              className="img-fluid mb-2"
              style={{
                width: "64px",
                height: "64px",
                flexShrink: 0,
                borderRadius: "6px",
                background: "conic-gradient(from 100deg at 50% 50%, #1F86E3 51.249960064888deg, #1B3B97 360deg)",
              }}
            />
            <h3
              className="FeatureHeadingNew"
              style={{
                color: "var(--Gray-900, #2A3342)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                padding: "15px 0",
              }}
            >Lead Handling</h3>
            <p
              className="FeatureContentNew"
              style={{
                color: "var(--Gray-500, #556987)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                marginTop: "-10px",
                marginBottom: "60px",
              }}
            >
              Per-user lead handling allows for the right leads to reach the
              right salespeople at the right time. Start handling your leads
              smarter!
            </p>
          </div>
        </div>

        <div className="col-12">
          <div className="FeatureColumn">
            <img
              src="/assets/images/real-time/real-time-section-55.webp"
              alt="Integrated Analytics"
              className="img-fluid mb-2"
              style={{
                width: "64px",
                height: "64px",
                flexShrink: 0,
                borderRadius: "6px",
                background: "conic-gradient(from 100deg at 50% 50%, #1F86E3 51.249960064888deg, #1B3B97 360deg)",
              }}
            />
            <h3
              className="FeatureHeadingNew"
              style={{
                color: "var(--Gray-900, #2A3342)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                padding: "15px 0",
              }}
            >Integrated Analytics</h3>
            <p
              className="FeatureContentNew"
              style={{
                color: "var(--Gray-500, #556987)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                marginTop: "-10px",
                marginBottom: "60px",
              }}
            >
              Website visitor analytics can help you increase sales through the
              identification of leads, while also realizing what pages are most
              interesting.
            </p>
          </div>
        </div>

        <div className="col-12">
          <div className="FeatureColumn">
            <img
              src="/assets/images/real-time/real-time-section-56.webp"
              alt="Integrations"
              className="img-fluid mb-2"
              style={{
                width: "64px",
                height: "64px",
                flexShrink: 0,
                borderRadius: "6px",
                background: "conic-gradient(from 100deg at 50% 50%, #1F86E3 51.249960064888deg, #1B3B97 360deg)",
              }}
            />
            <h3
              className="FeatureHeadingNew"
              style={{
                color: "var(--Gray-900, #2A3342)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                padding: "15px 0",
              }}
            >Integrations</h3>
            <p
              className="FeatureContentNew"
              style={{
                color: "var(--Gray-500, #556987)",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "24px",
                marginTop: "-10px",
              }}
            >
              Send your leads to more than 1,000 unique applications with our
              Zapier and API Integrations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealTimeFifthMobileSection;
