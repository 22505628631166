import React, { useState, useEffect } from "react";

const LeadManagementSection = () => {
  // eslint-disable-next-line no-unused-vars
  const [activeRow, setActiveRow] = useState(0);

  // Data for each row
  const rowData = [
    {
      leftColumn: {
        image: "assets/images/Lead-Scoring/lead-scoring-second-2.1.webp",
        heading: "Efficient Lead Handling",
        content: (
          <>
            Quickly accept or reject leads based on your
            <br /> specific criteria
          </>
        ),
      },
      rightColumn: {
        image: "assets/images/Lead-Scoring/lead-scoring-1.webp",
      },
    },
    {
      leftColumn: {
        image: "assets/images/Lead-Scoring/lead-scoring-second-2.2.webp",
        heading: "CRM Integration",
        content: (
          <>
            Seamlessly integrate leads into your CRM,
            <br />
            keeping your data organized and up-to-date.
          </>
        ),
      },
      rightColumn: {
        image: "assets/images/Lead-Scoring/lead-scoring-2.webp",
      },
    },
    {
      leftColumn: {
        image: "assets/images/Lead-Scoring/lead-scoring-second-2.3.webp",
        heading: "Team Collaboration and Automation",
        content: (
          <>
            Assign leads to team members and trigger automated
            <br />
            workflows to keep your pipeline flowing smoothly.
          </>
        ),
      },
      rightColumn: {
        image: "assets/images/Lead-Scoring/lead-scoring-3.webp",
      },
    },
  ];

  const mobileContainerStyle = {
    paddingTop: "30px",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "self-end", // Vertically align the content
    maxWidth: "960px",
    margin: "0 auto",
    height: "750px",
    paddingBottom: "130px",
    marginTop: "150px",
  };

  const leftColumnStyle = {
    flexShrink: 0,
    width: "70%",
    paddingRight: "20px",
  };

  const rowStyle = {
    color: "#BCC1CA",
    fontFamily: "Soleil",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.72px",
    cursor: "pointer",
    marginBottom: "30px",
    marginTop: "30px",
    transition: "color 0.3s ease-in-out",
    display: "flex",
    alignItems: "flex-start", // Align items to the top
  };

  const activeRowStyle = {
    color: "#2A3342",
  };

  const rightColumnStyle = {
    flexShrink: 0,
    width: "465.084px",
    height: "526.559px",
    alignSelf: "flex-end",
  };

  const contentContainerStyle = {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
  };

  const contentStyle = {
    color: "#BCC1CA",
    fontFamily: "Soleil",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  };

  const activeContentStyle = {
    color: "#626981",
  };

  const imageStyle = {
    width: "56px",
    height: "56px",
    flexShrink: 0,
    marginRight: "10px",
    marginTop: "5px", // Adjust the margin to move the image down
  };

  const mobileContentStyle = {
    color: "#626981",
    fontFamily: "Soleil",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18.689px",
    whiteSpace: "nowrap",
  };

  const mobileRowStyle = {
    color: "#2A3342",
    fontFamily: "Soleil",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24.919px",
    cursor: "pointer",
    letterSpacing: "-0.561px",
    transition: "color 0.3s ease-in-out",
    display: "flex",
    alignItems: "flex-start",
    width: "280.343px",
  };

const inactivemobileRowStyle = {
  color: "#BCC1CA",
  fontFamily: "Soleil",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "24.919px",
  cursor: "pointer",
  letterSpacing: "-0.561px",
  transition: "color 0.3s ease-in-out",
  display: "flex",
  alignItems: "flex-start",
  width: "280.343px",
}
  const inactiveMobileContentStyle = {
    color: "#BCC1CA", // Adjust the color to your preference
  };

  const handleRowHover = (index) => {
    setActiveRow(index);
  };

  const handleRowLeave = () => {
    setActiveRow(0); // Reset to the first row when leaving any row
  };

  useEffect(() => {
    // Set initial active state to the first row
    setActiveRow(0);
  }, []);

  return (
    <>
      {/* Desktop Version */}
      <div className="d-none d-md-block">
        <div style={containerStyle}>
          {/* Left Column */}
          <div style={leftColumnStyle}>
            <div
              style={{
                color: "#2A3342",
                fontFamily: "Soleil",
                fontSize: "40px",
                fontWeight: 700,
                lineHeight: "53.5px",
                letterSpacing: "-0.8px",
                marginBottom: "90px",
              }}
            >
              Streamline Your Lead
              <br /> Management with Intentbuy
            </div>

            {rowData.map((data, index) => (
              <div key={index}>
                {/* Add horizontal line after the 1st and 2nd rows */}
                {index > 0 && (
                  <hr
                    style={{
                      width: "530.592px",
                      height: "0px",
                      flexShrink: 0,
                      strokeWidth: "1px",
                      stroke: "#D2D2D2",
                    }}
                  />
                )}

                <div
                  style={{
                    ...rowStyle,
                    ...(activeRow === index ? activeRowStyle : {}),
                  }}
                  onMouseEnter={() => handleRowHover(index)}
                  onMouseLeave={handleRowLeave}
                >
                  <div style={imageStyle}>
                    <img
                      src={data.leftColumn.image}
                      alt={`${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  <div style={contentContainerStyle}>
                    <div>{data.leftColumn.heading}</div>
                    <div
                      style={{
                        ...contentStyle,
                        ...(activeRow === index ? activeContentStyle : {}),
                        marginTop: "5px",
                      }}
                    >
                      {data.leftColumn.content}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Right Column */}
          <div style={rightColumnStyle}>
            <img
              src={rowData[activeRow]?.rightColumn.image}
              alt={`${activeRow + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>

      {/* Mobile Version */}
      <div className="d-md-none" style={mobileContainerStyle}>
        <div className="container d-flex flex-column mx-auto">
          {/* Left Column */}
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  color: "#2A3342",
                  fontFamily: "Soleil",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "35px",
                  letterSpacing: "-0.52px",
                  marginBottom: "40px",
                  textAlign: "left",
                }}
              >
                Streamline Your Lead Management with Intentbuy
              </div>

              <img
                src={rowData[activeRow]?.rightColumn.image}
                alt={`${activeRow + 1}`}
                style={{
                  width: "88vw",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>

          {/* Right Column */}
          <div className="row pt-5 pb-5">
            <div className="col-12">
              {rowData.map((data, index) => (
                <div key={index}>
                  {index > 0 && (
                    <hr
                      style={{
                        height: "0px",
                        flexShrink: 0,
                        strokeWidth: "1px",
                        stroke: "#D2D2D2",
                        marginBottom: "20px", // Increased margin
                      }}
                    />
                  )}

                  <div
                    style={{
                      ...mobileRowStyle,
                      ...(activeRow === index ? activeRowStyle : {}),
                    }}
                    onMouseEnter={() => handleRowHover(index)}
                    onMouseLeave={handleRowLeave}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <img
                        src={data.leftColumn.image}
                        alt={`${index + 1}`}
                        style={{
                          width: "43.609px",
                          height: "43.609px", // Make the height auto to maintain aspect ratio
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    </div>

                    <div style={contentContainerStyle}>
                      <div style={{
                          ...mobileRowStyle,
                          ...(activeRow === index
                            ? activeContentStyle
                            : inactivemobileRowStyle),
                          marginTop: "5px",
                        }}
                      >{data.leftColumn.heading}</div>
                      <div
                        style={{
                          ...mobileContentStyle,
                          ...(activeRow === index
                            ? activeContentStyle
                            : inactiveMobileContentStyle),
                          marginTop: "5px",
                        }}
                      >
                        {data.leftColumn.content}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadManagementSection;
