// MobileThirdSection.js
import React from "react";
import styled from "styled-components";
import MobileCarouselSection from "./home-MinithirdSection";

const VideoSectionContainer = styled.div`
  margin-top: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 24px !important;
  display: flex;
  justify-content: center; /* Center horizontally */
`;

const ThirdSectionContainer = styled.div`
  background: url("assets/images/home-page/third-section.webp") center/cover;
  padding-top: 100px;
  padding-bottom: 16px;
  margin-top: -100px; /* Adjust margin-top for mobile */
`;

const FeatureColumn = styled.div`
  text-align: center;
  margin-top: 55px;
  padding-left: 30px;
`;

const FeatureBadge = styled.div`
width: 100px;
height: 25px;
border-radius: 28px;
background: url('assets/images/home-page/Features.png') center/cover;
background-size: 60.264px 10px; /* Set the width and height */
background-repeat: no-repeat;
box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.2) inset;
flex-shrink: 0;

`;

const FeatureText = styled.span`
  color: #fff;
  text-align: center;
  font-family: Soleil;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
`;

const Heading = styled.h2`
  color: #fff;
  text-align: center;
  font-family: Soleil;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.688px;
  letter-spacing: -1.12px;
`;


const MobileThirdSection = () => {
  return (
    <>
      <VideoSectionContainer className="container">
        <iframe
          title="Embedded YouTube Video"
          width="352px"
         
          height="200px"
          src="https://www.youtube.com/embed/9WkT4yE-qh0?si=Xg3OdHFpKwGLVzKH&rel=0"
          frameBorder="0"
          allowFullScreen
          style={{ borderRadius: "7px" }}
        ></iframe>
      </VideoSectionContainer>

      

      <ThirdSectionContainer>
        <div className="container mt-5">
          <div className="row justify-content-center align-items-center">

            {/* Second column */}
            <div className="col-md-12">
              <FeatureColumn>
                <FeatureBadge className="badge  p-2">
                  <FeatureText></FeatureText>
                </FeatureBadge>
                <Heading>Here's how we stand out</Heading>
              </FeatureColumn>
            </div>
          </div>
        </div>
      </ThirdSectionContainer>
      <MobileCarouselSection />
    </>
  );
};

export default MobileThirdSection;
