import React from "react";
import "../BeforeFooter.css"; // Import the CSS file

const BeforeFooterMobile = () => {
  const desktopSeventhSectionStyle = {
    backgroundImage: 'url("assets/images/home-page/before-footer.webp")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "15px",
  };

  return (
    <>
      {/* Desktop version */}
      <div
        className="DesktopSeventhSectionContainer"
        style={desktopSeventhSectionStyle}
      >
        <h2 className="DesktopLeftColumn">
          Experience the Future of
          
          Business Analytics with
          
          IntentBuy
        </h2>
        <div className="DesktopVerticalLine" />
        <div className="DesktopRightColumn">
          <h2 className="DesktopRightColumnHeading">Book a Demo Today</h2>
          <div className="DesktopFormContainer">
            {/* Replace the paragraph with an input field */}
            <input
              type="email"
              className="DesktopEmailAddressLabel DesktopEmailAddressInput"
              placeholder="Email Address"
              style={{ background: "transparent", border: "none" }}
            />
            <button className="DesktopJoinNowButton">
              <p className="MobileJoinNowButtonTextHomePage">Join Now</p>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile version */}
      <div className="MobileSeventhSectionContainerRealTime">
        <h2 className="MobileHeadingRealTime">
        Have any additional questions?
        </h2>
        <div className="MobileNewEmailFormRealTime">
          <div className="MobileNewEmailFieldRealTime">
            {/* Replace the paragraph with an input field */}
            <input
              type="email"
              className="MobileEmailLabelTextRealTime  MobileEmailInput"
              placeholder="Email Address"
              style={{ background: "transparent", border: "none" }}
            />
          </div>
          <button className="MobileNewJoinNowButtonRealTime">
            <p className="MobileJoinNowButtonTextRealTime">Get in Touch</p>
          </button>
        </div>
      </div>
    </>
  );
};

export default BeforeFooterMobile;
