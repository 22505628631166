// real-time.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/real-time.css"; // Import the CSS file
import CustomSection from "./component/CustomSection";
import NavBar from "./component/Navbar";
import FrequentlyAskedQuestion from "./component/FrequentlyAskedQuestion";
import PageTitle from "./PageTitle";
import ResponsiveFooter from "./component/footer";
import BeforeFooterMobile from "./component/Mobile-Component/BeforeFooterMobile";
import styled from "styled-components";
import MobileNavbar from "./component/Mobile-Component/MobileNavbar";
import MobileSectionRealTimeFourth from "./component/Mobile-Component/RealTimeFourth";
import RealTimeSecondMobileSection from "./component/Mobile-Component/RealTimeSecond";
import RealTimeFirstMobileSection from "./component/Mobile-Component/RealTimeFirst";
import RealTimeThirdMobileSection from "./component/Mobile-Component/RealTimeThird";
import RealTimeFifthMobileSection from "./component/Mobile-Component/RealTimeFifth";
import MobileFooter from "./component/Mobile-Component/MobileFooter";

const DesktopOnly = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

function RealTime() {
  const homePageHeading = (
    <div>
      Frequently Asked
      <br />
      Questions
    </div>
  );
  const homePageAdditionalContent = (
    <>
      <p>
        Our platform is engineered to transform your
        <br /> website's anonymous traffic into a goldmine
        <br /> of qualified leads
      </p>
      {/* Add more lines as needed */}
    </>
  );

  const homePageFaqData = [
    {
      question: "What is Visitor Tracking and IP Append?",
      answer:
        "IP append is the process by which website visitors are identified for marketing, prospecting, and lead nurturing. When visitors come to your website, we assign them a unique Identifying code that allows us to track repeat visits. Specifically, we can tell you which companies are visiting your website, which pages they’re viewing, and which sources directed them there. On your end, all you have to do to start uncovering visitors is add our tracking script to your website.",
    },
    {
      question: "How does Visitor Tracking and IP Append work?",
      answer:
        "When a visitor clicks on your website, we assign them a unique Identifying code. This allows us to track repeat visits. Specifically, we can tell you which companies are visiting your website, which pages they’re viewing, and which sources directed them there. On your end, all you have to do to start uncovering visitors is add our tracking script to your website.",
    },
    {
      question: "How does Visitor Tracking and IP Append benefit salespeople?",
      answer:
        "This helps uncover the warm leads that you already have! What is a warm lead? A warm lead is a business or individual who has shown interest in your business but has not decided to start doing business with you yet. These leads are easier to convert than cold leads because they are already familiar with your business and have started looking at your site. Some of them may even understand the value of your products without you having to explain it to them. Consequently, they are more likely to become customers than other leads.",
    },
    {
      question:
        "How does Visitor Tracking and IP Append benefit marketing departments?",
      answer:
        "Visitor Tracking is a form of sales intelligence that identifies companies that are actively researching your product on your site. These companies are exhibiting intent signals. In other words, they are indicating their intention to purchase a product or service.  It’s worth noting that a buyer will likely select the vendor that makes first contact. That’s why identifying those warm leads visiting your website and knowing who they are and where they came from is so important to your marketing team. This will help them understand which marketing plans are sending leads directly to your site and which are not.",
    },
  ];

  const containerStyle = {
    backgroundColor: "#000",
    paddingTop: "50px",
    // Add any other styles you want to customize
    headingStyle: {
      color: "#fff", // Replace 'blue' with your desired heading color
    },
    questionStyle: {
      color: "#FFF", // Replace 'green' with your desired question color
    },
  };

  const handleRequestDemo = () => {
    window.location.href = "/#before-footer";
  };

  return (
    <>
      <PageTitle title={"Real Time Visitor Identification"} />
      <DesktopOnly>
        <NavBar />
      </DesktopOnly>
      <MobileOnly>
        <MobileNavbar />
      </MobileOnly>
      <div className="container mt-5">
        {/* PreFirst Section - Desktop Version */}
        <div
          className="PreFirstSection row mt-3 d-none d-md-flex"
          style={{
            backgroundImage:
              'url("/assets/images/real-time/real-time-1st-bg.webp")', // Replace with the path to your background image
            backgroundSize: "cover",
            backgroundPosition: "top-center",
          }}
        >
          {/* Left Column */}
          <div
            className="LeftColumn col-md-5 d-flex flex-column align-items-start justify-content-center"
            style={{ paddingRight: "20px", marginBottom: "100px" }}
          >
            <h2
              className="mb-2"
              style={{
                color: "var(--Gray-900, #2A3342)",
                fontFamily: "Soleil",
                fontSize: "40px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "51px",
                letterSpacing: "-0.8px",
              }}
            >
              Uncover Insights Into <br /> Visitor Behavior Instantly
            </h2>

            <p
              className="text-muted mb-4"
              style={{
                color: "#626981",
                fontFamily: "Soleil",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "26px",
              }}
            >
              Identify your website visitors, what pages they visit,
              <br /> and what actions they take on your website in real time!
            </p>

            <div className="ButtonsContainer">
              <button className="DemoButton1" onClick={handleRequestDemo}>
                Request a Demo
              </button>
              <button className="SignUpButton1" onClick={handleRequestDemo}>
                Sign Up
              </button>
            </div>
          </div>

          {/* Right Column */}
          <div
            className="RightColumn col-md-7"
            style={{ padding: "0", height: "auto" }}
          >
            <img
              src="/assets/images/real-time/real-time-1.png"
              alt="Illustration for PreFirst Section"
              className="img-fluid"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>

        <RealTimeFirstMobileSection />

        {/* Desktop Section */}
        <div
          className="SecondSection row mt-5 align-items-center d-none d-md-flex"
          style={{
            backgroundImage:
              'url("/assets/images/real-time/real-time-1st-bg.webp")', // Replace with the path to your background image
            backgroundSize: "cover",
            backgroundPosition: "top-center",
          }}
        >
          <div className="col-md-6">
            {/* Left Column (Image) */}
            <img
              src="/assets/images/real-time-second.webp"
              alt="real-time-second"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 col-12 d-flex flex-column align-items-center align-items-md-center">
            {/* Right Column */}
            <h2 className="mb-4">
              Discover the untapped potential of your website traffic!
            </h2>
            <p>
              In a world where only about 2 percent of website visitors fill out
              a website form, you’re missing out on the other 98 percent! That’s
              the case many businesses are facing when it comes to website
              traffic.
            </p>
          </div>
        </div>

        {/* Mobile Section - RealTimeSecond */}
        <MobileOnly>
          <RealTimeSecondMobileSection />
        </MobileOnly>

        {/* Third Section Desktop */}
        <div className="ThirdSection row mt-5 d-none d-md-flex">
          {/* First Column */}
          <div className="col-md-6 d-flex flex-column align-items-center text-center">
            <img
              src="/assets/images/real-time/real-time-3.1.webp" // Replace with the actual image path
              alt="Your Alt Text"
              className="img-fluid"
            />
            <h2
              className="mt-4"
              style={{
                color: "#2C323E",
                fontFamily: "Soleil",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                letterSpacing: "-0.96px",
              }}
            >
              Website Visitor Tracking
            </h2>
            <p
              style={{
                color: "#626981",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "26.5px",
              }}
            >
              We’re putting an end to this by offering anonymous <br /> website
              visitor tracking allowing marketers to understand <br /> who lands
              on your website.
            </p>
          </div>

          {/* Second Column */}
          <div className="col-md-6 d-flex flex-column align-items-center text-center">
            <img
              src="/assets/images/real-time/real-time-3.2.webp" // Replace with the actual image path
              alt="Your Alt Text"
              className="img-fluid"
            />
            <h2
              className="mt-4"
              style={{
                color: "#2C323E",
                fontFamily: "Soleil",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                letterSpacing: "-0.96px",
              }}
            >
              Advanced Website Analytics
            </h2>
            <p
              style={{
                color: "#626981",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "26.5px",
              }}
            >
              When someone visits your site, our technology can record <br />
              information such as company name, pages viewed, keywords <br />{" "}
              searched, and other relevant information that would not <br />{" "}
              have been available to you without it.
            </p>
          </div>
        </div>

        <RealTimeThirdMobileSection />

        {/* Fourth Section Desktop */}
        <div
          className="FourthSection row mt-5 d-none d-md-flex"
          style={{
            backgroundImage:
              "url(/assets/images/real-time/real-time-fourth.webp)", // Replace with the actual image path
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          {/* First Column */}
          <div className="col-md-6 col-12 mb-md-0 mb-3 d-flex flex-column align-items-center justify-content-center">
            <h2>
              Catch the one
              <br /> that Got Away!!
            </h2>
            <p>
              We have all been there, after spending <br />
              precious time on a potential buyer, they
              <br /> disappear.
            </p>
            <p>
              With our advanced form and email
              <br /> integration, you will know when they
              <br /> secretly return to your site, giving your
              <br /> sales team the right opportunity to close
              <br /> the sale.
            </p>
          </div>
          {/* Second Column - Replace image with video */}
          <div className="col-md-6 col-12 d-flex align-items-center justify-content-center justify-content-center">
            <img
              src="/assets/images/real-time/real-time-4.webp"
              alt="Your Alt Text"
              className="img-fluid"
            />
          </div>
        </div>

        <MobileOnly>
          <MobileSectionRealTimeFourth />
        </MobileOnly>

        <DesktopOnly>
          {/* Fifth Section */}
          <div
            className="FifthSection row mt-5"
            style={{
              backgroundImage: "url(/assets/images/real-time/real-time-5.webp)", // Replace with the actual image path
              backgroundSize: "contain",
              backgroundPosition: "center center",
            }}
          >
            {/* First Row */}
            <div
              className="col-12 text-center"
              style={{
                background:
                  "var(--Gradient, linear-gradient(91deg, #1F86E3 14.24%, #1B3B97 100%))",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              <div className="FeatureBadge">FEATURES</div>
            </div>

            {/* Second Row */}
            <div className="col-12 text-center mt-3">
              <h2
                className="VisitorTrackingHeading pb-5"
                style={{
                  fontFamily: "Soleil",
                  fontSize: "24px",
                  lineHeight: "32px",
                }}
              >
                {window.innerWidth > 767 ? (
                  <>
                    Visitor Tracking (Ip Append) Packed With
                    <br /> Features To Make Lead Generation Possible
                  </>
                ) : (
                  <span>
                    Visitor Tracking (Ip Append)
                    <br /> Packed With Features To Make
                    <br /> Lead Generation Possible
                  </span>
                )}
              </h2>
            </div>

            {/* Third and Fourth Rows */}
            <div className="row mb-4 mt-4">
              {/* First Column */}
              <div className="col-md-4 text-center">
                <div className="FeatureColumn">
                  <img
                    src="/assets/images/real-time/real-time-section-51.webp" // Replace with the actual image path
                    alt="Contact Insights"
                    className="img-fluid"
                  />
                  <h3 className="FeatureHeading">Contact Insights</h3>
                  <p className="FeatureContent">
                    We offer access to a robust database,
                    <br /> including email addresses , phone numbers,
                    <br /> allowing your sales team to immediately
                    <br /> engage the prospect.
                  </p>
                </div>
              </div>

              {/* Second Column */}
              <div className="col-md-4 text-center">
                <div className="FeatureColumn">
                  <img
                    src="/assets/images/real-time/real-time-section-52.webp" // Replace with the actual image path
                    alt="Company Data"
                    className="img-fluid"
                  />
                  <h3 className="FeatureHeading">Company Data</h3>
                  <p className="FeatureContent">
                    We offer access to a robust database of
                    <br /> company information, as well as quick
                    <br /> links for social and news research.
                  </p>
                </div>
              </div>

              {/* Third Column */}
              <div className="col-md-4 text-center">
                <div className="FeatureColumn">
                  <img
                    src="/assets/images/real-time/real-time-section-53.webp" // Replace with the actual image path
                    alt="Real-Time Alerts"
                    className="img-fluid"
                  />
                  <h3 className="FeatureHeading">Real-Time Alerts</h3>
                  <p className="FeatureContent">
                    Never miss a prospect with our real-
                    <br />
                    time notification system. Collecting and
                    <br /> emailing you critical information about
                    <br /> your visitors.
                  </p>
                </div>
              </div>
            </div>

            <div className="row mb-4 mt-4">
              {/* First Column */}
              <div className="col-md-4 text-center">
                <div className="FeatureColumn">
                  <img
                    src="/assets/images/real-time/real-time-section-54.webp" // Replace with the actual image path
                    alt="Lead Handling"
                    className="img-fluid"
                  />
                  <h3 className="FeatureHeading">Lead Handling</h3>
                  <p className="FeatureContent">
                    Per-user lead handling allows for the
                    <br /> right leads to reach the right
                    <br /> salespeople at the right time. Start
                    <br /> handling your leads smarter!
                  </p>
                </div>
              </div>

              {/* Second Column */}
              <div className="col-md-4 text-center">
                <div className="FeatureColumn">
                  <img
                    src="/assets/images/real-time/real-time-section-55.webp" // Replace with the actual image path
                    alt="Integrated Analytics"
                    className="img-fluid"
                  />
                  <h3 className="FeatureHeading">Integrated Analytics</h3>
                  <p className="FeatureContent">
                    Website visitor analytics can help you
                    <br /> increase sales through the identification of
                    <br /> leads, while also realizing what pages are
                    <br /> most interesting.
                  </p>
                </div>
              </div>

              {/* Third Column */}
              <div className="col-md-4 text-center">
                <div className="FeatureColumn">
                  <img
                    src="/assets/images/real-time/real-time-section-56.webp" // Replace with the actual image path
                    alt="Integrations"
                    className="img-fluid"
                  />
                  <h3 className="FeatureHeading">Integrations</h3>
                  <p className="FeatureContent">
                    Send your leads to more than 1,000
                    <br /> unique applications with our Zapier and
                    <br /> API Integrations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </DesktopOnly>
        <MobileOnly>
          <RealTimeFifthMobileSection />
        </MobileOnly>
      </div>
      <div style={containerStyle}>
        <FrequentlyAskedQuestion
          heading={homePageHeading}
          additionalContent={homePageAdditionalContent}
          faqData={homePageFaqData}
          headingStyle={containerStyle.headingStyle}
          questionStyle={containerStyle.questionStyle}
        />
      </div>

      {/* Seventh section */}
      <DesktopOnly>
        <CustomSection />
      </DesktopOnly>
      <MobileOnly>
        <BeforeFooterMobile />
      </MobileOnly>
      <DesktopOnly>
        <ResponsiveFooter customBackgroundColor={true} />
      </DesktopOnly>
      <MobileOnly>
        <MobileFooter />
      </MobileOnly>
    </>
  );
}

export default RealTime;
