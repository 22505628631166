// Banner.js
import React from "react";
import NavBar from "./Navbar";
import styled from "styled-components";
import MobileNavbar from "./Mobile-Component/MobileNavbar";

const BannerContainer = styled.div`
  position: relative;
  height: 626.354px;
  margin-bottom: 40px;
`;

const BannerContent = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MobileBannerContent = styled(BannerContent)`
  padding: 0 20px;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -180px; /* Adjust as needed */
  gap: 40px;
  @media (max-width: 767px) {
    margin-top: 20px; /* Adjust as needed */
  }
`;

const Card = styled.div`
  max-width: 450.471px;
  height: 472.415px;
  flex-shrink: 0;
  border-radius: 15px;
  padding: 20px;
  position: relative;
  z-index: 1;
`;

const CardImage = styled.img`
  width: 100%; /* Make image take up full width of the card */
  max-width: 100%; /* Adjusted for smaller screens */
  height: auto;
  object-fit: cover;
  border-radius: 15px;
`;
const CardHeading = styled.h2`
  color: #2c323e;
  font-family: Soleil;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.96px;
  text-align: center;
  margin-top: 30px;
`;

const CardContent = styled.p`
  color: #556987;
  text-align: center;
  font-family: Soleil;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.5px;
  margin-top: 10px;
`;

const MobileCardsContainer = styled.div`
margin-top: -80px;
  @media (min-width: 768px) {
    display: none; /* Hide for screens wider than 767px (non-mobile) */
    
  }
`;

const MobileCard = styled(Card)`
  /* Add any additional styles for mobile cards */
`;

const handleRequestDemo = () => {

  window.location.href = "/#before-footer";
};

const Banner = ({ transparent }) => {
  return (
    <>
      {/* Desktop Version */}
      <div className="d-none d-md-block">
        <BannerContainer>
          <div
            style={{
              backgroundImage:
                "url(/assets/images/Lead-Scoring/lead-sourcing-banner.webp)",
              backgroundSize: "cover",
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          />
          <NavBar transparent={true} whiteText={true} customTextColor="#000" />
          <BannerContent>
            <h1
              style={{
                color: "#FFF",
                fontFamily: "Soleil",
                fontSize: "40px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "51px",
                letterSpacing: "-0.8px",
                paddingBottom: "10px",
              }}
            >
              Tailoring Ideal Leads Based on Company
              <br /> Attributes and User Behavior
            </h1>

            <p
              style={{
                color: "#BBB",
                fontFamily: "Soleil",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "30px",
                paddingBottom: "20px",
              }}
            >
              Combine company attributes such as industry, size, and location
              with behavior
              <br /> like time on site or pricing page visits. We will then
              automatically capture, save,
              <br /> and send you ideal leads.
            </p>

            <button
              style={{
                color: "#fff",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "13.943px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "13.943px",
                border: "4px solid #161F2E",
                borderRadius: "49.055px",
                background:
                  "var(--Gradient, linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%))",
                outline: "solid var(--Gradient, #1f86e3) 1.162px",
                padding: "14.267px 49.048px",
                cursor: "pointer",
                transition: "background 0.3s ease, box-shadow 0.3s ease",
                position: "relative",
              }}
              onClick={handleRequestDemo}>
              Request a Demo
              <span
                style={{
                  content: '"›"',
                  width: "5.81px",
                  height: "9.295px",
                  flexShrink: 0,
                  color: "#ffffff",
                  marginLeft: "5px",
                  zIndex: 2,
                  position: "absolute",
                  top: "45%",
                  transform: "translateY(-50%)",
                }}
              >
                ›
              </span>
            </button>
          </BannerContent>
        </BannerContainer>
      </div>

      {/* Mobile Version */}
      <BannerContainer className="d-md-none mx-auto">
        <div
          style={{
            backgroundImage:
              "url(/assets/images/Lead-Scoring/lead-sourcing-banner-mob.webp)",
            backgroundSize: "cover",
            position: "absolute",
            width: "100%",
            height: "750px",
          }}
        />
        <MobileNavbar whiteText={true} />
        <MobileBannerContent className="text-white">
          {/* Adjusted content for mobile version */}
          <h1
            className="mb-1"
            style={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Soleil",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "39px",
              letterSpacing: "-0.4px",
            }}
          >
            Tailoring Ideal Leads Based on
          </h1>

          <h1
            className="mb-3"
            style={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Soleil",
              fontSize: "34px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "40px",
              letterSpacing: "-0.72px",
            }}
          >
            Company Attributes & User Behavior
          </h1>

          <p
            className="mb-3"
            style={{
              color: "#BBB",
              textAlign: "center",
              fontFamily: "Soleil",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "23px",
              paddingTop: "15px",
              paddingBottom: "20px",
            }}
          >
            Combine company attributes such as industry, size, and location with
            behavior like time on site or pricing page visits. We will then
            automatically capture, save, and send you ideal leads.
          </p>

          <button 
          style={{
                color: "#fff",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "13.943px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "13.943px",
                border: "4px solid #1B3A59",
                borderRadius: "49.055px",
                background:
                  "var(--Gradient, linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%))",
                outline: "solid var(--Gradient, #1f86e3) 1.162px",
                padding: "18.267px 32.048px",
                cursor: "pointer",
                transition: "background 0.3s ease, box-shadow 0.3s ease",
                position: "relative",
              }}
              onClick={handleRequestDemo}>
              Request a Demo
              <span
                style={{
                  content: '"›"',
                  width: "5.81px",
                  height: "9.295px",
                  flexShrink: 0,
                  color: "#ffffff",
                  marginLeft: "5px",
                  zIndex: 2,
                  position: "absolute",
                  top: "45%",
                  transform: "translateY(-50%)",
                }}
              >
                ›
              </span>
          </button>
        </MobileBannerContent>
      </BannerContainer>

      {/* Cards section */}
      {/* Desktop Version */}
      <div className="d-none d-md-block">
        <CardsContainer>
          <Card>
            <CardImage
              src="assets/images/Lead-Scoring/lead-scoring-card-1.webp"
              alt="Card 1"
            />
            <CardHeading>Smart Lead Scoring</CardHeading>
            <CardContent>
              Automatically score and categorize
              <br /> leads to focus on those that best fit
              <br /> your business.
            </CardContent>
          </Card>

          <Card>
            <CardImage
              src="assets/images/Lead-Scoring/lead-scoring-card-2.webp"
              alt="Card 2"
            />
            <CardHeading>Tailored Insights</CardHeading>
            <CardContent>
              Combine company information with
              <br /> visitor behavior to identify key
              <br />
              opportunities.
            </CardContent>
          </Card>

          <Card>
            <CardImage
              src="assets/images/Lead-Scoring/lead-scoring-card-3.webp"
              alt="Card 3"
            />
            <CardHeading>Prioritize Quality Leads</CardHeading>
            <CardContent>
              Avoid losing valuable prospects by
              <br /> focusing on high-potential companies.
            </CardContent>
          </Card>
        </CardsContainer>
      </div>

      {/* Mobile Cards section */}
      <div className="d-md-none">
        <MobileCardsContainer>
          <MobileCard style={{ paddingBottom: '20px' }}>
            <CardImage
              src="assets/images/Lead-Scoring/lead-scoring-card-1.webp"
              alt="Card 1"
            />
            <CardHeading>Smart Lead Scoring</CardHeading>
            <CardContent>
              Automatically score and categorize leads to focus on those that
              best fit your business.
            </CardContent>
          </MobileCard>

          <MobileCard style={{ paddingBottom: '20px' }}>
            <CardImage
              src="assets/images/Lead-Scoring/lead-scoring-card-2.webp"
              alt="Card 2"
            />
            <CardHeading>Tailored Insights</CardHeading>
            <CardContent>
              Combine company information with visitor behavior to identify key
              opportunities.
            </CardContent>
          </MobileCard>

          <MobileCard style={{ paddingBottom: '20px' }}>
            <CardImage
              src="assets/images/Lead-Scoring/lead-scoring-card-3.webp"
              alt="Card 3"
            />
            <CardHeading>Prioritize Quality Leads</CardHeading>
            <CardContent>
              Avoid losing valuable prospects by focusing on high-potential
              companies.
            </CardContent>
          </MobileCard>
        </MobileCardsContainer>
      </div>
    </>
  );
};

export default Banner;
