import React from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

const images = [
  "/assets/images/home-page/scroll3.1.webp",
  "/assets/images/home-page/scroll3.2.webp",
  "/assets/images/home-page/scroll3.3.webp",
  "/assets/images/home-page/scroll3.4.webp",
];

const contentData = [
  {
    heading: "Real-Time Visitor Identification",
    subheading: "Know who's on your site at any moment.",
    link: "/real-time",
  },
  {
    heading: "Lead Scoring & Prioritization",
    subheading: "Focus on leads that matter",
    link: "/lead-scoring",
  },
  {
    heading: "Google Analytics Enrichment",
    subheading: "Enhance your existing analytics for deeper insights",
    link: "/analytics",
  },
  {
    heading: "Seamless Integration",
    subheading: "Works effortlessly with your favorite tools",
    link: "/Seamless-Integration",
  },
];

const MobileCarouselSection = () => {
  return (
    <MobileCarouselContainer>
      <SlideContainer>
        {images.map((image, index) => (
          <Slide key={index}>
            <ImageContainer>
            <Image src={image} alt={`Image ${index + 1}`} className="padded-image" />
            </ImageContainer>
            <ContentContainer>
              <Heading>{contentData[index].heading}</Heading>
              <Text>{contentData[index].subheading}</Text>
              <Button href={contentData[index].link}>Know More</Button>
            </ContentContainer>
          </Slide>
        ))}
      </SlideContainer>
    </MobileCarouselContainer>
  );
};

const MobileCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("assets/images/home-page/third-mini.webp") center/cover;
  padding-bottom: 30px;
`;

const SlideContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
`;

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Adjust the max width as needed */
`;

const ImageContainer = styled.div`
  flex-shrink: 0;
`;

const Image = styled.img`
  width: 100vw;
  object-fit: cover;
  margin: 0 auto;
  align-self: flex-end; /* Use align-self instead of justify-content */
  &.padded-image {
    padding-left: 5px;
  }
`;

const ContentContainer = styled.div`
  margin-top: 10px;
`;

const Heading = styled.div`
  color: #fff;
  font-family: Soleil;
  font-size: 22px;
  font-weight: 400;
  line-height: 37.546px;
  letter-spacing: -0.69px;
  padding-top: 20px;
`;

const Text = styled.div`
  color: #fff;
  font-family: Soleil;
  font-size: 14px;
  line-height: 35.626px;
  padding-bottom:20px;
`;

const Button = styled.a`
color: #fff;
text-align: center;
font-family: Soleil;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 12px;
border: 4px solid #0e1623;
border-radius: 46.477px;
background: var(
  --Gradient,
  linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%)
);
outline: solid var(--Gradient, #1f86e3) 1.162px;
padding: 12px 20px;
cursor: pointer;
transition: background 0.3s ease, box-shadow 0.3s ease;
text-decoration: none;  
gap: 10px;
display: inline-flex;
margin-bottom: 20px;
&::after {
  content: "›";
  width: 5.81px;
  height: 9.295px;
  flex-shrink: 0;
  color: #ffffff;
  margin-left: 5px;
  z-index: 2;
}
`;



export default MobileCarouselSection;
