import React from "react";
import "./BeforeFooter.css"; // Import the CSS file
import styled from "styled-components";

const SeventhSection = React.forwardRef((props, ref) => {
  const desktopSeventhSectionStyle = {
    backgroundImage: 'url("assets/images/home-page/before-footer.webp")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "15px",
  };

  return (
    <>
      <div ref={ref}>
        {/* Desktop version */}
        <div
          className="DesktopSeventhSectionContainer"
          style={desktopSeventhSectionStyle}
        >
          <h2 className="DesktopLeftColumn">
            Experience the Future of Business Analytics with IntentBuy
          </h2>
          <div className="DesktopVerticalLine" />
          <div className="DesktopRightColumn">
            <h2 className="DesktopRightColumnHeading">Book a Demo Today</h2>
            <div className="DesktopFormContainer">
              {/* Replace the paragraph with an input field */}
              <input
                type="email"
                className="DesktopEmailAddressLabel DesktopEmailAddressInput"
                placeholder="Email Address"
                style={{ background: "transparent", border: "none" }}
              />
              <button className="DesktopJoinNowButton">
                <p className="DesktopJoinNowButtonText">Join Now</p>
              </button>
            </div>
          </div>
        </div>
      

  
        {/* Mobile version */}
        <div className="MobileSeventhSectionContainer">
          <h2 className="MobileHeading">
            Experience the Future of Business Analytics with IntentBuy
          </h2>
          <div className="MobileHorizontalLine" />
          <h1 className="MobileSubheading">Book a Demo Today</h1>
          <div className="MobileNewEmailFormHomepage">
            <div className="MobileNewEmailField">
              {/* Replace the paragraph with an input field */}
              <input
                type="email"
                className="MobileEmailLabelTexthomepage MobileEmailInput"
                placeholder="Email Address"
                style={{ background: "transparent", border: "none" }}
              />
            </div>
            <button className="MobileNewJoinNowButtonHomePage">
              <p className="MobileJoinNowButtonTextHomePage">Join Now</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default SeventhSection;
