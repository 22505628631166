import React from "react";
import PageTitle from "./PageTitle";
import Banner from "./component/banner-seamless-integration";
import styled from "styled-components";
import MobileBannerSeamlessIntegration from "./component/Mobile-Component/MobileBannerSeamlessIntegration";
import MobileSectionComponent from "./component/Mobile-Component/Seamless-Integration-Mobile-second";
import SeamlessMobileVideoComponent from "./component/Mobile-Component/SemalessMobileVideoComponent";

const DesktopOnly = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

// Styled components for the main container
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`;

// Main component for the Seamless Integration page
const SeamlessIntegrationPage = () => {
  return (
    <Container>
      <PageTitle title={"Seamless Integration"} />
      <DesktopOnly>
        <Banner />
      </DesktopOnly>
      <MobileOnly>
        <MobileBannerSeamlessIntegration />
      </MobileOnly>
      <MobileOnly>
        <MobileSectionComponent />
      </MobileOnly>
      <MobileOnly>
        <SeamlessMobileVideoComponent />
      </MobileOnly>
    </Container>
  );
};

export default SeamlessIntegrationPage;
