import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  background: ${(props) =>
    props.customBackgroundColor
      ? "url(/assets/images/gradient-overlay.webp) center bottom/contain no-repeat, #000"
      : "url(/assets/images/Contact-us/footer-bg1.png) right bottom/contain no-repeat, #000"};
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => !props.customBackgroundColor && "padding-top: 20px;"}
`;


const CopyrightText = styled.div`
  display: flex;
  width: 100%;
  height: 14.4px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  opacity: 0.6;
 
`;

const Text = styled.p`
  color: #fff;
  font-family: Soleil;
  font-size: 14.4px;
  font-style: normal;
  font-weight: 500;
  line-height: 14.4px;
  margin: 0;
  text-align: center;
  margin-right: -40px;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: flex-end; /* Align columns to the right */
  padding-top: 200px;
  padding-bottom: 80px;

  @media (max-width: 767px) {
    padding-top: 230px;
    justify-content: flex-start; /* Adjust for smaller screens */
  }
`;

const Column = styled.div`
  text-align: center;

  @media (max-width: 767px) {
    text-align: left;
    
  }
`;

const Logo = styled.img`
  width: 205.55px;
  height: auto;
  margin-bottom: 10px;
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 30px;
  margin-top: 20px;

  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;
const AccountColumn = styled(Column)`
`;

const PagesColumn = styled(Column)`
  margin-bottom: 20px;
  
  text-align: left; /* Align text to the left */

  h3 {
    padding-bottom: 10px; /* Add padding to the bottom of the heading */
  }
`;

const CompanyColumn = styled(Column)`
  text-align: left; /* Align text to the left */
  

  h3 {
    padding-bottom: 10px; /* Add padding to the bottom of the heading */
  }
`;


const PagesLink = styled.a`
  text-decoration: none;
`;

const PageLinkText = styled.p`
  color: #fff;
  font-family: Soleil;
  font-size: 16.8px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.8px;
  margin: 0;
  padding-bottom: 15px; /* Add padding to the bottom of each link */
  display: block;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

const ColumnHeading = styled.h3`
  color: #fff;
  font-family: Soleil;
  font-size: 16.8px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.8px;
  padding-bottom: 10px; /* Add padding to the bottom of the heading */
`;



const LogoLink = styled.a`
  text-decoration: none; // Remove underline style for the link
`;

const VerticalLine = styled.div`
  border-left: 1px solid #969696;
  height: 157.008px;
   // Adjust the margin as needed

`;


const ResponsiveFooter = ({ customBackgroundColor }) => {
  return (
    <FooterContainer
      className="container-fluid"
      customBackgroundColor={customBackgroundColor}
    >
      <RowWrapper className="row">
        <Column className="col-lg-4">
          <LogoLink href="https://intentbuy.io/">
            <Logo src="/assets/images/footerlogo.webp" alt="Logo" />
          </LogoLink>
          <SocialIcons>
          <Icon src="/assets/images/twitter-1.webp" alt="Twitter" />
            <Icon src="/assets/images/facebook-1.webp" alt="Facebook" />
            <Icon src="/assets/images/instagram-1.webp" alt="Instagram" />
          </SocialIcons>
          <CopyrightText>
        <Text>© 2024 IntentBuy. All rights reserved.</Text>
      </CopyrightText>
        </Column>
         {/* Dummy columns added to the left */}
         <Column className="col-lg-1"></Column>
        <Column className="col-lg-1"></Column>
        <PagesColumn className="col-lg-2">
        <ColumnHeading>Pages</ColumnHeading>
          <div>
            <PagesLink href="/"><PageLinkText>Home</PageLinkText>
              </PagesLink>
          </div>          
          <div>
            <PagesLink href="/Pricing"><PageLinkText>Pricing</PageLinkText></PagesLink>
          </div>
          <div>
            <PagesLink href="/Contact-Us"><PageLinkText>Contact Us</PageLinkText>
              </PagesLink>
          </div>
          <div>
            <PagesLink href="/Privacy-Policy"><PageLinkText>Privacy Policy</PageLinkText></PagesLink>
          </div>
        </PagesColumn>
        <AccountColumn className="col-lg-1">
        <VerticalLine />
        </AccountColumn>
        <CompanyColumn className="col-lg-3">
        <ColumnHeading>Product Pages</ColumnHeading>
        <div>
            <PagesLink href="/real-time">
            <PageLinkText>Real Time Visitor Identification</PageLinkText>
            </PagesLink>
          </div>
          <div>
            <PagesLink href="/lead-scoring"><PageLinkText>Lead Scoring</PageLinkText></PagesLink>
          </div>
          <div>
            <PagesLink href="/analytics"><PageLinkText>GA Enrichment</PageLinkText></PagesLink>
          </div>
          <div>
            <PagesLink href="/Seamless-Integration"><PageLinkText>Seamless Integration</PageLinkText>
            </PagesLink>
          </div>
        </CompanyColumn>
      </RowWrapper>      
    </FooterContainer>
  );
};

export default ResponsiveFooter;
