import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const MobileFooter = ({ footerStyle }) => {
  const logoStyle = {
    width: "215.212px",
    height: "41.48px",
  };

  const socialIconStyle = {
    width: "34.218px",
    height: "34.218px",
    marginTop: "10px",
    marginBottom: "10px",
  };

  const localFooterStyle = {
    paddingTop: "80px",
    backgroundImage: "url('/assets/images/Mobile-footer-bg.webp')",
    backgroundSize: "cover",
    backgroundPosition: "center right",
    ...footerStyle, // Merge the provided footerStyle prop
  };

  const linkStyle = {
    textDecoration: "none", // Remove underline style for links
    color: "#fff", // Set the text color for links
    paddingBottom: "20px", // Add 10px padding below each link
    display: "inline-block", // Make the link an inline-block element to apply padding
  };

  const socialIconSpacing = {
    marginRight: "15px", // Add 5px space to the right of each social icon
  };

  const listStyle = {
    listStyle: "disc inside",
    color: "#FFF",
    fontFamily: "Soleil",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "37px", // 264.286%
  };

  const headingStyle = {
    marginBottom: "25px",
    color: "#FFF",
    fontFamily: "Soleil",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "23.823px", // 132.35%
  };

  const copyrightStyle = {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Soleil",
    fontSize: "15.203px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "15.203px", // 100%
  };

  return (
    <footer className="bg-dark text-light" style={localFooterStyle}>
      <Container>
        {/* First Row: Logo and Social Media Icons */}
        <Row>
          <Col xs={12} className="text-left">
            <img
              src="/assets/images/footerlogo.webp"
              alt="Logo"
              className="img-fluid mb-2"
              style={logoStyle}
            />
          </Col>
          <Col xs={12} className="text-left">
            <img
              src="/assets/images/twitter-1.webp"
              alt="Twitter"
              className="social-icon"
              style={{ ...socialIconStyle, ...socialIconSpacing }}
            />
            <img
              src="/assets/images/facebook-1.webp"
              alt="Facebook"
              className="social-icon"
              style={{ ...socialIconStyle, ...socialIconSpacing }}
            />
            <img
              src="/assets/images/instagram-1.webp"
              alt="Instagram"
              className="social-icon"
              style={socialIconStyle}
            />
          </Col>
        </Row>

        {/* Second Row: Pages */}
        <Row>
          <Col xs={12} className="text-left">
            <h5 style={headingStyle}>Pages</h5>
            <ul className="list-unstyled" style={listStyle}>
              <li>
                <a href="/" style={linkStyle}>
                  Home
                </a>
              </li>
              <li>
                <a href="/Pricing" style={linkStyle}>
                  Pricing
                </a>
              </li>
              <li>
                <a href="/Contact-Us" style={linkStyle}>
                  Contact Us
                </a>
              </li>
              <li>
                <a href="/Privacy-Policy" style={linkStyle}>
                  Privacy Policy
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        {/* Third Row: Product Features */}
        <Row>
          <Col xs={12} className="text-left">
            <h5 style={headingStyle}>Product Features</h5>
            <ul className="list-unstyled" style={listStyle}>
              <li>
                <a href="/real-time" style={linkStyle}>
                  Real Time Visitor Identification
                </a>
              </li>
              <li>
                <a href="/lead-scoring" style={linkStyle}>
                  Lead Scoring
                </a>
              </li>
              <li>
                <a href="/analytics" style={linkStyle}>
                  GA Enrichment
                </a>
              </li>
              <li>
                <a href="/Seamless-Integration" style={linkStyle}>
                  Seamless Integration
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        {/* Horizontal Line */}
        <Row className="w-100">
          <Col xs={12} className="p-0">
            <hr
              className="bg-light"
              style={{
                height: "1px",
                margin: "0",
                width: "100vw", // Change this line to use viewport width (100vw)
                border: "none",
              }}
            />
          </Col>
        </Row>

        {/* Copyright Footer */}
        <Row>
        <Col xs={12} className="text-center">
            <p style={copyrightStyle}>&copy; 2024 IntentBuy. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default MobileFooter;
