import React from "react";
import styled from "styled-components";

const MobileContainer = styled.div`
 
  text-align: center;
  width: 100%;
`;

const Heading1 = styled.h2`
  color: #2a3342;
  font-family: Soleil;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.16px;
  opacity: 0.9913;
  padding-top:30px;
`;

const SubHeading = styled.h3`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #2a3342;
  font-family: Soleil;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.315px;
  letter-spacing: -0.745px;
`;

const Content = styled.div`
  display: flex;
  width: 356.146px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #646A81;
  text-align: center;
  font-family: Soleil;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  opacity: 0.9913;
`;

function MobileViewSecond() {
  return (
    <MobileContainer>
      <Heading1>
        Introducing{" "}
        <span
          style={{
            opacity: 0.9913,
            background:
              "var(--Gradient, linear-gradient(91deg, #1F86E3 14.24%, #1B3B97 100%))",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily: "Soleil",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "700",
            letterSpacing: "-0.16px",
          }}
        >
          intentbuy
        </span>
      </Heading1>
      <SubHeading>Making B2B Selling Easier</SubHeading>
      <Content>
        Our platform is engineered to empower B2B teams with actionable
        insights, transforming your website's anonymous traffic into a goldmine
        of qualified leads
      </Content>
    </MobileContainer>
  );
}

export default MobileViewSecond;
