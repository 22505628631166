import React from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import "./MobileNavbar.css";
import { Link } from "react-router-dom";

const MobileNavbar = ({ whiteText  }) => {
  const logoImagePath = whiteText
    ? "/assets/images/logo-transparent.webp"
    : "/assets/images/logo.webp";
  const buttonTextColor = whiteText ? "#FFF" : "#000";
  const navLinkColor = "#000"; // Set the navLinkColor to black
  const navbarTogglerStyle = {
    borderColor: navLinkColor,
    color: navLinkColor,
    backgroundColor: whiteText ? "#FFF" : "#FFF",
    zIndex: 1000,
  };

  const navbarTogglerIconStyle = {
    width: "20px", // Adjust the width as needed
    height: "20px", // Adjust the height as needed
  };

  const navbarStyle = {
    background: whiteText ? "#FFF" : "transparent",
    color: navLinkColor,
    zIndex: 1000,
  };

  const logoStyleMobile = {
    marginLeft: window.innerWidth <= 767 ? "-25px" : "0", // Adjust the margin-left as needed
  };

  return (
    <Navbar bg="transparent" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-between w-100 align-items-center">
          {/* First Column - Menu Icon */}
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={navbarTogglerStyle}
          >
            <span
              className="navbar-toggler-icon"
              style={navbarTogglerIconStyle}
            ></span>
          </Navbar.Toggle>

          {/* Second Column - Logo Image */}
          <Navbar.Brand href="#" style={logoStyleMobile}>
            <img
              src={logoImagePath}
              alt="Logo"
              className="img-fluid"
              style={{ maxWidth: "123.717px", height: "23.857px" }}
            />
          </Navbar.Brand>

          {/* Third Column - Contact Us Button */}
          <Link to="/Contact-Us" style={{ textDecoration: 'none' }}>
            <Button
              variant="outline-info"
              style={{
                display: "flex",
                width: "130px",
                height: "36px",
                padding: "6px 25.5px 10px 25.5px",
                justifyContent: "center",
                alignItems: "center",
                flexShrink: 0,
                borderRadius: "40.027px",
                border: "0.931px solid var(--Gradient, #1F86E3)",
                color: buttonTextColor,
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "12px",
              }}
            >
              Contact Us
            </Button>
          </Link>
        </div>

        {/* Navigation Links */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" style={navbarStyle}>
            <NavLink
              className={`nav-link ${whiteText ? "white-text" : ""}`}
              to="/"
              style={{ color: navLinkColor }}
            >
              Home
            </NavLink>
            <NavLink
              className={`nav-link ${whiteText ? "white-text" : ""}`}
              to="/real-time"
              style={{ color: navLinkColor }}
            >
              Real Time Visitor Identification
            </NavLink>
            <NavLink
              className={`nav-link ${whiteText ? "white-text" : ""}`}
              to="/lead-scoring"
              style={{ color: navLinkColor }}
            >
              Lead Scoring
            </NavLink>
            <NavLink
              className={`nav-link ${whiteText ? "white-text" : ""}`}
              to="/analytics"
              style={{ color: navLinkColor }}
            >
              GA Enrichment
            </NavLink>
            <NavLink
              className={`nav-link ${whiteText ? "white-text" : ""}`}
              to="/Seamless-Integration"
              style={{ color: navLinkColor }}
            >
              Seamless Integration
            </NavLink>
            <NavLink
              className={`nav-link ${whiteText ? "white-text" : ""}`}
              to="/Pricing"
              style={{ color: navLinkColor }}
            >
              Pricing
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MobileNavbar;
