import React, { useState } from "react";
import styled from "styled-components";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      heading:
        "Unlock B2B Brilliance: Intentbuy's Analytics Game-Changer!",
      content:
        "Intentbuy revolutionized our B2B analytics—a game-changer! From filtering marketing actions to identifying traffic by name and tracking changes on the fly, it's the secret sauce for elevating your B2B marketing!",
      name: "Joseph",
      rating: 5,
      image: "/assets/images/home-page/test-2.webp",
    },
    {
      heading:
        "Intentbuy: Smart Scoring, Tailored Insights, Targeted Success!",
      content:
        "Intentbuy transformed our lead management! Seamless CRM integration streamlines our workflow. Defining our ideal customer profile and segmenting visitors with industry filters is a game- changer. Highly recommended for a seamless and targeted lead generation experience!",
      name: "Marsha H.",
      rating: 4,
      image: "/assets/images/home-page/test-3.webp",
    },
    {
      heading: "High-quality data at a great price.",
      content:
        "Very good data quality compared to price. Customer support is very responsive and is always ready to go the extra mile to help solve a challenge. It is a developing software solution that launches new features frequently and is always open for feedback.",
      name: "Germans F.",
      rating: 4,
      image: "/assets/images/home-page/Test-1.webp",
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <SliderContainer className="d-md-none">
      {" "}
      {/* d-md-none makes it visible only on mobile screens */}
      <ArrowsContainer>
        <ArrowIconLeftContainer onClick={handlePrev}>
          <div className="arrow-icon">
            <FaArrowLeft />
          </div>
        </ArrowIconLeftContainer>
        <HeadingContainer>
          <HeadingText>
            What Our <SubHeadingText>Clients Say</SubHeadingText>
          </HeadingText>
        </HeadingContainer>
        <ArrowIconRightContainer onClick={handleNext}>
          <div className="arrow-icon">
            <FaArrowRight />
          </div>
        </ArrowIconRightContainer>
      </ArrowsContainer>
     
      <Card>
        <Heading>{testimonials[currentIndex].heading}</Heading>
        <Content>{testimonials[currentIndex].content}</Content>
        <Footer>
          <Name>
            <Image imagePath={testimonials[currentIndex].image} />
            {testimonials[currentIndex].name}
          </Name>
          <Rating>
            {Array.from(
              { length: testimonials[currentIndex].rating },
              (_, index) => (
                <Star key={index}>★</Star>
              )
            )}
          </Rating>
        </Footer>
      </Card>
      
      <IndicatorContainer>
        {testimonials.map((_, index) => (
          <IndicatorDot
            key={index}
            active={index === currentIndex}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </IndicatorContainer>
    </SliderContainer>
  );
};

const ArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ArrowIconLeftContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  color:#C4C4C4;
`;

const ArrowIconRightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  color:#C4C4C4;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1; /* Allow the heading to take up remaining space */
`;

const HeadingText = styled.h2`
  color: #2a3342;
  font-family: Soleil;
  font-size: 20.104px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.186px;
  letter-spacing: -0.924px;
  margin: 0;
`;

const SubHeadingText = styled.span`
  color: #2a3342;
`;


const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-top: 30px;
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto; /* Push to the bottom */
  margin-bottom: 20px; /* Add some space between the card and indicators */
`;

const IndicatorDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#007BFF" : "#C4C4C4")};
  margin: 0 5px;
  cursor: pointer;
  margin-top: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
`;

const Star = styled.span`
  color: #ffc107; /* Yellow color for the star */
  margin-right: 4px;
`;

const Heading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
    color: #333;
    font-family: Soleil;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 127.273% */
  }
`;

const Content = styled.p`
  font-size: 1rem;
  color: #555;

  @media (max-width: 768px) {
    font-size: 14px;
    color: #475467;
    font-family: Soleil;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }
`;

const Name = styled.span`
  font-family: Soleil;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.617px;
  display: flex;
  align-items: center; /* Align image and text vertically */
  color: #000;
`;


const Image = styled.div`
  width: 20.992px;
  height: 21.56px;
  flex-shrink: 0;
  border-radius: 29.503px;
  background: ${(props) => `url(${props.imagePath})`} lightgray 50% / cover
    no-repeat;
  margin-right: 8px; /* Adjust margin as needed */
`;

const Card = styled.div`
  width: 333.609px;
  height: auto; /* Allow the card to expand based on content height */
  flex-shrink: 0;
  border-radius: 4.539px;
  border: 0.567px solid #eaecf0;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  padding: 20px;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 350px;
    height:270px;
    padding-top:33px;
  }
`;

export default TestimonialSlider;
