import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import "../css/analytics.css"

const Card = ({ number, heading, content, onCardVisible }) => (
  <Element name={`section${number}`} className="scrollable-section">
    <div
      style={{
        position: "relative",
        display: "flex",
        width: "441.762px",
        height: "338.585px",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        borderRadius: "31.306px",
        background: "#FFF",
        marginBottom: "100px",
        marginTop: "100px",
    
        cursor: "pointer",
      }}
      ref={(element) => onCardVisible(number, element)}
    >
      <div style={{ width: "319.61px", transform: "rotate(-0.013deg)" }}>
        <h2
          style={{
            color: "#1B2559",
            fontFamily: "Soleil",
            fontSize: "34.784px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "36.524px",
            letterSpacing: "-1.391px",
          }}
        >
          {heading}
        </h2>
        <p
          style={{
            color: "#68769F",
            fontFamily: "Soleil",
            fontSize: "18.552px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "30.146px",
          }}
        >
          {content}
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          color: "#1B2559",
          fontFamily: "Soleil",
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        {number}
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "8px",
          right: "3px",
          width: "64.931px",
          height: "64.931px",
          flexShrink: 0,
          borderRadius: "84.642px",
          background:
            "conic-gradient(from 100deg at 50% 50%, #1F86E3 51.249960064888deg, #1B3B97 360deg)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#FFF",
          fontSize: "20px",
          fontWeight: 700,
       
        }}
      >
        {/* Use String.prototype.padStart() to add leading zeros to the card number */}
        {number.toString().padStart(2, "0")}
      </div>
    </div>
  </Element>
);

const ScrollComponent = () => {
  const [visibleCard, setVisibleCard] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      console.log(window.innerHeight, "height");
      const windowHeight = window.innerHeight;

      // Check the visibility of each card
      for (var cardNumber = 1; cardNumber <= 3; cardNumber++) {
        console.log(cardNumber,"cardNumber");
        const element = document.getElementById(`section${cardNumber}`);

        if (element) {
          const rect = element.getBoundingClientRect();
          const fullyVisible =
            rect.top >= 0 &&
            rect.bottom <= windowHeight &&
            rect.top <= windowHeight / 2 &&
            rect.bottom >= windowHeight / 2;
console.log( "top b",fullyVisible);

          if (fullyVisible) {
            // setVisibleCard(cardNumber);
            break; // Exit the loop once the first fully visible card is found
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getImagePath = () => {
    // console.log(visibleCard,"visibleCard",window.innerHeight);

    const imagePath = `assets/images/analytics/analytics-second-${visibleCard}.webp`;
    // console.log("Image path:", imagePath);
    return imagePath;
  };

  const onCardVisible = (cardNumber, element) => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // setVisibleCard(cardNumber);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (element) {
      observer.observe(element);
    }
  };
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (event) => {
    // Access scroll-related information from the event object
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    // Calculate the scroll percentage
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

    // Update the state with the current scroll position
    setScrollPosition(scrollTop);
if (scrollPercentage<=30) {
  setVisibleCard(1)
} else if (scrollPercentage>60) {
  setVisibleCard(3)
} else {
  setVisibleCard(2)
}
    // You can do more with the scroll-related information if needed
    console.log("Scroll Top:", scrollTop);
    console.log("Scroll Height:", scrollHeight);
    console.log("Client Height:", clientHeight);
    console.log("Scroll Percentage:", scrollPercentage);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "660px",
        overflowY: "hidden",
        background: 'url("assets/images/analytics/analytics-second.webp")',
        backgroundSize: "cover",
        scrollbarWidth: "thin", // For Firefox
          scrollbarColor: "transparent transparent",
          
       
      }}
    >
      <div
      onScroll={handleScroll}
       className="scrollable-section" 
        style={{
          textAlign: "left",
          overflowY: "scroll",
          height: "90%",
        
        // Increase top padding
      
         
        }}
      >
    
        <Card 
          number="1"
          heading="Optimizing Leads and Budget"
          content="Leverage these enhanced insights from Google Analytics to better understand your marketing performance. This enables you to attract more qualified leads while optimizing your budget."
          onCardVisible={onCardVisible}
        />
        <Card
          number="2"
          heading="Advanced Audience Segmentation"
          content="Additionally, create sophisticated audience segments, like retargeting and lookalike groups, based on detailed company data."
          onCardVisible={onCardVisible}
        />
        <Card
          number="3"
          heading="Boosting Quality Conversions"
          content="You can reduce your cost per acquisition and increase high-quality conversions, all within your current budget, using the enhanced capabilities of Google Analytics through this feature."
          onCardVisible={onCardVisible}
        />
      </div>

      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          scrollbarWidth: "thin", // For Firefox
          scrollbarColor: "transparent transparent",
        }}
      >
        {getImagePath() && (
          <img
            src={getImagePath()}
            alt="Responsive"
            style={{
              width: "638.652px",
              height: "auto",
              marginLeft: "140px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ScrollComponent;