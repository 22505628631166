// src/Pricing.js
import React from "react";
import ResponsiveFooter from "./component/footer";
import NavBar from "./component/Navbar";
import FrequentlyAskedQuestion from "./component/FrequentlyAskedQuestion";
import PageTitle from "./PageTitle";
import PricingCardNew from "./component/NewPricing";
import styled from "styled-components";
import MobileNavbar from "./component/Mobile-Component/MobileNavbar";
import MobileFooter from "./component/Mobile-Component/MobileFooter";

const PricingSection = styled.div`
  padding-top: 50px; /* Add padding top and bottom of 80px */
  padding-bottom: 100px;
`;

const Pricing = () => {
  // Define the content for Frequently Asked Questions on the home page
  const homePageHeading = "Frequently Asked Questions";
  const homePageAdditionalContent = (
    <>
      <p>
        Our platform is engineered to transform your
        <br /> website's anonymous traffic into a goldmine
        <br /> of qualified leads
      </p>
      {/* Add more lines as needed */}
    </>
  );
  const homePageFaqData = [
    {
      question: (
        <div>
          What is a single-touch and multi-touch
          <br />
          attribution model?
        </div>
      ),
      answer: (
        <div>
          Depending upon your requirement, you can select any attribution model
          – single-touch or multi-touch. If your content is enough to engage or
          create demand with a single touch, or it will require multiple
          touches.
        </div>
      ),
    },
    {
      question: <div>What is included in our targeting options?</div>,
      answer: (
        <div>
          <div>
            Our options include <b>Firmographic Targeting</b> (Company Size,
            Industry, Job Title, Revenue, Location…)
            <br />
            <br />
            <b>Technographic Targeting - </b>Current Tech Stack used by the
            companies: Software and Applications: Understanding the types of
            software solutions a company is currently using, including CRM
            systems, accounting software, project management tools, and more.
            Cloud Services: Identifying if and what type of cloud services a
            company uses (e.g., AWS, Google Cloud, Azure) can offer insights
            into their scalability needs and security considerations. Adoption
            Rate of New Technology, Tech Spend, Communication Tools, Security
            Solutions, Integration and APIs…
            <br />
            <br />
            <b>Intent-Based Targeting - </b>
            <br />
            - Website Visits: Tracking which pages a prospect visits, how long
            they stay, and what actions they take can indicate interest in
            specific products or services.
            <br />
            - Content Engagement: Monitoring engagement with specific content
            types, such as downloading whitepapers, attending webinars, or
            interacting with interactive tools.
            <br />
            - Search Queries: Analyzing search terms used by prospects to find
            products or solutions can reveal their immediate needs or pain
            points.
            <br />
            - Product Comparisons: Prospects comparing different products or
            reading reviews might be closer to making a purchase decision.
            <br />
            - Price Page Visits: Visits to pricing pages can indicate a serious
            consideration of purchase.
            <br />
            - Lead Scoring Models: Using advanced algorithms to score leads
            based on their behavior and engagement to predict purchase intent.
            <br />
            - Predictive Buying Models: Analyzing past purchase data and
            behavior to predict future buying actions.
            <br />
            <br />
            And the most important that sets us apart from our competitors is
            our capability to get intent signals from more than 5+ Third Party
            Intent Data providers.
          </div>
        </div>
      ),
    },
    {
      question: (
        <div>
          How does a Behavioural Data Report help with campaign
          <br />
          optimization? (Behaviour Intel for each touch point)?
        </div>
      ),
      answer: (
        <div>
          A Behavioral Data Report enhances campaign optimization by providing
          insights into how prospects interact with your content across
          touchpoints. It allows for precise audience segmentation, content
          personalization, and timely engagement, enhancing relevance and
          conversion. By understanding behavioral patterns, you can tailor your
          strategies, predict future actions, and continuously refine your
          approach for better results.
        </div>
      ),
    },
    {
      question: (
        <div>
          What is the minimum budget for the Programmatic Display
          <br />
          campaign? Can we select our media type?
        </div>
      ),
      answer: (
        <div>
          The budget depends on the budget you want to allocate for this
          campaign. We always advise to this channel as an additional lead
          nurture channel for the non-engaging accounts as the CPM decreases by
          running a targeted campaign to a specific list.
          <br />
          <br />
          Yes, you can select your media type from Display Ads, Native Ads,
          Video, or Rich Media
        </div>
      ),
    },
    {
      question: <div>How do you guys deliver leads?</div>,
      answer: (
        <div>
          We only deliver leads via API or a secure server FTP. We have also
          introduced an event-based delivery system. Anytime a lead qualifies as
          per the required parameter the lead is automatically delivered to your
          CRM or any database you need.
        </div>
      ),
    },
    {
      question: (
        <div>
          Can you help us update our CRM Data with additional or
          <br />
          missing data points?
        </div>
      ),
      answer: (
        <div>
          Yes, we own a proprietary database of more than 180M B2B contacts that
          can be used to enrich your old database. Please reach out to our Sales
          team for more information.
        </div>
      ),
    },
    {
      question: (
        <div>
          What if we need assistance setting-up GA4 or GTM
          <br />
          for conversion tracking and reporting?
        </div>
      ),
      answer: (
        <div>
          We will assist you with this setup. Our team of experts can help you
          set up GA4 and GTM as it is also a part of our setup checklist. As a
          part of our service, we provide reports in Google Data Studio
          templates.
          <br />
          <br /> We have a team of front-end developers who can help you build
          landing pages and we prefer using our in-house team for quick
          turnaround and ease of integration implementation.
        </div>
      ),
    },
    {
      question: (
        <div>
          What is your Sales Intelligence Platform? How can
          <br />
          it help in addition to the lead and demand generation campaigns?
        </div>
      ),
      answer: (
        <div>
          Our Sales Intelligence Platform is a cutting-edge tool designed to
          enhance your lead generation and Account-Based Marketing (ABM)
          efforts.
          <br /> By incorporating this platform into our model, we provide
          deeper insights into your target market's behavior and preferences. It
          identifies and tracks potential customers' digital footprints,
          offering real-time data like company details, pages viewed, and
          engagement levels. This information allows for more personalized and
          effective outreach, ensuring that your campaigns are not just reaching
          more people, but the right people. In essence, our platform acts as a
          smart companion to your lead and demand generation campaigns,
          amplifying their effectiveness and maximizing your return on
          investment.
        </div>
      ),
    },
    {
      question: (
        <div>
          What if we don’t want to enter into a baseline
          <br />
          commitment agreement?
        </div>
      ),
      answer: (
        <div>
          If you're hesitant about entering into a baseline commitment, you
          might negotiate a trial period, a smaller-scale pilot, or a
          pay-as-you-go model. We might offer flexible terms to accommodate your
          needs and demonstrate value before you commit to a larger investment.
          Please contact our Sales for more information.
        </div>
      ),
    },
    // Add more FAQs as needed
  ];

  const containerStyle = {
    paddingTop: "80px",
    paddingBottom: "50px",
  };

  const headingStyle = {
    color: "#101C2D",
    textAlign: "center",
    fontFamily: "Soleil",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "56px",
    maxWidth: "600px", // Add this line to set the maximum width
    margin: "0 auto", // Center the heading
  };

  const headingGradientStyle = {
    background:
      "var(--Gradient, linear-gradient(91deg, #1F86E3 14.24%, #1B3B97 100%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  const contentStyle = {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const contentTextStyle = {
    color: "#626981",
    textAlign: "center",
    fontFamily: "Soleil",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "31px",
    marginTop: "20px", // Adjust as needed
  };

  const mobilecontentTextStyle = {
    color: "#626981",
    textAlign: "left",
    fontFamily: "Soleil",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "25px",
    marginTop: "20px", // Adjust as needed
  };

  const mobileheadingStyle = {
    color: "#101C2D",
    textAlign: "left",
    fontFamily: "Soleil",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "47px",
    maxWidth: "600px", // Add this line to set the maximum width
    margin: "0 auto",
  };

  const DesktopOnly = styled.div`
    @media (max-width: 767px) {
      display: none;
    }
  `;

  const MobileOnly = styled.div`
    @media (min-width: 768px) {
      display: none;
    }
  `;

  return (
    <div>
      <PageTitle title={"Pricing"} />
      <DesktopOnly>
        <NavBar />
      </DesktopOnly>
      <MobileOnly>
        <MobileNavbar />
      </MobileOnly>
      <div style={containerStyle}>
        <DesktopOnly>
          {/* Heading */}
          <h2 style={headingStyle}>
            Open{" "}
            <span style={{ ...headingGradientStyle, ...{ display: "inline" } }}>
              Pricing
            </span>
            , Clear{" "}
            <span style={{ ...headingGradientStyle, ...{ display: "inline" } }}>
              Value
            </span>
            <br /> No Surprises, Just Transparency
          </h2>

          {/* Content Section */}
          <div style={contentStyle}>
            <p style={contentTextStyle}>
              We believe in straightforward, transparent pricing strategies,
              ensuring you understand
              <br /> every aspect of your investment. No hidden fees, no
              surprises—just honest, clear pricing for
              <br /> informed decisions and trustworthy partnerships.
            </p>
          </div>
        </DesktopOnly>

        <MobileOnly>
          {/* Heading */}
          <h2
            style={{
              ...mobileheadingStyle,
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            Open{" "}
            <span style={{ ...headingGradientStyle, ...{ display: "inline" } }}>
              Pricing,
            </span>
            <br />
            Clear{" "}
            <span style={{ ...headingGradientStyle, ...{ display: "inline" } }}>
              Value
            </span>
            <br /> No Surprises, Just Transparency
          </h2>

          {/* Content Section */}
          <div
            style={{
              ...contentStyle,
              textAlign: "left",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <p style={mobilecontentTextStyle}>
              We believe in straightforward, transparent pricing strategies,
              ensuring you understand every aspect of your investment. No hidden
              fees, no surprises—just honest, clear pricing for informed
              decisions and trustworthy partnerships.
            </p>
          </div>
        </MobileOnly>

        {/* Responsive Styles */}
        <style>
          {`
            @media only screen and (max-width: 768px) {
              .pricing-cards-container {
                flex-direction: column;
                align-items: center;
              }

              .pricing-card {
                margin-right: 0;
                margin-bottom: 30px;
              }
            }
          `}
        </style>

        {/* PricingCardNew */}
        <PricingSection>
          <div className="container">
            <div
              className="row"
              style={{ marginLeft: "-12px", marginBottom: "20px" }}
            >
              <PricingCardNew
                index={0}
                heading="Starter Plan 🚀"
                content="Best for a pilot campaign to test our leads quality. Best plan to get started with content syndication"
                price="$38"
                buttonText="Get Started"
                footerHeading="$15k Baseline Commitment"
                options={[
                  "Single or Multi Touch Attribution",
                  "Select your own qualification criteria HQL, BANT",
                  "ABM",
                  "Firmographics Targeting",
                  "Technographic Targeting",
                  "Daily Reporting",
                  "Dedicated Account Manager",
                ]}
                linkTo="/Contact-Us"
              />

              <PricingCardNew
                index={1}
                heading="Growth Plan 📈"
                content="This package is best if you are looking to generate leads using multiple targeting options. Leverage our data driven insights to optimise and customise your campaigns."
                price="$34"
                buttonText="Get Started"
                footerHeading="$75k Baseline Commitment"
                options={[
                  "Single or Multi Touch Attribution",
                  "Select your own qualification criteria HQL, BANT",
                  "ABM",
                  "Firmographics Targeting",
                  "Technographic Targeting",
                  "Intent Based Targeting",
                  "Behavioural Data Report (Behaviour Intel for each touch point)",
                  "Programmatic Display (Retargeting Only)",
                  "Dedicated Account Manager",
                  "Real Time Daily Reporting",
                  "Lead Delivery via API or event based Webhook",
                ]}
                linkTo="/Contact-Us"
              />

              <PricingCardNew
                index={2}
                heading="Enterprise Plan 💼"
                content="This package is best for companies looking to optimise their complete marketing strategy. We will work with your internal marketing team and help optimise your complete marketing strategy. Our Marketing experts will consult and help implement the best strategies."
                price="$30"
                buttonText="Get Started"
                footerHeading="$125k Baseline Commitment"
                options={[
                  "Single or Multi Touch Attribution",
                  "Select your own qualification criteria HQL, BANT",
                  "ABM",
                  "Firmographics Targeting",
                  "Technographic Targeting",
                  "Intent Based Targeting",
                  "Behavioural Data Report (Behaviour Intel for each touch point)",
                  "Programmatic Display (Retargeting Only)",
                  "Dedicated Account Manager",
                  "Real Time Daily Reporting",
                  "Lead Delivery via API or event based Webhook",
                  "Full Funnel Strategy",
                  "Access to our Private database - Connect your CRM to update or append your CRM data",
                  "Access to our Sales Intelligence Platform for Full Funnel Campaign Analytics",
                  "End to End Tech Assistance (Tracking Setup, Reporting, GA and GTM Setup)",
                ]}
                linkTo="/Contact-Us"
              />
            </div>
          </div>
        </PricingSection>

        {/* FAQs */}
        <div>
          <FrequentlyAskedQuestion
            heading={homePageHeading}
            additionalContent={homePageAdditionalContent}
            faqData={homePageFaqData}
          />
        </div>
      </div>
      <DesktopOnly>
        <ResponsiveFooter customBackgroundColor={true} />
      </DesktopOnly>
      <MobileOnly>
        <MobileFooter />
      </MobileOnly>
    </div>
  );
};

export default Pricing;
