import React, { useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import "./Testimonial.css"; // Import your CSS file for styling
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const Rating = () => (
  <span role="img" aria-label="star" style={{ color: "yellow" }}>
    ⭐⭐⭐⭐⭐
  </span>
);

const HeadingText = styled.h1`
  color: #2a3342;
  font-family: Soleil;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: -2px;
`;

const SubHeadingText = styled.span`
  opacity: 0.9913;
  color: #2a3342;
  font-family: Soleil;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: -2px;
`;

const TestimonialImage = styled.div`
  width: 37px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 50%;
  background: url("${(props) => props.image}") center/cover no-repeat, lightgray;
`;

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorName = styled.p`
  margin-left: 10px; // Adjust as needed
  margin-bottom: -4px;
`;

const DesktopOnlyContainer = styled.div`

  @media (max-width: 768px) {
    display: none;
  }
`;

function HorizontalScrollingTestimonials() {
  const scrollRef = useRef();
  const [scrollDirection, setScrollDirection] = useState("vertical");
  const [initialSpace, setInitialSpace] = useState(100); // Adjust as needed
  const [scrollPosition, setScrollPosition] = useState(0);

  const [{ scroll }, set] = useSpring(() => ({ scroll: 0 }));

  const handleWheel = (e) => {
    e.preventDefault();

    const deltaY = e.deltaY;
    const container = scrollRef.current;
    const containerSize = container.clientWidth;

    if (scrollDirection === "vertical") {
      const newScroll = Math.min(
        Math.max(scrollPosition + deltaY, 0),
        container.scrollHeight - container.clientHeight
      );

      setScrollPosition(newScroll);
      set({
        scroll: newScroll,
      });

      if (scrollPosition + containerSize >= container.scrollHeight) {
        setScrollDirection("horizontal");
        setInitialSpace(0);
      }
    } else if (scrollDirection === "horizontal") {
      const newScroll = Math.min(
        Math.max(scrollPosition + deltaY, 0),
        container.scrollWidth - containerSize
      );

      setScrollPosition(newScroll);
      set({
        scroll: newScroll,
      });

      if (scrollPosition + containerSize >= container.scrollWidth) {
        setScrollDirection("vertical");
        setInitialSpace(0);
      }
    }
  };

  const handleLeftArrowClick = () => {
    const container = scrollRef.current;
    const containerSize = container.clientWidth;

    const newScroll = Math.max(scrollPosition - containerSize, 0);

    setScrollPosition(newScroll);
    set({
      scroll: newScroll,
    });
  };

  const handleRightArrowClick = () => {
    const container = scrollRef.current;
    const containerSize = container.clientWidth;

    const newScroll = Math.min(
      scrollPosition + containerSize,
      scrollDirection === "vertical"
        ? container.scrollHeight - container.clientHeight
        : container.scrollWidth - containerSize
    );

    setScrollPosition(newScroll);
    set({
      scroll: newScroll,
    });
  };

  return (
    <DesktopOnlyContainer>
      {/* Heading */}
      <div className="container testimonial-heading-container mb-5 mt-5">
        <div
          className="arrow-icon"
          style={{
            background: "rgba(234, 234, 234, 0.60)",
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "42px",
          }}
        >
          <FaArrowLeft
            onClick={handleLeftArrowClick}
            style={{
              width: "24px",
              height: "24px",
              fill: "#4b5563",
              flexShrink: 0,
            }}
          />
        </div>
        <HeadingText>
          What Our <SubHeadingText>Clients Say</SubHeadingText>
        </HeadingText>
        <div
          className="arrow-icon"
          style={{
            background: "rgba(234, 234, 234, 0.60)",
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "42px",
          }}
        >
          <FaArrowRight
            onClick={handleRightArrowClick}
            style={{
              width: "24px",
              height: "24px",
              fill: "#4b5563",
              flexShrink: 0,
            }}
          />
        </div>
      </div>
      <div
        className="horizontal-scroll-container"
        ref={scrollRef}
        style={{
          marginLeft: initialSpace,
          overflow: "hidden",
          position: "relative", // Added for proper stacking
        }}
        onWheel={handleWheel}
      >
        <animated.div
          className="testimonials-container"
          style={{
            transform:
              scrollDirection === "vertical"
                ? scroll.interpolate((s) => `translateY(${-s}px)`)
                : scroll.interpolate((s) => `translateX(${-s}px)`),
          }}
        >
          {/* Testimonial 1 */}
          {/* Add more testimonials as needed */}
          <div className="testimonial">
            <div className="heading">
              <p>
                Unlock B2B Brilliance: Intentbuy's Analytics
                <br /> Game-Changer!
              </p>
            </div>
            <div className="content">
              <p>
                Intentbuy revolutionized our B2B analytics—a game-changer!
                <br /> From filtering marketing actions to identifying traffic
                by name
                <br /> and tracking changes on the fly, it's the secret sauce
                for elevating
                <br />
                your B2B marketing!<br />
              </p>
            </div>
            <div className="author">
              <AuthorContainer>
                <TestimonialImage image="/assets/images/home-page/test-2.webp" />
                <AuthorName>Joseph</AuthorName>
              </AuthorContainer>
              <div className="rating">
                <Rating />
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="heading">
              <p>
                Intentbuy: Smart Scoring, Tailored Insights,
                <br /> Targeted Success!
              </p>
            </div>
            <div className="content">
              <p>
                Intentbuy transformed our lead management! Seamless CRM<br />
                integration streamlines our workflow. Defining our ideal
                customer<br /> profile and segmenting visitors with industry filters
                is a game-<br />changer. Highly recommended for a seamless and
                targeted lead<br /> generation experience!
              </p>
            </div>
            <div className="author">
              <AuthorContainer>
                <TestimonialImage image="/assets/images/home-page/test-3.webp" />
                <AuthorName>Marsha H.</AuthorName>
              </AuthorContainer>
              <div className="rating">
                <Rating />
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="heading">
              <p>High-quality data at a great price.</p>
            </div>
            <div className="content">
              <p>
                Very good data quality compared to price. Customer support is
                <br />
                very responsive and is always ready to go the extra mile to{" "}
                <br />
                help solve a challenge. It is a developing software solution
                that
                <br />
                launches new features frequently and is always open for
                <br /> feedback.
              </p>
            </div>
            <div className="author">
              <AuthorContainer>
                <TestimonialImage image="/assets/images/home-page/Test-1.webp" />
                <AuthorName>Germans F.</AuthorName>
              </AuthorContainer>
              <div className="rating">
                <Rating />
              </div>
            </div>
          </div>
        </animated.div>
        {/* Add more testimonials as needed */}
      </div>
    </DesktopOnlyContainer>
  );
}

export default HorizontalScrollingTestimonials;
