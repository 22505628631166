// DesktopThirdSection.js
import React from "react";
import styled from "styled-components";
import MiniThirdSection from "./MinithirdSection";

const VideoSectionContainer = styled.div`
  margin: 50px auto;
  position: relative;
  overflow: hidden;
  border-radius: 24px !important;
  display: flex;
  justify-content: center; /* Center horizontally */
`;

const ThirdSectionContainer = styled.div`
  background: url("assets/images/home-page/third-section.webp") center/cover;
  padding-top: 200px;
  padding-bottom: 80px;
  margin-top: -200px; /* Add margin-top to adjust position */
`;

const FeatureImage = styled.img`
  height: 197.862px;
  flex-shrink: 0;
`;

const FeatureColumn = styled.div`
  text-align: center;
  margin-top: -35px;
`;



const FeatureText = styled.span`
  position: static;
  z-index: 1;
  color: #FFF !important;
  text-align: center;
  font-family: Soleil;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  display: block; /* Add display: block */
`;

const Heading = styled.h2`
  color: #fff;
  text-align: center;
  font-family: Soleil;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -1.6px;
`;

const NewFeatureBadge = styled.div`
  width: 100px;
  height: 30px;
  background: url('assets/images/home-page/Features.png') center/cover;
  background-size: 70.264px 13px; /* Set the width and height */
  background-repeat: no-repeat;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.2) inset;
  flex-shrink: 0;
`;



const EllipseShape = styled.div`
  height: 290.927px;
  border-radius: 50%;
  background: rgba(40, 139, 255, 0.4);
  filter: blur(120.25px);
  position: absolute;
  top: calc(50% - 145px); /* Half of the height */
  left: calc(50% - 292.699px); /* Half of the width */
  z-index: -41; /* Move the ellipse behind the video */
`;

const DesktopThirdSection = () => {
  return (
    <>
      <VideoSectionContainer className="container">
        <iframe
          title="Embedded YouTube Video"
          width="1097"
          height="630"
          src="https://www.youtube.com/embed/9WkT4yE-qh0?si=Xg3OdHFpKwGLVzKH&rel=0"
          frameBorder="0"
          allowFullScreen
          style={{ border: "3px solid #fff", borderRadius: "20px" }}
        ></iframe>
      </VideoSectionContainer>

      <EllipseShape />

      <ThirdSectionContainer>
        <div className="container-fluid mt-5">
          <div className="row justify-content-center align-items-center">
            {/* First column */}
            <div className="col-lg-3 col-md-6">
              <FeatureImage
                src="/assets/images/third-section-left.webp"
                alt="Feature 1"
                className="img-fluid"
              />
            </div>

            {/* Second column */}
            <div className="col-lg-5 col-md-6">
              <FeatureColumn>
                <NewFeatureBadge className="badge  p-2">
                  <FeatureText ></FeatureText>
                </NewFeatureBadge>
                <Heading>Here's how we stand out</Heading>
              </FeatureColumn>
            </div>

            {/* Third column */}
            <div className="col-lg-3 col-md-6 d-flex justify-content-end">
              <FeatureImage
                src="/assets/images/third-section-right.webp"
                alt="Feature 2"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </ThirdSectionContainer>
      <MiniThirdSection />
    </>
  );
};

export default DesktopThirdSection;
