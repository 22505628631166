// Home.js
import React from "react";
import Banner from "./component/Banner";
import LeadScoringSecond from "./component/lead-scoring-second";
import FrequentlyAskedQuestion from "./component/FrequentlyAskedQuestion";
import styled from "styled-components";
import LeadFourth from "./component/LeadScoringFourth";
import PageTitle from "./PageTitle";
import ResponsiveFooter from "./component/footer";
import MobileSection from "./component/Mobile-Component/Mobile-Lead-Scoring-Fourth";
import MobileFooter from "./component/Mobile-Component/MobileFooter";


function LeadScoring() {
  // Define the content for Frequently Asked Questions on the home page
  const homePageHeading = "Frequently Asked Questions";
  const homePageAdditionalContent = (
    <>
      <p>
        Our platform is engineered to transform your
        <br /> website's anonymous traffic into a goldmine
        <br /> of qualified leads
      </p>
      {/* Add more lines as needed */}
    </>
  );
  const homePageFaqData = [
    {
      question: "What is Visitor Tracking and IP Append?",
      answer:
        "IP append is the process by which website visitors are identified for marketing, prospecting, and lead nurturing. When visitors come to your website, we assign them a unique Identifying code that allows us to track repeat visits. Specifically, we can tell you which companies are visiting your website, which pages they’re viewing, and which sources directed them there. On your end, all you have to do to start uncovering visitors is add our tracking script to your website.",
    },
    {
      question: "How does Visitor Tracking and IP Append work?",
      answer:
        "When a visitor clicks on your website, we assign them a unique Identifying code. This allows us to track repeat visits. Specifically, we can tell you which companies are visiting your website, which pages they’re viewing, and which sources directed them there. On your end, all you have to do to start uncovering visitors is add our tracking script to your website.",
    },
    {
      question: "How does Visitor Tracking and IP Append benefit salespeople?",
      answer:
        "This helps uncover the warm leads that you already have! What is a warm lead? A warm lead is a business or individual who has shown interest in your business but has not decided to start doing business with you yet. These leads are easier to convert than cold leads because they are already familiar with your business and have started looking at your site. Some of them may even understand the value of your products without you having to explain it to them. Consequently, they are more likely to become customers than other leads.",
    },
    {
      question:
        "How does Visitor Tracking and IP Append benefit marketing departments?",
      answer:
        "Visitor Tracking is a form of sales intelligence that identifies companies that are actively researching your product on your site. These companies are exhibiting intent signals. In other words, they are indicating their intention to purchase a product or service.  It’s worth noting that a buyer will likely select the vendor that makes first contact. That’s why identifying those warm leads visiting your website and knowing who they are and where they came from is so important to your marketing team. This will help them understand which marketing plans are sending leads directly to your site and which are not.",
    },
  ];

  const DesktopOnly = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const SectionContainer = styled.div`
  height: 330px;
  flex-shrink: 0;
  background: url("assets/images/home-page/before-footer.webp") center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    height: 203.841px;
    flex-shrink: 0;
    background: url("assets/images/lead-scoring/mobile-third-bg.webp") center/cover;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  font-family: "Soleil", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: 880.399px;
    height: 160px;
    flex-shrink: 0;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -0.72px;
  }
`;

  const containerStyle = {
    paddingTop: "30px",
    marginBottom: "50px",
    opacity:"0 !important",
  };

 

  return (
    <div>
      <PageTitle title={"Lead Scoring"} />
      <Banner transparent={true} whiteText={true} customTextColor="#000" />
      <LeadScoringSecond />
      {/* Desktop SectionContainer */}
      <DesktopOnly>
      <SectionContainer>
        <TextContainer>
          Intentbuy is designed to keep your lead management
          process organized and efficient, ensuring a smooth
          transition of leads through your sales pipeline.
        </TextContainer>
      </SectionContainer>
      </DesktopOnly>
      {/* Mobile SectionContainer */}
      <MobileOnly>
      <SectionContainer className="mobile-version">
        <TextContainer className="mobile-version">
          Intentbuy is designed to keep your lead<br /> management
          process organized and<br /> efficient, ensuring a smooth
          transition of<br /> leads through your sales pipeline.
        </TextContainer>
      </SectionContainer>
      </MobileOnly>
      <LeadFourth />
      <MobileSection />
      <div style={containerStyle}>
        <FrequentlyAskedQuestion
          heading={homePageHeading}
          additionalContent={homePageAdditionalContent}
          faqData={homePageFaqData}
          
        />
       
      </div>
      <DesktopOnly>
      <ResponsiveFooter customBackgroundColor={true} />
      </DesktopOnly>
      <MobileOnly>
      <div style={{backgroundColor:"white",height:"10px"}}>jj</div>
        <MobileFooter />
      </MobileOnly>
    </div>
  );
}

export default LeadScoring;