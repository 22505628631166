// SemalessMobileVideoComponent.js
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

const SeamlessMobileVideoContainer = styled.div`
  background: url('/assets/images/seamless-integration-mobile-3.webp') center/cover;
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image */
  padding-top: 50px; /* Add padding to the top */
`;





const SeamlessMobileVideoComponent = () => {
  return (
    <React.Fragment>
    <SeamlessMobileVideoContainer>
      <Container fluid style={{ padding: 0 }}>
        {/* First Row - Video */}
        <Row className="mb-0">
          <Col xs={12}>
            {/* Replace 'your_video_url' with the actual URL of your video */}
            <iframe
              title="video"
              width="100%" // Adjust the width to fill the container
              height="196.641px"
              src="https://www.youtube.com/embed/9WkT4yE-qh0?si=Xg3OdHFpKwGLVzKH&rel=0"
              frameBorder="0"
              style={{
                borderRadius: "13.497px",
                border: "0.562px solid #E5E8EF",
                flexShrink: 0,
              }}
              allowFullScreen
            ></iframe>
          </Col>
        </Row>

        {/* Add space between rows */}
        <div style={{ padding: "30px" }} />

        {/* Second Row - Heading and Content */}
        <Row>
          <Col xs={12}>
            <h2
              className="text-center"
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "23px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "32px",
                letterSpacing: "-0.46px",
                paddingBottom: "20px",
              }}
            >
              Unleashing Advanced Capabilities of IntentBuy
            </h2>
            <p
              className="text-center"
              style={{
                color: "#BBB",
                textAlign: "center",
                fontFamily: "Soleil",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "23px",
                marginTop:"-10px",
                paddingBottom:"10px"
              }}
            >
              By effortlessly incorporating Intentbuy into your tech stack, you empower your marketing team with advanced tools and features.
            </p>
          </Col>
        </Row>
      </Container>
    </SeamlessMobileVideoContainer>
    
    </React.Fragment>
  );
};

export default SeamlessMobileVideoComponent;
