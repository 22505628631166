import React from "react";
import styled from "styled-components";
import NavBar from "./component/Navbar";
import ResponsiveFooter from "./component/footer";
import MobileFooter from "./component/Mobile-Component/MobileFooter";
import MobileContactUs from "./component/Mobile-Component/MobileContactUs";
import MobileNavbar from "./component/Mobile-Component/MobileNavbar";

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -180px; /* Add margin-bottom as needed */
`;

const FooterContainerContact = styled.footer`
  background: url("assets/images/Contact-us/footer-bg.webp") center
      bottom/contain no-repeat,
    #000;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContactContainer = styled.div`
  background-image: url("assets/images/Contact-us/contact-us-background.webp"); /* Replace 'path/to/your/image.jpg' with the actual path to your image */
  background-size: cover; /* Adjust the background-size property as needed */
  background-position: center; /* Adjust the background-position property as needed */
  min-height: 100vh; /* Ensure the container takes at least the full height of the viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactForm = styled.form`
  width: 1036.625px;
  height: 542.04px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  padding: 80px;
  border: 1px solid #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-top: 100px;
  z-index: 1;
`;

const FormRow = styled.div`
  display: flex;
  margin-bottom: 15px;

  &:not(:last-child) {
    margin-bottom: 70px;
  }
`;

const FormColumn = styled.div`
  flex: 1;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

const InputField = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #828282;
  outline: none;
  color: #7d7b7b;
  font-family: Soleil;
  font-size: 17.76px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.24px;
  padding-bottom: 15px;
`;

const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CheckboxColumn = styled(ColumnContainer)`
  align-items: flex-start;
  margin-bottom: 15px;
  display: inline-block;

  input {
    width: auto;
    margin-right: 8px;
  }

  label {
    margin-left: 8px;
  }
`;

const SubmitButtonColumn = styled(ColumnContainer)`
  align-items: flex-end;
`;

const SubmitButton = styled.button`
  color: #fff;
  text-align: center;
  font-family: Soleil;
  font-size: 17.943px;
  font-style: normal;
  font-weight: 700;
  line-height: 15.943px;
  border: 4px solid white;
  border-radius: 46.477px;
  background: var(
    --Gradient,
    linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%)
  );
  outline: solid var(--Gradient, #1f86e3) 1.162px;
  padding: 14.267px 49.048px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;

  &::after {
    content: "›";
    width: 5.81px;
    height: 9.295px;
    flex-shrink: 0;
    color: #ffffff;
    margin-left: 5px;
    z-index: 2;
  }
`;

const Heading = styled.h1`
  text-align: center;
  font-family: Soleil;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 85px;
  letter-spacing: -1.8px;
  opacity: 0.9913;
  background: var(
    --Gradient,
    linear-gradient(91deg, #1f86e3 14.24%, #1b3b97 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 50px;
`;

const Subheading = styled.p`
  color: #343434;
  text-align: center;
  font-family: Soleil;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 39px;
  margin-bottom: 105px;
`;

const DesktopOnly = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const ContactUsPage = () => {
  const yourFooterStyle = {
    paddingTop: "210px", // Adjust the value based on your requirement
    backgroundImage: "url('/your-image-path.jpg')", // Update with your image path
    backgroundSize: "cover",
    backgroundPosition: "center right",
  };

  return (
    <>
      <MobileOnly>
        <MobileNavbar />
      </MobileOnly>
      <DesktopOnly>
        <NavBar />
      </DesktopOnly>
      <MobileOnly>
        <MobileContactUs />
      </MobileOnly>
      <DesktopOnly>
        <ContactContainer className="contact-us-container">
          {/* Heading Section */}
          <Heading>Contact Us</Heading>
          <Subheading>
            Get in Touch to Experience the Future of Business Analytics with
            IntentBuy
          </Subheading>

          {/* Form Section */}
          <FormContainer>
            <ContactForm>
              {/* First Row */}
              <FormRow>
                <FormColumn>
                  <InputField type="text" placeholder="First Name" />
                </FormColumn>
                <FormColumn>
                  <InputField type="text" placeholder="Last Name" />
                </FormColumn>
              </FormRow>

              {/* Second Row */}
              <FormRow>
                <FormColumn>
                  <InputField type="text" placeholder="Email Id" />
                </FormColumn>
                <FormColumn>
                  <InputField type="text" placeholder="Organization Name" />
                </FormColumn>
              </FormRow>

              {/* Third Row */}
              <FormRow>
                <FormColumn>
                  <InputField type="text" placeholder="Company Size" />
                </FormColumn>
              </FormRow>

              {/* Fourth Row */}
              <FormRow>
                <CheckboxColumn>
                  <InputField type="checkbox" id="termsConditions" />
                  <label htmlFor="termsConditions">
                    Terms & Conditions and Apply*
                  </label>
                </CheckboxColumn>
                <SubmitButtonColumn>
                  <SubmitButton type="submit">Submit</SubmitButton>
                </SubmitButtonColumn>
              </FormRow>
            </ContactForm>
          </FormContainer>
        </ContactContainer>
      </DesktopOnly>
      <MobileOnly>
        <MobileFooter footerStyle={yourFooterStyle} />
      </MobileOnly>
      <DesktopOnly>
        <ResponsiveFooter
          customBackgroundColor={false}
          FooterContainer={FooterContainerContact}
        />
      </DesktopOnly>
    </>
  );
};

export default ContactUsPage;
