import React from "react";
import YouTube from "react-youtube";
import styled from "styled-components";

const MobileAnalyticsSection = ({ onRowClick }) => {
  const opts = {
    
   width: "400px",
    height: "253px",
 
    // top:"0px !important"
    // Adjust the width for mobile view
  };

  const headingStyles = {
    color: "var(--Gray-900, #2A3342)",
    fontFamily: "Soleil",
    fontSize: "27.122px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "25.526px",
    letterSpacing: "-0.814px",
  };

  const iconStyles = {
    width: "44.671px",
    height: "44.671px",
    flexShrink: 0,
    marginRight: "10px", // Add margin-right for the desired spacing
  };

  const columnStyles = {
    width: "100%",
    paddingLeft: "10px", // Add left padding
    paddingRight: "10px", // Add right padding
  };

  const StyledParagraph = styled.p`
    color: #626981;
    font-family: Soleil;
    font-size: 14.359px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.133px;
  `;

  const StyledVideoContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
  `;

  const StyledVideo = styled(YouTube)`
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
  `;

  const StyledVideoOverlay = styled.div`
    position: relative;
    top: 50%;
    left: 0;
    width: 100%;
    background-color: black;
    z-index: 1;
  `;

  const handleRowClick = (sectionNumber, description, startTime) => {
    console.log(`Clicked on section ${sectionNumber}: ${description}`);
    // Use the YouTube API to seek to the specified start time
    const player = window.youtubePlayer; // Assuming you have access to the YouTube player instance
    player.seekTo(startTime);
    player.playVideo();
  };

  return (
    <div className="mobile-analytics-section" style={{}}>
      {/* Video container */}
      <StyledVideoContainer >
      
        <StyledVideo 
       
          videoId="9WkT4yE-qh0"
          opts={opts}
          onReady={(event) => (window.youtubePlayer = event.target)}
        />
        <StyledVideoOverlay />
      </StyledVideoContainer>
      {/* First section */}
      <div
        className="column"
        style={{
          ...columnStyles,
          paddingTop: "40px",
          paddingBottom: "30px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        onClick={() => handleRowClick(1, "Analyze", 1)}
      >
        {/* Image and Heading in the same row */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Image icon */}
          <img
            src="assets/images/analytics/analytics-3.1.webp"
            alt="Analyze Icon"
            style={iconStyles}
          />

          <h3 style={headingStyles}>Analyze</h3>
        </div>
        <div style={{ marginTop: "10px" }}></div>
        <StyledParagraph>
          Filter paid and organic marketing actions by the amount of target
          company traffic they drive, so you can cut costs with confidence and
          double down on winners.
        </StyledParagraph>
      </div>

      {/* Second section */}
      <div
        className="column"
        style={{
          ...columnStyles,
          paddingTop: "40px",
          paddingBottom: "30px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        onClick={() => handleRowClick(27, "Enrich", 27)}
      >
        {/* Image and Heading in the same row */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Image icon */}
          <img
            src="assets/images/analytics/analytics-3.1.webp"
            alt="Analyze Icon"
            style={iconStyles}
          />

          <h3 style={headingStyles}>Enrich</h3>
        </div>
        <div style={{ marginTop: "10px" }}></div>
        <StyledParagraph>
          <b>Know your traffic by name.</b> <br />
          Numbers without context don’t tell much of a story, Intentbuy adds
          vital context to Google Analytics by enriching the numbers with
          company identifications. So you can spend less and convert more.
        </StyledParagraph>
      </div>

      {/* Third section */}
      <div
        className="column"
        style={{
          ...columnStyles,
          paddingTop: "40px",
          paddingBottom: "30px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        onClick={() => handleRowClick(40, "Optimize", 40)}
      >
        {/* Image and Heading in the same row */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Image icon */}
          <img
            src="assets/images/analytics/analytics-3.1.webp"
            alt="Analyze Icon"
            style={iconStyles}
          />

          <h3 style={headingStyles}>Optimize</h3>
        </div>
        <div style={{ marginTop: "10px" }}></div>
        <StyledParagraph>
          Track the changes you make without needing to wait for conversions, so
          you can test more with less risk and make sure you hit your number
          within budget.
        </StyledParagraph>
      </div>
    </div>
  );
};

export default MobileAnalyticsSection;
