import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import styled, { css } from "styled-components";

const commonStyles = css`
  font-family: "Soleil";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;
const CustomButton = styled(Button)`
  display: inline-flex;
  margin-right: 25px;
  height: 36.207px;
  padding: 12.104px 36.845px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 31.138px;
  border: 2px solid var(--Gradient, #1f86e3);
  background-color: ${({ transparent }) =>
    transparent ? "transparent" : "white"};
  color: ${({ transparent, customTextColor }) =>
    transparent ? "white" : customTextColor || "#10202D"}; /* Updated color value */
 
    font-family: "Soleil";
    font-size: 15.967px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
      background-color: ${({ transparent }) =>
        transparent ? "transparent" : "white"};
      color: ${({ transparent, customTextColor }) =>
        transparent ? (customTextColor || "white") : "#10202D"} !important; /* Updated color value */
    }

  /* Remove hover effect on the whole button */
  &:hover {
    background-color: transparent;
    color: white;
  }

  /* Remove hover effect specifically on the text */
  &:hover span {
    color: #10202D; /* Updated color value */
  }
`;


const CustomNavLink = styled(Nav.Link)`
  ${commonStyles}
  color: ${({ transparent }) => (transparent ? "white" : "#000")};
  &:hover {
    color: ${({ transparent }) => (transparent ? "white" : "#000")};
    text-decoration: none;
  }
`;

const WhiteTextNavLink = styled(CustomNavLink)`
  color: white;
  &:hover {
    color: white;
    text-decoration: none;
  }
`;

const LogoLink = styled.a`
  /* Add any additional styling for the logo link */
  text-decoration: none; /* Optional: Remove underline */
`;

const LogoImage = styled.img`
  max-width: 147.998px;
  max-height: 50px;
  margin-left: 50px;
`;

function NavBar({
  transparent,
  whiteText,
  customBackgroundColor,
  customTextColor,
  backgroundColor,
}) {
  return (
    <Navbar
      bg={transparent ? "transparent" : backgroundColor || "white"}
      expand="lg"
    >
      <Container fluid>
        {/* Logo on the left */}
        <Navbar.Brand>
          <LogoLink href="https://intentbuy.io/">
            <LogoImage
              src={
                transparent
                  ? "/assets/images/logo-transparent.webp"
                  : "/assets/images/logo.webp"
              }
              alt="Intentbuy"
              transparent={transparent}
            />
          </LogoLink>
        </Navbar.Brand>

        {/* Centered menu */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <Nav>
            {whiteText ? (
              <>
                <WhiteTextNavLink href="/">Home</WhiteTextNavLink>
                <WhiteTextNavLink href="/real-time">
                  Real Time Visitor Identification
                </WhiteTextNavLink>
                <WhiteTextNavLink href="/lead-scoring">
                  Lead Scoring
                </WhiteTextNavLink>
                <WhiteTextNavLink href="/analytics">
                  GA Enrichment
                </WhiteTextNavLink>
                <WhiteTextNavLink href="/Seamless-Integration">
                  Seamless Integration
                </WhiteTextNavLink>
                <WhiteTextNavLink href="/Pricing">Pricing</WhiteTextNavLink>
              </>
            ) : (
              <>
                <CustomNavLink href="/">Home</CustomNavLink>
                <CustomNavLink href="/real-time">
                  Real Time Visitor Identification
                </CustomNavLink>
                <CustomNavLink href="/lead-scoring">Lead Scoring</CustomNavLink>
                <CustomNavLink href="/analytics">GA Enrichment</CustomNavLink>
                <CustomNavLink href="/Seamless-Integration">
                  Seamless Integration
                </CustomNavLink>
                <CustomNavLink href="/Pricing">Pricing</CustomNavLink>
              </>
            )}
          </Nav>
        </Navbar.Collapse>

        {/* Styled "Contact Us" button on the right */}
        <CustomButton
          variant="primary"
          transparent={transparent}
          customBackgroundColor={customBackgroundColor}
          customTextColor={customTextColor}
          href="/Contact-Us"
        >
          Contact Us
        </CustomButton>
      </Container>
    </Navbar>
  );
}

export default NavBar;
