import React from "react";
import styled, { keyframes } from "styled-components";

const reverseMarqueeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`;

const ContainerWithPaddingTop = styled.div`
  padding-top: 10px; // Adjust the value as needed
`;

const MarqueeContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  margin-top: ${(props) => (props.reverse ? "10px" : "20px")};
  margin-bottom: ${(props) => (props.reverse ? "20px" : "10px")};
`;

const MarqueeImage = styled.img`
  display: inline-block;
  margin-right: 20px;
  width: 263.762px;
  height: 93.724px;
`;

const MarqueeRow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")}; // Change direction for the second row
`;

const AnimatedMarqueeImage = styled(MarqueeImage)`
  animation: ${reverseMarqueeAnimation} ${(props) => props.duration || 20}s linear
    infinite;
  animation-delay: ${(props) => props.delay || 0}s;
`;

const ReverseImageMarquee = ({ images, delay, duration }) => {
  const loopedImages = [...images, ...images, ...images];
  const firstRowImages = loopedImages.slice(0, loopedImages.length);

  return (
    <>
      <ContainerWithPaddingTop>
        <MarqueeRow reverse>
          <MarqueeContainer reverse>
            {firstRowImages.map((imageUrl, index) => (
              <AnimatedMarqueeImage
                key={index}
                src={imageUrl}
                alt={`Image ${index + 1}`}
                delay={index * 0.1} // Adjust delay as needed
                duration={duration}
              />
            ))}
          </MarqueeContainer>
        </MarqueeRow>
        {/* <MarqueeRow>
          <MarqueeContainer>
            {firstRowImages.reverse().map((imageUrl, index) => (
              <AnimatedMarqueeImage
                key={index}
                src={imageUrl}
                alt={`Image ${index + 1}`}
                delay={index * 0.1} // Adjust delay as needed
                duration={duration}
              />
            ))}
          </MarqueeContainer>
        </MarqueeRow> */}
      </ContainerWithPaddingTop>
    </>
  );
};

const ReverseImageMarqueeExample = () => {
  const images = [
    "/assets/images/Seamless Integration/logo1.webp",
    "/assets/images/Seamless Integration/logo2.webp",
    "/assets/images/Seamless Integration/logo3.webp",
    "/assets/images/Seamless Integration/logo4.webp",
    "/assets/images/Seamless Integration/logo1.webp",
    "/assets/images/Seamless Integration/logo2.webp",
    "/assets/images/Seamless Integration/logo3.webp",
    "/assets/images/Seamless Integration/logo4.webp",
  ];

  return (
    <div>
      <ReverseImageMarquee images={images} delay={0.8} duration={20} />
    </div>
  );
};

export default ReverseImageMarqueeExample;
