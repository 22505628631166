// SeamlessIntegration.js
import React from "react";
import PageTitle from "./PageTitle";
import Banner from "./component/banner-seamless-integration";
import styled from "styled-components";
import FrequentlyAskedQuestion from "./component/FrequentlyAskedQuestion";
import CustomSection from "./component/CustomSection";
import ResponsiveFooter from "./component/footer";
import MobileBannerSeamlessIntegration from "./component/Mobile-Component/MobileBannerSeamlessIntegration";
import MobileSectionComponent from "./component/Mobile-Component/Seamless-Integration-Mobile-second";
import SeamlessMobileVideoComponent from "./component/Mobile-Component/SemalessMobileVideoComponent";
import BeforeFooterMobile from "./component/Mobile-Component/SeamlessIntegartionFooter";

import MobileFooter from "./component/Mobile-Component/MobileFooter";

const DesktopOnly = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
    backgroundImage:url("")
  }
`;

const PageContainer = styled.div`
  background-color: #101c2d; /* Set the background color */
`;

const SectionContainer = styled.div`
  margin-top: 420px; /* Add margin-top to create space between sections */
`;

const Heading = styled.h2`
  color: #fff;
  font-family: Soleil;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 53.5px;
  letter-spacing: -1px;
  margin-bottom: 10px;
`;

const Content = styled.p`
  color: #bbb;
  font-family: Soleil;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  margin-bottom: 20px;
`;

const RightListBox = styled.div`
  margin-top: 20px;
  margin-left: 36px;
`;

const ListBoxItem = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-family: Soleil;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
`;

const CheckmarkIcon = styled.div`
  font-size: 20px;
  margin-right: 20px;
  width: 30.044px;
  height: 30.044px;
  flex-shrink: 0;
  border-radius: 2.817px;
  background: linear-gradient(
    45deg,
    hsla(208, 78%, 51%, 1) 0%,
    hsla(225, 70%, 35%, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

const InnerCheckmark = styled.div`
  font-size: 16px;
  color: #fff;
`;

const ResponsiveSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px; /* Add your desired margin-bottom value */

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const LeftContent = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 48%;
    margin-right: 4%; /* Adjusted margin */
  }
`;

// New styles for video and content
const VideoContainer = styled.div`
  width: 566px;
  height: 425px;
  flex-shrink: 0;
  border-radius: 24px;
  background: linear-gradient(97deg, #809ff0 0%, #4c62c5 100%);
  overflow: hidden;
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const RightContentContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 48%;
    margin-left: 6%; /* Adjusted margin */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const RightHeading = styled.h2`
  color: #fff;
  font-family: Soleil;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 53.5px;
  letter-spacing: -0.8px;
`;

const RightContent = styled.p`
  color: #bbb;
  font-family: Soleil;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
`;



const SeamlessIntegration = () => {
  const homePageHeading = "Frequently Asked Questions";
  const homePageAdditionalContent = (
    <>
        Our platform is engineered to transform your
        <br />
        website's anonymous traffic into a goldmine
        <br />
        of qualified leads
      {/* Add more lines as needed */}
    </>
  );
  const homePageFaqData = [
    {
      question: "What is Visitor Tracking and IP Append?",
      answer:
        "IP append is the process by which website visitors are identified for marketing, prospecting, and lead nurturing. When visitors come to your website, we assign them a unique Identifying code that allows us to track repeat visits. Specifically, we can tell you which companies are visiting your website, which pages they’re viewing, and which sources directed them there. On your end, all you have to do to start uncovering visitors is add our tracking script to your website.",
    },
    {
      question: "How does Visitor Tracking and IP Append work?",
      answer:
        "When a visitor clicks on your website, we assign them a unique Identifying code. This allows us to track repeat visits. Specifically, we can tell you which companies are visiting your website, which pages they’re viewing, and which sources directed them there. On your end, all you have to do to start uncovering visitors is add our tracking script to your website.",
    },
    {
      question: "How does Visitor Tracking and IP Append benefit salespeople?",
      answer:
        "This helps uncover the warm leads that you already have! What is a warm lead? A warm lead is a business or individual who has shown interest in your business but has not decided to start doing business with you yet. These leads are easier to convert than cold leads because they are already familiar with your business and have started looking at your site. Some of them may even understand the value of your products without you having to explain it to them. Consequently, they are more likely to become customers than other leads.",
    },
    {
      question:
        "How does Visitor Tracking and IP Append benefit marketing departments?",
      answer:
        "Visitor Tracking is a form of sales intelligence that identifies companies that are actively researching your product on your site. These companies are exhibiting intent signals. In other words, they are indicating their intention to purchase a product or service.  It’s worth noting that a buyer will likely select the vendor that makes first contact. That’s why identifying those warm leads visiting your website and knowing who they are and where they came from is so important to your marketing team. This will help them understand which marketing plans are sending leads directly to your site and which are not.",
    },
  ];

  const containerStyle = {
    backgroundImage: 'url("assets/images/Seamless-Integration-mobilefaq.webp")',
    backgroundSize: 'cover', // Adjust as needed
    backgroundPosition: 'center', // Adjust as needed
    // Add any other styles you want to customize
    headingStyle: {
      color: "#fff", // Replace 'blue' with your desired heading color
    },
    questionStyle: {
      color: "#FFF", // Replace 'green' with your desired question color
    },
  };

  const isLightBackground = true;
  return (
    <PageContainer>
      <PageTitle title={"Seamless Integration"} />
      <DesktopOnly>
      <Banner />
      </DesktopOnly>
      <MobileOnly>
      <MobileBannerSeamlessIntegration isLightBackground={isLightBackground} />
      </MobileOnly>
      <DesktopOnly>
      <div className="container">
        <SectionContainer>
          <ResponsiveSection>
            <LeftContent>
              <Heading>Unified Integration Hub</Heading>
              <Content>
                With compatibility with over 5,000+ apps,
                integrating your <br /> team's favorite tools is just a click
                away, ensuring a smooth<br />  and efficient workflow.
              </Content>
            </LeftContent>
            <RightListBox>
              <ListBoxItem>
                <CheckmarkIcon>
                  <InnerCheckmark>&#10003;</InnerCheckmark>
                </CheckmarkIcon>
                Enhanced Marketing Campaigns
              </ListBoxItem>
              <ListBoxItem>
                <CheckmarkIcon>
                  <InnerCheckmark>&#10003;</InnerCheckmark>
                </CheckmarkIcon>
                Boosted Sales Pipeline
              </ListBoxItem>
              <ListBoxItem>
                <CheckmarkIcon>
                  <InnerCheckmark>&#10003;</InnerCheckmark>
                </CheckmarkIcon>
                Time-Saving Workflow
              </ListBoxItem>
            </RightListBox>
          </ResponsiveSection>
          <ResponsiveSection>
            <LeftContent>
              <VideoContainer>
                <VideoFrame
                  src="https://www.youtube.com/embed/9WkT4yE-qh0?si=Xg3OdHFpKwGLVzKH&rel=0"
                  title="IntentBuy Video"
                  allowFullScreen
                />
              </VideoContainer>
            </LeftContent>
            <RightContentContainer>
              <RightHeading>
                Unleashing Advanced Capabilities <br /> of IntentBuy
              </RightHeading>
              <RightContent>
                By effortlessly incorporating Intentbuy into your
                tech stack,<br /> you empower  your marketing team with
                advanced tools and<br />  features.
              </RightContent>
            </RightContentContainer>
          </ResponsiveSection>
        </SectionContainer>
      </div>
      </DesktopOnly>
      <MobileOnly>
        <MobileSectionComponent />
      </MobileOnly>
      <MobileOnly>
        <SeamlessMobileVideoComponent />
      </MobileOnly>
      <div style={containerStyle}>
        <FrequentlyAskedQuestion
          heading={homePageHeading}
          additionalContent={homePageAdditionalContent}
          faqData={homePageFaqData}
          headingStyle={containerStyle.headingStyle}
          questionStyle={containerStyle.questionStyle}
        />
      </div>
      {/* Seventh section */}
      <MobileOnly>
        <BeforeFooterMobile />
      </MobileOnly>
      <DesktopOnly>
      <CustomSection customBackgroundColor="#101c2d" />
      </DesktopOnly>
      <DesktopOnly>
      <ResponsiveFooter customBackgroundColor="#101c2d" />;
      </DesktopOnly>
      <MobileOnly>
        <MobileFooter />
      </MobileOnly>
    </PageContainer>
  );
};

export default SeamlessIntegration;
