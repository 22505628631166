import React from "react";

const RealTimeThirdMobileSection = () => {
  return (
    <div className="MobileSection row mt-5 d-md-none mx-auto">
      {/* First Column */}
      <div className="col-12 d-flex flex-column text-left">
        <img
          src="/assets/images/real-time/real-time-3.1.webp" // Replace with the actual image path
          alt="Your Alt Text"
          className="img-fluid"
          style={{
            width: "auto",
            height: "auto",
          }}
        />
        <h2
          className="mt-4"
          style={{
            color: "#2C323E",
            fontFamily: "Soleil",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px",
            letterSpacing: "-0.96px",
            paddingTop: "15px", // Add 15px padding to the top
          }}
        >
          Website Visitor Tracking
        </h2>
        <p
          style={{
            color: "#626981",
            fontFamily: "Soleil",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "26.5px",
          }}
        >
          We’re putting an end to this by offering
          <br /> anonymous website visitor tracking allowing
          <br /> marketers to understand who lands on
          <br /> your website.
        </p>
      </div>

      {/* Second Column */}
      <div className="col-12 d-flex flex-column text-left mt-5">
        <img
          src="/assets/images/real-time/real-time-3.2.webp" // Replace with the actual image path
          alt="Your Alt Text"
          className="img-fluid"
          style={{
            width: "auto",
            height: "auto",
          }}
        />
        <h2
          className="mt-4"
          style={{
            color: "#2C323E",
            fontFamily: "Soleil",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "32px",
            letterSpacing: "-0.96px",
            paddingTop: "15px", // Add 15px padding to the top
          }}
        >
          Advanced Website Analytics
        </h2>
        <p
          style={{
            color: "#626981",
            fontFamily: "Soleil",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "26.5px",
            width: "351.323px",
          }}
        >
          When someone visits your site, our technology can record information
          such as company name, pages viewed, keywords searched, and other
          relevant information that would not have been available to you without
          it.
        </p>
      </div>
    </div>
  );
};

export default RealTimeThirdMobileSection;
